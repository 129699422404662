import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../../utilities/routes/links';
import ProjectCostTable from '../Tables/ProjectCostTable';
import SectionTitle from '../Tables/SectionTitle';
import HelpTooltip from '../ToolTips/HelpTooltip';
import { InsightsProject as InsightsProjectType, InsightsTabId } from '../types';

import Section from './Section';

export default function InsightsProjectCostSummary(props: { project: InsightsProjectType }) {
  const sendAnalytics = useSendAnalytics();
  const costSummaryTableTooltip =
    'A summary of the current project costs and the potential path to budget.';
  return (
    <Section
      id={`${InsightsTabId.Costs}`}
      title={
        <div className="flex items-center gap-1">
          <div className="text-type type-heading3">Cost Summary</div>
          <HelpTooltip
            onOpen={() =>
              sendAnalytics(
                insightsEvent(InsightsEvent.DETAILS_INFO_TOOLTIP, { type: 'costSummary' })
              )
            }
            tooltipContent={costSummaryTableTooltip}
          />
          <div className="ml-56">
            <SectionTitle
              linkText="View dashboard"
              onLink={() =>
                sendAnalytics(
                  insightsEvent(InsightsEvent.DETAILS_COSTS_LINK_CLICK, {
                    linkType: 'View dashboard',
                  })
                )
              }
              to={generateSharedPath(JoinProjectRoutes.PROJECT_DASHBOARD, {
                projectId: props.project.id,
              })}
            />
          </div>
        </div>
      }
    >
      <div className="flex p-2">
        <ProjectCostTable project={props.project} showTooltips />
      </div>
    </Section>
  );
}
