import { FC, useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Borders,
  ChartLabel,
  FlexibleXYPlot,
  Hint,
  HorizontalGridLines,
  LabelSeries,
  LineSeries,
  MarkSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
  // @ts-ignore JIRA: CT-224
} from 'react-vis/dist';

import { reportChart } from '../../../analytics/analyticsEventProperties';
import { ALL_MILESTONES } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import theme from '../../../theme/komodo-mui-theme';
import 'react-vis/dist/style.css';
import { formatCommas, formatCost } from '../../../utilities/currency';
import { parseDate } from '../../../utilities/dates';
import DashboardChartPlaceholder from '../../dashboard/DashboardCharts/DashboardChartsPlaceholder';
import { DETAILED } from '../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendDetailToggle';
import ChartLegend from '../../dragon-scales/TimelineCharts/ChartLegend';
import CostSummary from '../../estimate/CostSummary'; // TODO: Fix this
import { hasActiveFilter } from '../../FilterPanel/filterUtils';
import { CHART_HEIGHT, getChartLabelWithCurrency, yLabelStyle } from '../ChartsUtils';

import ChartsAllMilestonesStyles from './ChartsAllMilestonesStyles';
import {
  CHART_BOTTOM_PADDING,
  CHART_RIGHT_PADDING,
  CHART_TOP_PADDING,
  HoverPoint,
  LinePoint,
  SMALL_CHART_HEIGHT,
  SMALL_CHART_LEFT_PADDING,
  TrendData,
  Trendline,
  extendValues,
} from './ChartsAllMilestonesUtils';

type ChartsAllMilestonesProps = {
  classes: Classes<typeof ChartsAllMilestonesStyles>;
  clearFilters?: () => void;
  detailLevel?: string;
  emptyMessage?: string;
  height?: number;
  hover?: HoverPoint | null;
  isPrint?: boolean;
  isSmall?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClick?: (clicked: any) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  rounded?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  segmentsLoading?: boolean;
  setHover?: (hover: HoverPoint | null) => void;
  trendData: TrendData;
  viewFilter?: ViewFilterInput;
};

const ChartsAllMilestones: FC<ChartsAllMilestonesProps> = ({
  classes,
  clearFilters,
  detailLevel,
  emptyMessage = '',
  height,
  hover,
  isPrint = false,
  isSmall = false,
  onClick,
  segmentsLoading = false,
  trendData,
  setHover = () => {},
  rounded = true,
  viewFilter,
}) => {
  // CONSTANTS
  const calcHeight = height || (isSmall ? SMALL_CHART_HEIGHT : CHART_HEIGHT);
  const yPadding =
    (20 * (CHART_HEIGHT - CHART_BOTTOM_PADDING)) / (calcHeight - CHART_BOTTOM_PADDING);

  const margin = isSmall
    ? {
        left: SMALL_CHART_LEFT_PADDING,
        top: 12,
        right: CHART_RIGHT_PADDING,
        bottom: CHART_BOTTOM_PADDING,
      }
    : {
        left: 90,
        right: CHART_RIGHT_PADDING,
        top: CHART_TOP_PADDING,
        bottom: CHART_BOTTOM_PADDING,
      };

  // HOOKS
  const sendAnalytics = useSendAnalytics();
  const reportDashboard = (chart: string, mouseEvent: string) =>
    sendAnalytics(reportChart(chart, mouseEvent));

  // FUNCTIONS
  const onMouseOver = (value: HoverPoint) => {
    if (!isPrint) {
      reportDashboard(`Super All Milestones`, 'hover');
      setHover(value);
    }
  };

  const onMouseLeave = () => {
    setHover(null);
  };

  const getQuantityText = (quantity: Quantity | null | undefined) =>
    quantity ? `${formatCommas(quantity.magnitude)} ${quantity.unit.abbreviationPlural}` : '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleClick = (clicked: any) => {
    if (onClick) {
      reportDashboard(`Super All Milestones`, 'click');
      onClick(clicked);
    }
  };

  // DATA
  const {
    labels,
    legendLabels,
    lineData,
    minorMarkData,
    markData,
    milestonesXData,
    xDomain,
    selectedPoints,
  } = trendData;

  const tickValues = milestonesXData.map((m) => m.x) || [0];
  const width = 120; // width / milestones, with a max width
  const angle = 18;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const tickFormat = (_: any, i: number) => {
    const { name } = milestonesXData[i];
    return (
      <foreignObject className={classes.tickContainer} style={{ transform: `rotate(${angle}deg)` }}>
        <div className={classes.ticks} style={{ width }}>
          {name}
        </div>
      </foreignObject>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const topTickFormat = (_: any, i: number) => {
    const { quantity } = milestonesXData[i];
    const quantityText = getQuantityText(quantity);
    return (
      <tspan dy="1.6em" x="0">
        {quantityText}
      </tspan>
    );
  };

  const hint = useMemo(() => {
    if (!hover) return null;
    const { milestone } = hover;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const costReportColumns: any[] = extendValues(hover.values || []);
    const date = new Date(hover.dateStart);
    const now = new Date(Date.now());
    return (
      <Hint
        align={{
          horizontal: Hint.ALIGN.AUTO,
          vertical: Hint.ALIGN.AUTO,
        }}
        value={hover}
      >
        <div className={`${classes.hint} rv-hint__content`}>
          <Typography className={classes.date}>{milestone.name}</Typography>
          <Typography className={classes.date} variant="caption">
            {milestone.date ? `Started on ${parseDate(milestone.date)}` : ''}
          </Typography>
          <Typography className={classes.date} variant="caption">
            {getQuantityText(milestone.quantity)}
          </Typography>
          <div className={classes.hintSummary}>
            <CostSummary
              costReport={{
                __typename: 'MilestoneCostReports',
                costReportColumns,
                milestoneID: ALL_MILESTONES,
              }}
              rounded={rounded}
              variant={ALL_MILESTONES}
            />
          </div>
          {detailLevel === DETAILED && (
            <Typography className={classes.date} variant="caption">
              {`on `}
              {/* Display min(date, now), since segments have fixed interval sizes. */}
              {parseDate(date > now ? now : date)}
            </Typography>
          )}
        </div>
      </Hint>
    );
  }, [classes.date, classes.hint, classes.hintSummary, detailLevel, hover, rounded]);

  // PLACEHOLDER COMPONENT CASES
  if (emptyMessage || segmentsLoading) {
    const isFiltered = !!viewFilter && hasActiveFilter(viewFilter);
    return (
      <DashboardChartPlaceholder
        clearFilters={isFiltered ? clearFilters : undefined}
        emptyMessage={emptyMessage}
        height={calcHeight}
        loading={segmentsLoading}
      />
    );
  }

  // COMPONENTS
  const unitAxis = (
    <XAxis
      orientation="top"
      position="middle"
      style={{
        line: { stroke: 'none' },
        ticks: { stroke: 'none' },
      }}
      tickFormat={topTickFormat}
      tickValues={tickValues}
    />
  );

  const yAxis = (
    <YAxis
      className={classes.axisLabel}
      tickFormat={(v: string) => formatCost(v, { short: true, showCurrencySymbol: false })}
      tickTotal={6}
    />
  );
  const yGrid = <HorizontalGridLines tickTotal={6} />;
  const marks = markData.map((line: Trendline) => (
    <MarkSeries
      key={JSON.stringify(line)}
      className="hoverPoints"
      color={line.color}
      data={line.data}
      size={4}
      stack={false}
    />
  ));
  const minorMarks = minorMarkData.map((line: Trendline) => (
    <MarkSeries
      key={JSON.stringify(line)}
      className="hoverPoints"
      data={line.data}
      fill="white"
      size={2}
      stack={false}
      stroke={line.color}
    />
  ));
  const minorDetectors = minorMarkData.map((line: Trendline) => (
    <MarkSeries
      key={JSON.stringify(line)}
      className="hoverPoints"
      color="rgba(255,255,255,0)"
      data={line.data}
      onValueMouseOut={onMouseLeave}
      onValueMouseOver={(pt: LinePoint) => onMouseOver({ ...pt, color: line.color })}
      size={10}
      stack={false}
    />
  ));
  const majorDetectors = markData.map((line: Trendline) => (
    <MarkSeries
      key={JSON.stringify(line)}
      className="hoverPoints"
      color="rgba(255,255,255,0)"
      data={line.data}
      onValueMouseOut={onMouseLeave}
      onValueMouseOver={(pt: LinePoint) => onMouseOver({ ...pt, color: line.color })}
      size={10}
      stack={false}
    />
  ));
  const hoverMark = hover && ( // the hover location gets BIGGER
    <MarkSeries className="hoverPoints" colorType="literal" data={[hover]} size={6} stack={false} />
  );
  const selectedMarks = !hover && ( // only show if NOT hovering...
    <MarkSeries
      className="hoverPoints"
      colorType="literal"
      data={selectedPoints}
      size={6}
      stack={false}
    />
  );
  const xLabel = !isSmall && (
    <ChartLabel
      className={classes.axisLabel}
      includeMargin={false}
      style={{
        y: 80,
        textAnchor: 'middle',
      }}
      text="Milestones"
      xPercent={0.5}
      yPercent={1}
    />
  );
  const yLabel = !isSmall && (
    <ChartLabel
      className={classes.axisLabel}
      includeMargin={false}
      style={yLabelStyle}
      text={getChartLabelWithCurrency('Cost')}
      x="100px"
      xPercent={-0}
      yPercent={0.5}
    />
  );
  const lineComponents = lineData.map((l: Trendline) => (
    <LineSeries {...l} key={JSON.stringify(l)} />
  ));
  const legend = legendLabels && <ChartLegend legendLabels={legendLabels} />;
  const labelSeries =
    labels &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    labels.map((l: any) => (
      <LabelSeries
        {...l}
        key={JSON.stringify(l)}
        style={{
          ...l.style,
          wordWrap: 'word-break',
          ...theme.typography.number,
        }}
      />
    ));

  const menu = !isSmall && !isPrint && <div className={classes.rowContainer}>{legend}</div>;

  return (
    <>
      {menu}
      <div className={classes.rowContainer} data-cy="all-milestones-chart">
        <FlexibleXYPlot
          className={classes.linechart}
          dontCheckIfEmpty
          height={calcHeight}
          margin={margin}
          onClick={handleClick}
          onMouseLeave={onMouseLeave}
          xDomain={xDomain}
          xType="linear"
          yPadding={yPadding}
        >
          {isSmall && <Borders style={{ all: { fill: '#fff' } }} />}
          {yGrid}
          <VerticalGridLines tickValues={tickValues} />
          <XAxis tickFormat={tickFormat} tickValues={tickValues} />
          {unitAxis}
          {yAxis}
          {lineComponents}
          {xLabel}
          {yLabel}
          {minorMarks}
          {marks}
          {labelSeries}
          {hoverMark}
          {selectedMarks}
          {minorDetectors}
          {majorDetectors}
          {hint}
        </FlexibleXYPlot>
      </div>
    </>
  );
};

export default withStyles(ChartsAllMilestonesStyles)(ChartsAllMilestones);
