import { SearchItemsQuery } from '../../../generated/graphql';

export enum SearchMode {
  PROJECTS = 'projects',
  ITEMS = 'items',
}

export type SearchItem = NonNullable<SearchItemsQuery['searchItems']>['data'][number];

export enum SearchToggleValue {
  ALL = 'COMPANY_RESULTS',
  MY = 'MY_RESULTS',
}
