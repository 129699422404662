import { useCallback } from 'react';

import { Org, OrgNode } from '../../../generated/graphql';
import { TreeEntry, makeTree } from '../../../utilities/utilities';
import useOrganizationsQuery from '../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import {
  filterPublished,
  getIDs,
} from '../../CompanyTab/CompanyTabOrganizations/OrgSelector/utils';
import { useCompanyTabID } from '../../CompanyTab/CompanyTabUtils';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  getSelectedForFilterType,
} from '../../ProjectsList/ProjectsListUtils';
import { Chip, MultiSelect, SelectEntry } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';

type Props = {
  filterManager: ProjectFilterManager;
  filterType: string;
  setSettings: ProjectFilterSetSettings;
};

export default function InsightsFilterSelect(props: Props) {
  // Filters
  const { setFilters } = props.filterManager;
  const [selected] = getSelectedForFilterType(props.filterManager, props.filterType);
  // Hooks
  const companyID = useCompanyTabID();
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations ?? [];
  // Data setup
  const publishedOrgs = useMemoWrapper(filterPublished, orgs) ?? [];
  const orgNodesAvailableIDs = useMemoWrapper(
    getIDs,
    props.filterManager.filterOptions?.organizationNodes
  );

  const handleMultiSelectChange = useCallback(
    (
      selectedValues: string[],
      orgNodeIDsForCurrentOrg: Set<string>,
      descendantIds: Record<string, string[]>,
      parentIds: Record<string, string[]>
    ) => {
      // Determine IDs that have been added
      const newlySelectedIDs = selectedValues.filter((id) => !selected.includes(id));
      // Determine IDs that have been removed (deselected)
      const removedIDs = selected.filter((id) => !selectedValues.includes(id));
      // Start off with the intial selected list of org ids
      let updatedValues: string[] = [...selected];

      if (selectedValues.length === 0) {
        // Handle clear button, preserve entries from other orgs
        updatedValues = updatedValues.filter((id) => !orgNodeIDsForCurrentOrg.has(id));
      } else if (newlySelectedIDs.length) {
        // Add Filter
        newlySelectedIDs.forEach((id) => {
          updatedValues.push(id);
          // Add all parent node IDs
          if (parentIds[id]) {
            updatedValues = updatedValues.concat(parentIds[id]);
          }
        });
      } else if (removedIDs.length) {
        // Remove Filter
        removedIDs.forEach((id) => {
          updatedValues = updatedValues.filter((val) => val !== id);
          // Remove descendant IDs
          if (descendantIds[id]) {
            updatedValues = updatedValues.filter((val) => !descendantIds[id].includes(val));
          }
        });
      }

      updatedValues = Array.from(new Set(updatedValues));
      // Update
      setFilters(
        {
          type: props.filterType,
          value: '',
          values: updatedValues,
        },
        props.setSettings
      );
    },
    [setFilters, selected, props.filterType, props.setSettings]
  );

  const orgData = useMemoWrapper(createMultiselectEntries, publishedOrgs, orgNodesAvailableIDs);

  if (!publishedOrgs.length) return null;

  return (
    <div className="flex flex-col gap-2">
      {orgData.map(({ org, entries, orgNodeIDsForCurrentOrg, descendantIds, parentIds }) => (
        <div key={org.id} className="px-2">
          <MultiSelect
            data-cy="organization-multi-select"
            entries={entries}
            isAllSelectable
            isClearable
            isSearchable
            label={org.name}
            onChange={(selectedValues: string[]) =>
              handleMultiSelectChange(
                selectedValues,
                orgNodeIDsForCurrentOrg,
                descendantIds,
                parentIds
              )
            }
            placeholder={`Find a ${org.name.toLowerCase()}...`}
            value={props.filterManager.filterState.orgNodeIDs}
          />
        </div>
      ))}
    </div>
  );
}

// Below is the logic to create entries for the multiselect.
// We are only displaying the selectable nodes and its parents
export const createMultiselectEntries = (orgs: Org[], orgNodesAvailableIDs: UUID[] | undefined) => {
  return orgs.map((org) => {
    const entries = convertOrgToSelectEntries(org, orgNodesAvailableIDs || []);
    const orgNodeIDsForCurrentOrg = new Set(entries.map((entry) => entry.id));
    // Create a map of entries for quick lookup
    const entryMap = new Map(entries.map((entry) => [entry.id, entry]));

    // Calculate descendant IDs for each entry
    const descendantMap = new Map<string, string[]>();
    entries.forEach((entry) => {
      const descendants = getDescendantIds(entries, entry.id, entryMap);
      descendantMap.set(entry.id, descendants);
    });

    // Calculate parent IDs for each entry
    const parentMap = new Map<string, string[]>();
    entries.forEach((entry) => {
      const parents = getParentIds(entries, entry.id, entryMap);
      parentMap.set(entry.id, parents);
    });

    return {
      org,
      entries,
      orgNodeIDsForCurrentOrg,
      descendantIds: Object.fromEntries(descendantMap),
      parentIds: Object.fromEntries(parentMap),
    };
  });
};

// Helper function to get all descendant IDs for a node
const getDescendantIds = (
  entries: SelectEntry[],
  entryId: string,
  entryMap: Map<string, SelectEntry>
): string[] => {
  const entry = entryMap.get(entryId);
  if (!entry) return [];

  const childEntries = entries.filter((e) => e.parentID === entryId);
  let descendants: string[] = [];
  childEntries.forEach((childEntry) => {
    descendants.push(childEntry.id);
    descendants = descendants.concat(getDescendantIds(entries, childEntry.id, entryMap));
  });
  return descendants;
};

// Helper function to get all parent IDs for a node
const getParentIds = (
  entries: SelectEntry[],
  entryId: string,
  entryMap: Map<string, SelectEntry>
): string[] => {
  const entry = entryMap.get(entryId);
  if (!entry || !entry.parentID) return [];

  let parents: string[] = [entry.parentID];
  const parentEntry = entryMap.get(entry.parentID);
  if (parentEntry) {
    parents = parents.concat(getParentIds(entries, parentEntry.id, entryMap));
  }
  return parents;
};

// Helper function only get nodes from the selectable nodes and its related parents
export function convertOrgToSelectEntries(org: Org, selected: UUID[]): SelectEntry[] {
  const selectEntries: SelectEntry[] = [];
  const selectedSet = new Set(selected);
  const parentSet = new Set<UUID>();

  // Create a map of nodes by ID for quick lookup
  const nodeMap = new Map(org.nodes.map((node) => [node.id, node]));

  // Recursive function to add parents to the parentSet
  const addParents = (node: OrgNode) => {
    if (node.parentID && !parentSet.has(node.parentID)) {
      parentSet.add(node.parentID);
      const parentNode = nodeMap.get(node.parentID);
      if (parentNode) {
        addParents(parentNode); // Recursively add higher-level parents
      }
    }
  };

  // Populate parentSet by adding all parents of selected entries
  org.nodes.forEach((node) => {
    if (selectedSet.has(node.id)) {
      addParents(node);
    }
  });

  // Recursively process entries and filter based on selected IDs or parent IDs
  const processEntries = (nodes: TreeEntry<OrgNode>[]) => {
    nodes.forEach((node) => {
      const levelIndex = node.depth;
      const entry = {
        id: node.id,
        label: node.name,
        parentID: node.parentID,
        endAdornment: levelIndex >= 0 && <Chip text={org.levels[levelIndex]} />,
      };

      // Add the entry if it is in the selectedSet or parentSet
      if (selectedSet.has(node.id) || parentSet.has(node.id)) {
        selectEntries.push(entry);
      }

      // Recursively process child entries if they exist
      if (node.entries) {
        processEntries(node.entries);
      }
    });
  };

  // Call the recursive function with the initial allNodes
  const allNodes = makeTree<OrgNode>(org.nodes);
  processEntries(allNodes);

  return selectEntries;
}
