import { getTodayUtcNoon } from '../../../utilities/dates';
import { isNonNullable } from '../../../utilities/types';
import InsightsBaseLine from '../../dragon-scales/TimelineCharts/InsightsCost/InsightsBaseLine';
import useMemoWrapper from '../../useMemoWrapper';
import { BASE_HEIGHT } from '../ProjectBabyCharts/utils';
import { LEFT_MARGIN } from '../ProjectDetailsCharts/utils';
import { InsightsProject as InsightsProjectType } from '../types';
import { getRange } from '../utils';

import { getProjectRange } from './utils';

export default function InsightsProjectBaseline(props: { project: InsightsProjectType }) {
  const [startDate, endDate] = useMemoWrapper(getProjectRange, props.project);
  const milestones = props.project?.milestones || [];
  const baseRange = getRange(milestones.filter(isNonNullable).filter((a) => a.date.getTime() > 0));
  const today = getTodayUtcNoon();
  const totalRange: [Date, Date] = [startDate, endDate];
  const margin = {
    left: LEFT_MARGIN,
    right: 0,
    bottom: 0,
    top: 0,
  };
  return (
    <InsightsBaseLine
      baseRange={baseRange}
      data={milestones}
      height={BASE_HEIGHT}
      hoverDate={undefined}
      margin={margin}
      projectStatus={props.project.status}
      today={today}
      totalRange={totalRange}
    />
  );
}
