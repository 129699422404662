import {
  ForecastingExploreItemsFilters,
  ForecastingExploreItemsSortBy,
  ForecastingExploreItemsSortKey,
  ForecastingProjectsFilters,
  SortDirection,
} from '../../../../generated/graphql';
import { SearchToggleValue } from '../types';

type SearchItemsVariables = {
  filters: ForecastingExploreItemsFilters;
  projectsFilters: ForecastingProjectsFilters;
  search: string;
  sortBy: ForecastingExploreItemsSortBy;
  toggleValue: SearchToggleValue;
};

export const DEFAULT_ITEMS_SEARCH_QUERY: SearchItemsVariables = {
  filters: {
    categoryNumbers: [],
    costImpact: {},
    milestoneDate: {},
    milestoneNames: [],
    projectLocations: [],
    projectNames: [],
    projectTypes: [],
    statuses: [],
  },
  projectsFilters: {
    companies: [],
    estimateCostRange: {},
    locations: [],
    milestoneDateRange: {},
    milestoneDesignPhases: [],
    projectTypes: [],
    statuses: [],
    types: [],
  },
  search: '',
  sortBy: {
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: ForecastingExploreItemsSortKey.SCORE,
  },
  toggleValue: SearchToggleValue.ALL,
};
