import { gql } from '../../../../api/graphqlFragments';
import { ideaFields, ideaGroupFields } from '../../queries';

export const ideaGroupsQuery = gql`
  query ideaGroups($projectID: UUID!, $milestoneID: UUID, $filters: IdeaFilters!) {
    ideaGroups(projectID: $projectID, milestoneID: $milestoneID, filters: $filters) {
      ...ideaGroupFields
    }
  }
  ${ideaGroupFields}
`;

export const ideasQuery = gql`
  query ideas($projectID: UUID!, $milestoneID: UUID, $filters: IdeaFilters!) {
    ideas(projectID: $projectID, milestoneID: $milestoneID, filters: $filters) {
      ...ideaFields
    }
  }
  ${ideaFields}
`;
