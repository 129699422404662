import { ReactNode } from 'react';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import {
  ACTIVITY_ID,
  ALL_MILESTONES,
  CURRENT_MILESTONE,
  DUE_DATE,
  DUE_STATE,
  FILTER_TEXT,
  FROM,
  SORT,
  STATUS,
  TIMELINE_TYPE_NAMES,
} from '../../../constants';
import { ItemsSortKey, Status } from '../../../generated/graphql';
import { getDatePrimitives } from '../../../utilities/dates';
import { getItemListLink } from '../../ItemsList/ItemsListUtils';

import { timeline } from './timeline/timeline';
import {
  GroupByType,
  ItemDueState,
  Timeline,
  TimelineData,
  TimelineExpandedMap,
  TimelineItemData,
  TimelineItemGroup,
  TimelineOptions,
  TimelineSettings,
  TimelineType,
} from './timeline/timeline.types';
import {
  EVENT_ORANGE,
  MILESTONE_BLUE,
  MILESTONE_GMP,
  PHASE_GREY,
  TRANSPARENT_BLACK,
} from './timeline/timelineColor';
import { timeline as timelineDueDate } from './timeline/timelineDueDate';
import {
  AXIS_HEIGHT,
  ITEMS_HEIGHT,
  ROW_HEIGHT,
  TIMELINE_MIN_HEIGHT,
  ZOOM_HEIGHT,
  getExpandedData,
  getItemsGroups,
  getItemsLegend,
  getRangeExtendedStr,
  isSettingsItems,
  isSettingsTimeline,
} from './timeline/utils';

export { getRangeExtendedStr };

export const initTimeline = (
  node: HTMLElement,
  props: Partial<TimelineOptions>,
  activities: TimelineActivity[]
): Timeline<TimelineData> =>
  timeline()
    .settings(props.settings)
    .margin(props.margin)
    .width(props.width)
    .height(props.height)
    .isPrint(props.isPrint)
    .nodeColor(nodeColor)
    .strokeColor(strokeColor)
    .today(props.today)
    .data(props.data)
    .items(props.items)
    .expandedMap(props.expandedMap)
    .tooltips(true)
    .tooltipElement(tooltipElement(props.today || '0', activities, props.settings?.projectID || ''))
    .zoomLineCompressed(props.zoomLineCompressed)
    .zoomLineDisabled(props.zoomLineDisabled)
    .onExpand(props.onExpand)
    .onZoom(props.onZoom)
    .onAnalytics(props.onAnalytics)
    .render(node);

export const getFinalHeight = ({
  data,
  expandedMap,
  items,
  maxHeight,
  settings,
}: {
  data: TimelineData[];
  expandedMap: TimelineExpandedMap;
  items?: TimelineItemData[];
  maxHeight?: number;
  settings: TimelineSettings;
}) => {
  const expanded = getExpandedData(data, expandedMap);
  const isTimeline = isSettingsTimeline(settings);
  const isItems = isSettingsItems(settings);
  const itemsHeight = items?.length ? ITEMS_HEIGHT : 0;
  let timelineHeight = AXIS_HEIGHT + calcTimelineHeight(expanded);
  if (timelineHeight > (maxHeight || 0)) timelineHeight = maxHeight || 0;
  if (timelineHeight < TIMELINE_MIN_HEIGHT) timelineHeight = TIMELINE_MIN_HEIGHT;
  const timelineItemsHeight = timelineHeight + itemsHeight;
  let totalHeight = timelineItemsHeight + ZOOM_HEIGHT + 24;
  if (!isTimeline) totalHeight -= timelineHeight - 48;
  if (!isItems) totalHeight -= itemsHeight - 24;
  return { totalHeight, timelineHeight, itemsHeight: ITEMS_HEIGHT };
};

export const nodeColor = (d: TimelineData) => {
  switch (d.type) {
    case TimelineType.ACTIVE_MILESTONE:
      return d.isGuaranteedMaximumPrice ? MILESTONE_GMP : MILESTONE_BLUE;
    case TimelineType.MILESTONE:
      return TRANSPARENT_BLACK;
    case TimelineType.EVENT:
      return EVENT_ORANGE;
    case TimelineType.PHASE:
      return PHASE_GREY;
    default:
      return PHASE_GREY;
  }
};

export const strokeColor = (d: TimelineData) => {
  switch (d.type) {
    case TimelineType.ACTIVE_MILESTONE:
    case TimelineType.MILESTONE:
      return d.isGuaranteedMaximumPrice ? MILESTONE_GMP : MILESTONE_BLUE;
    case TimelineType.EVENT:
      return EVENT_ORANGE;
    case TimelineType.PHASE:
      return PHASE_GREY;
    default:
      return PHASE_GREY;
  }
};

export const getStatusFromDueState = (d: TimelineItemGroup, today: string, state: ItemDueState) => {
  const todayDate = new Date(today);
  todayDate.setDate(todayDate.getDate() - 1);
  let { start, end } = d;
  let status: Status[] = [];
  if (state === ItemDueState.Upcoming) {
    if (new Date(start) < todayDate && new Date(end) > todayDate) {
      start = todayDate.toISOString();
    }
    status = [Status.PENDING];
  } else if (state === ItemDueState.PastDue) {
    if (new Date(end) > todayDate && new Date(start) < todayDate) {
      end = todayDate.toISOString();
    }
    status = [Status.PENDING];
  } else if (state === ItemDueState.Decided) {
    if (new Date(start) < todayDate && new Date(end) > todayDate) {
      start = todayDate.toISOString();
    }
    status = [Status.ACCEPTED, Status.REJECTED, Status.INCORPORATED, Status.NOTAPPLICABLE];
  }
  return { start, end, status };
};

const getGroupHref = (
  projectID: UUID | undefined,
  d: TimelineItemGroup,
  today: string,
  state: ItemDueState | undefined
) => {
  if (!projectID) return '';
  const { end, start, status } =
    state === undefined ? { ...d, status: [] } : getStatusFromDueState(d, today, state);
  const [sYear, sMonth, sDay] = getDatePrimitives(start);
  const [eYear, eMonth, eDay] = getDatePrimitives(end);
  const startStr = `${sMonth}/${sDay}/${sYear}`;
  const endStr = `${eMonth}/${eDay}/${eYear}`;
  const due = `due=${startStr} to ${endStr}`;
  const filterText = `due: ${startStr} to ${endStr}`;
  const dueState = state ? { [DUE_STATE]: ItemDueState[state] } : {};
  const itemsListLink = getItemListLink(projectID, {
    [DUE_DATE]: due,
    [FILTER_TEXT]: filterText,
    [STATUS]: status,
    [SORT]: ItemsSortKey.SORT_DUE_DATE,
    [CURRENT_MILESTONE]: [ALL_MILESTONES],
    [FROM]: JoinProjectRoutes.TIMELINE,
    ...dueState,
  });
  return itemsListLink;
};

export const tooltipGroupElement = (
  d: TimelineItemGroup,
  projectID: UUID | undefined,
  today: string
) => {
  const hasNoLinks = !projectID;
  const { PastDue, Upcoming, Decided } = ItemDueState;
  const { counter, data, end, start } = d;
  const pastDue = counter[PastDue];
  const upcoming = counter[Upcoming];
  const decided = counter[Decided];
  const total = data.length;
  const sPrimitives = getDatePrimitives(start);
  const ePrimitives = getDatePrimitives(end);
  const [sYear, sMonth, sDay] = sPrimitives;
  const [eYear, eMonth, eDay] = ePrimitives;
  const startStr = `${sMonth}/${sDay}/${sYear}`;
  const endStr = `${eMonth}/${eDay}/${eYear}`;
  // Styles
  const classLink = 'cursor-pointer type-link type-body2';
  const classText = 'pointer-events-none cursor-pointer type-body2';
  const pastDueClass = pastDue === 0 || hasNoLinks ? classText : classLink;
  const upcomingClass = upcoming === 0 || hasNoLinks ? classText : classLink;
  const decidedClass = decided === 0 || hasNoLinks ? classText : classLink;
  const totalClass = hasNoLinks ? classText : classLink;
  const dueEl = (
    state: ItemDueState | undefined,
    countStr: string,
    linkClass: string,
    color: string
  ) => (
    <div className="flex type-body2" id="timeline-tooltip">
      <div className={`mr-1 h-2 w-2 self-center ${color}`} id="timeline-tooltip" />
      <a
        className={linkClass}
        href={getGroupHref(projectID, d, today, state)}
        id="timeline-tooltip"
        rel="noreferrer"
        target="_blank"
      >
        {countStr}
      </a>
    </div>
  );
  return (
    <div id="timeline-tooltip">
      <p className="type-body2" id="timeline-tooltip">
        Items Due
      </p>
      <div className="flex type-body2" id="timeline-tooltip">
        {startStr}
        {startStr !== endStr ? ` ‒ ${endStr}` : ''}
      </div>
      {dueEl(PastDue, `Past Due (${pastDue})`, pastDueClass, 'bg-entities-item-pastdue')}
      {dueEl(Upcoming, `Upcoming (${upcoming})`, upcomingClass, 'bg-entities-item-upcoming')}
      {dueEl(Decided, `Decided (${decided})`, decidedClass, 'bg-entities-item-decided')}
      {dueEl(undefined, `Total (${total})`, totalClass, '')}
    </div>
  );
};

export const dataElement = (d: TimelineData, linkEl?: ReactNode) => (
  <div id="timeline-tooltip">
    <p className="text-type-inactive type-label" id="timeline-tooltip">
      {TIMELINE_TYPE_NAMES[d.type]}
    </p>
    <p className="line-clamp-2 type-body1" id="timeline-tooltip">
      {d.name}
    </p>
    <div className="flex type-table-number" id="timeline-tooltip">
      {new Date(d.start).toLocaleDateString()}
      {d.end && d.end !== d.start ? ` ‒ ${new Date(d.end).toLocaleDateString()}` : ''}
    </div>
    {linkEl}
  </div>
);

export const linkElement = (d: TimelineData, activity: TimelineActivity, link: string) => {
  const classLink = 'cursor-pointer type-link type-body2';
  const classText = 'pointer-events-none cursor-pointer type-body2';
  const itemsCount = activity.itemCount || 0;
  const linkClass = itemsCount === 0 ? classText : classLink;
  const countStr = `Items (${itemsCount})`;
  const linkEl = (
    <div className="flex type-body2" id="timeline-tooltip">
      <a className={linkClass} href={link} id="timeline-tooltip" rel="noreferrer" target="_blank">
        {countStr}
      </a>
    </div>
  );
  return dataElement(d, linkEl);
};

export const tooltipElement =
  (today: string, activities: TimelineActivity[], projectID: UUID | undefined) =>
  (d: TimelineData | Date | TimelineItemGroup, text?: string) => {
    const textElement = (d: TimelineData) => (
      <div>
        <p className="text-type-inactive type-label">{TIMELINE_TYPE_NAMES[d.type]}</p>
        <p className="line-clamp-1 type-body1">{d.name}</p>
        <div className="flex type-table-number">{text}</div>
      </div>
    );
    const todayElement = (d: Date) => (
      <div>
        <p className="text-type-inactive type-label">Today</p>
        <div className="flex type-table-number">{new Date(d).toLocaleDateString()}</div>
      </div>
    );

    if ('counter' in d) {
      return tooltipGroupElement(d, projectID, today);
    }
    if ('type' in d && text) {
      return textElement(d);
    }
    if ('type' in d) {
      const activity = activities.find(({ id }) => id === d.id) || ({} as TimelineActivity);
      if (d.type === TimelineType.ACTIVE_MILESTONE || d.type === TimelineType.MILESTONE) {
        const itemsListLink = getItemListLink(projectID || '', {
          [SORT]: ItemsSortKey.SORT_DUE_DATE,
          [CURRENT_MILESTONE]: [activity.milestone?.id || ALL_MILESTONES],
          [FROM]: JoinProjectRoutes.TIMELINE,
        });
        return linkElement(d, activity, itemsListLink);
      }
      if (d.type === TimelineType.EVENT) {
        const itemsListLink = getItemListLink(projectID || '', {
          [SORT]: ItemsSortKey.SORT_DUE_DATE,
          [CURRENT_MILESTONE]: [ALL_MILESTONES],
          [ACTIVITY_ID]: activity.id,
          [FROM]: JoinProjectRoutes.TIMELINE,
        });
        return linkElement(d, activity, itemsListLink);
      }
    }
    if ('type' in d) {
      return dataElement(d);
    }
    return todayElement(d);
  };

export const calcTimelineHeight = (data: TimelineData[]) => data.length * ROW_HEIGHT;

export const computeItemsLegend = (items: TimelineItemData[]) =>
  getItemsLegend(getItemsGroups(items, GroupByType.Month));

export const initDueDateTimeline = (
  node: HTMLElement,
  props: Partial<TimelineOptions>,
  activities: TimelineActivity[]
): Timeline<TimelineData> =>
  timelineDueDate()
    .settings(props.settings)
    .range(props.range)
    .width(props.width)
    .height(props.height)
    .isPrint(props.isPrint)
    .nodeColor(nodeColor)
    .strokeColor(strokeColor)
    .today(props.today)
    .data(props.data)
    .items(props.items)
    .expandedMap(props.expandedMap)
    .tooltips(true)
    .tooltipElement(tooltipElement(props.today || '0', activities, props.settings?.projectID))
    .zoomLineCompressed(props.zoomLineCompressed)
    .onExpand(props.onExpand)
    .onZoom(props.onZoom)
    .onAnalytics(props.onAnalytics)
    .render(node);

export const getDueDateHeight = (height: number) => {
  return { totalHeight: height, timelineHeight: 0, itemsHeight: height };
};
