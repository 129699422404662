import { useParams } from 'react-router-dom';

import ProgramDashboardData from './ProgramDashboardData';

export default function ProgramDashboardRoute() {
  const { programID } = useParams();

  if (!programID) return null;
  return <ProgramDashboardData programID={programID} />;
}
