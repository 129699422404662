import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { IdeaFilters } from '../../../generated/graphql';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isEnumValue } from '../../../utilities/types';
import { CategoryMultiSelect } from '../../dragon-scales';
import { FilterPopover } from '../../FilterPopover/FilterPopover';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { Button, ButtonBar, Icon, ScrollContainer, TextInput } from '../../scales';
import MilestoneSelect from '../../Select/MilestoneSelect';
import { IdeaGroup, ProjectIdea, StarredToggle } from '../types';

import useIdeaGroupsQuery from './hooks/useIdeaGroupsQuery';
import useIdeasQuery from './hooks/useIdeasQuery';
import IdeaTile from './IdeaTile';

type Props = { projectID: UUID };

export default function Ideas(props: Props) {
  const [filters, debouncedFilters, setFilters] = useDebouncedState<IdeaFilters>({
    categories: [],
    search: '',
  });
  const [milestoneID, setMilestoneID] = useState<UUID>();
  const [starredIdeas, setStarredIdeas] = useLocalStorage<UUID[]>('STARRED_IDEAS', []);
  const [toggleValue, setToggleValue] = useState(StarredToggle.ALL_IDEAS);

  const ideaGroupsQuery = useIdeaGroupsQuery(props.projectID, milestoneID, debouncedFilters);
  const ideaGroups =
    ideaGroupsQuery?.data?.ideaGroups ?? ideaGroupsQuery.previousData?.ideaGroups ?? [];
  const ideasQuery = useIdeasQuery(props.projectID, milestoneID, debouncedFilters);
  const ideas = ideasQuery?.data?.ideas ?? ideasQuery.previousData?.ideas ?? [];

  const activeMilestoneID = useProjectPropsQuery(props.projectID).data.project?.activeMilestone.id;
  const milestones = useMilestonesQuery(props.projectID, true).data?.milestones ?? [];

  const hasActiveSearch = Boolean(filters.search);
  const onChangeFilter = (newFilters: Partial<IdeaFilters>) =>
    setFilters((prev) => ({ ...prev, ...newFilters }));
  const onStar = (ideaID: UUID) =>
    setStarredIdeas(
      starredIdeas.includes(ideaID)
        ? starredIdeas.filter((id) => id !== ideaID)
        : [...starredIdeas, ideaID]
    );

  const navigate = useNavigate();
  const handleMilestoneNavigation = () =>
    navigate(
      generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
        projectId: props.projectID,
        milestoneId: milestoneID ?? activeMilestoneID,
        search: '?view=ESTIMATE',
      })
    );

  return (
    <div className="flex flex-col gap-6 p-6">
      {/**
       * TODO: I'm using mr-auto temporarily to prevent MilestoneSelect from taking
       * up the full width. I will fix this when I update MilestoneSelect to not use
       * our deprecated JoinSelect under the hood.
       *  */}
      <div className="mr-auto flex flex-row items-center gap-4">
        <h1 className="type-heading1">Ideas</h1>
        <MilestoneSelect
          milestones={milestones}
          onChangeMilestone={(value) => {
            if (value) setMilestoneID(value);
          }}
          selectedMilestone={milestoneID ?? activeMilestoneID}
        />
        <Button
          label="View Milestone Estimate"
          onClick={handleMilestoneNavigation}
          type="tertiary"
        />
      </div>
      <Filters
        filters={filters}
        onChange={onChangeFilter}
        onToggle={setToggleValue}
        toggleValue={toggleValue}
      />
      {hasActiveSearch ? (
        <SearchResults
          ideas={ideas}
          onStar={onStar}
          projectID={props.projectID}
          search={filters.search}
          shouldHideUnstarred={toggleValue === StarredToggle.STARRED_IDEAS}
          starredIdeas={starredIdeas}
        />
      ) : (
        <IdeaGroups
          ideaGroups={ideaGroups}
          onStarIdea={onStar}
          projectID={props.projectID}
          shouldHideUnstarred={toggleValue === StarredToggle.STARRED_IDEAS}
          starredIdeas={starredIdeas}
        />
      )}
    </div>
  );
}

function Filters(props: {
  filters: IdeaFilters;
  onChange: (updatedFilters: Partial<IdeaFilters>) => void;
  onToggle: (value: StarredToggle) => void;
  toggleValue: StarredToggle;
}) {
  const numCategoryFilters = new Set(props.filters.categories.map((c) => c.categorizationID)).size;
  const categorizations = [
    { name: 'Masterformat', id: 'c22ed8a1-8fb3-43fc-b9ad-1ecf514c9852', builtin: true },
    { name: 'Uniformat', id: 'f7716c5d-5970-40b9-b215-03cc1b9773bf', builtin: true },
  ];

  return (
    <div className="flex gap-4">
      <TextInput
        aria-label="Search Input"
        onChange={(value) => props.onChange({ search: value })}
        onClear={() => props.onChange({ search: '' })}
        placeholder="Search ideas by name or material"
        startAdornment={<Icon name="search" />}
        value={props.filters.search}
      />
      <ButtonBar
        onChange={(value) => isEnumValue(StarredToggle, value) && props.onToggle(value)}
        options={[
          { value: StarredToggle.ALL_IDEAS, label: 'All Ideas' },
          { value: StarredToggle.STARRED_IDEAS, label: 'Starred Ideas' },
        ]}
        value={props.toggleValue}
      />
      <FilterPopover
        numFiltersApplied={numCategoryFilters}
        onResetFilters={() => props.onChange({ categories: [] })}
      >
        {categorizations.map((c) => (
          <CategoryMultiSelect
            key={c.id}
            categorization={c}
            onChange={(value) => {
              props.onChange({
                categories: [
                  ...props.filters.categories.filter(
                    (category) => category.categorizationID !== c.id
                  ),
                  ...value.map((id) => ({ id, categorizationID: c.id })),
                ],
              });
            }}
            value={props.filters.categories.flatMap((category) =>
              category.categorizationID === c.id ? [category.id] : []
            )}
          />
        ))}
      </FilterPopover>
    </div>
  );
}

function IdeaGroups(props: {
  ideaGroups: IdeaGroup[];
  onStarIdea: (ideaID: UUID) => void;
  projectID: UUID;
  shouldHideUnstarred: boolean;
  starredIdeas: UUID[];
}) {
  return (
    <>
      {props.ideaGroups.map((ideaGroup) => {
        const ideas = ideaGroup.ideas.filter(
          ({ id }) => !props.shouldHideUnstarred || props.starredIdeas.includes(id)
        );
        if (ideas.length === 0) return null;

        let header = ideaGroup.name;
        if (ideas.length !== ideaGroup.total) header += ` (${ideas.length} of ${ideaGroup.total})`;
        else header += ` (${ideas.length})`;
        return (
          <div key={ideaGroup.name} className="flex flex-col gap-4">
            <h2 className="type-heading2">{header}</h2>
            <ScrollContainer direction="horizontal">
              <div className="flex gap-6">
                {ideas.map((idea) => (
                  <div key={idea.id} className="w-80">
                    <IdeaTile
                      idea={idea}
                      isStarred={props.starredIdeas.includes(idea.id)}
                      onStarIdea={() => props.onStarIdea(idea.id)}
                      projectID={props.projectID}
                    />
                  </div>
                ))}
              </div>
            </ScrollContainer>
          </div>
        );
      })}
    </>
  );
}

function SearchResults(props: {
  ideas: ProjectIdea[];
  onStar: (ideaID: UUID) => void;
  projectID: UUID;
  search: string;
  shouldHideUnstarred: boolean;
  starredIdeas: UUID[];
}) {
  const ideas = props.ideas.filter(
    ({ id }) => !props.shouldHideUnstarred || props.starredIdeas.includes(id)
  );
  return (
    <div className="flex flex-col gap-4">
      <h2 className="type-heading2">
        Results for &quot;{props.search}&quot; ({ideas.length} of 184)
      </h2>
      <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-6">
        {ideas.map((idea) => (
          <IdeaTile
            key={idea.id}
            idea={idea}
            isStarred={props.starredIdeas.includes(idea.id)}
            onStarIdea={() => props.onStar(idea.id)}
            projectID={props.projectID}
          />
        ))}
      </div>
    </div>
  );
}
