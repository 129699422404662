import { useParams } from 'react-router-dom';

import { PortraitOrientation } from '../Print/PrintUtils';

import PrintProgramDashboardData from './PrintProgramDashboardData';

export default function PrintProgramDashboardRoute() {
  const { programID } = useParams();

  if (!programID) return null;
  return (
    <div className="h-full overflow-visible">
      <PortraitOrientation />
      <PrintProgramDashboardData programID={programID} />;
    </div>
  );
}
