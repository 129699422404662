import { useCallback } from 'react';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useCurrentCompanyUser } from '../../contexts/current-company-user';
import { useCurrentUser } from '../../contexts/current-user';
import { ProjectTermProvider } from '../../ProjectDisplaySettings/TerminologyProvider';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';

import useLastViewedEntries from './hooks/useLastViewedEntries';
import SidebarCollapsed from './SidebarCollapsed';
import SidebarExpanded from './SidebarExpanded';

export default function Sidebar() {
  const sendNavAnalytics = useSendNavAnalytics();

  const [expanded, setExpanded] = useLocalStorage('NAV_SIDEBAR_EXPANDED', true);
  const onToggleExpanded = useCallback(() => {
    sendNavAnalytics(navEventTypes.EXPAND_CHANGED, { expand: !expanded });
    setExpanded((prevState) => !prevState);
  }, [expanded, sendNavAnalytics, setExpanded]);

  const company = useCurrentCompanyUser()?.company ?? undefined;
  const user = useCurrentUser();

  const lastViewedEntries = useLastViewedEntries();

  const content = expanded ? (
    <SidebarExpanded
      company={company}
      lastViewedEntries={lastViewedEntries}
      onCollapse={onToggleExpanded}
      user={user}
    />
  ) : (
    <SidebarCollapsed
      company={company}
      lastViewedEntries={lastViewedEntries}
      onExpand={onToggleExpanded}
      user={user}
    />
  );

  // ToDo: Update term provider NS-1419
  const lastViewedProjectID = lastViewedEntries.find((e) => e.type === 'project')?.id ?? '';

  return (
    <ProjectTermProvider projectID={lastViewedProjectID}>
      <nav className="h-screen shrink-0 bg-background-primary text-type-primary print:hidden">
        {content}
      </nav>
    </ProjectTermProvider>
  );
}
