import { useProgramCostsQuery } from '../../../../ProgramReporting/hooks/useProgramCostsQuery';

import ActiveMilestoneCosts from './ActiveMilestoneCosts';

type Props = {
  programID: UUID;
  projectIDs: UUID[];
};

export default function ProgramCosts(props: Props) {
  const programCost = useProgramCostsQuery(props.projectIDs, props.programID)?.data
    ?.programCosts || {
    estimate: 0,
    budget: 0,
    runningTotal: 0,
  };

  return <ActiveMilestoneCosts project={programCost} />;
}
