import { useProgramQuery } from '../../../ProgramReporting/hooks/useProgramQuery';
import ProgramHUD from '../HUD/ProgramHUD';
import ProgramTitle from '../Title/ProgramTitle';

import useHUDVisibility from './useHUDVisibility';

type Props = {
  programID: UUID;
};

export default function ProgramDetails(props: Props) {
  const programQueryResult = useProgramQuery(props.programID);
  const program = programQueryResult?.data?.program;

  const { showHUD, onChangeHUDVisibility } = useHUDVisibility();

  if (!program) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2" data-cy="program-details">
      <div className="px-2">
        <ProgramTitle
          isShowingMilestoneInfo={showHUD}
          isThumbnailVisible
          onShowMilestoneInfo={onChangeHUDVisibility}
          program={program}
        />
      </div>
      {showHUD && props.programID && <ProgramHUD programID={props.programID} />}
    </div>
  );
}
