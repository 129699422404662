import { DefinitionNode } from 'graphql';

import { DocumentNode, gql as tag } from '@apollo/client';
import { print } from 'graphql/language/printer';

import { GridEstimateArmatureFieldsFragmentDoc } from '../generated/graphql';

// Deduplicates any fragments that it might find so the server doesn't choke.
// Pre-constructs a string of a pretty-printed version of the dedup'd AST.
function processFragments(
  document: DocumentNode & { cachedQuery?: string; definitions: readonly DefinitionNode[] }
) {
  const doc = document;
  const usedFragments: Record<string, boolean> = {};

  doc.definitions = doc.definitions.filter((def) => {
    if (def.kind === 'FragmentDefinition') {
      if (usedFragments[def.name.value]) {
        return false;
      }
      usedFragments[def.name.value] = true;
    }
    return true;
  });
  doc.cachedQuery = print(doc);
  return doc;
}

// Rebind our query to always preprocess
export const gql = (...q: Parameters<typeof tag>) => processFragments(tag(...q));

export const commonUserFields = gql`
  fragment commonUserFields on User {
    email
    id
    jobTitle
    name
    phone
    picture
    pictureAsset {
      thumbnailURL
    }
    status
  }
`;

export const commonCompanyUserFields = gql`
  fragment commonCompanyUserFields on CompanyUser {
    company {
      id
      name
      type
      domain
      assetID
      deactivated
    }
    isIndependent
    matchedCompany {
      id
      name
      type
      domain
      assetID
    }
    role {
      id
      name
    }
    user {
      email
      id
      name
      phone
      picture
      didSetup
      pictureAsset {
        thumbnailURL
      }
      jobTitle
      status
      activeAt
    }
  }
`;

export const commonCategoryFields = gql`
  fragment commonCategoryFields on Category {
    categorization {
      id
      name
      builtin
      description
      draft
      levels
      deprecated
    }
    id
    name
    number
    error
    level
    levels {
      id
      name
      number
      hasSubCategories
      level
    }
    hasSubCategories
  }
`;

export const costTypeFields = gql`
  fragment costTypeFields on Cost {
    ... on CostRange {
      min
      max
    }
    ... on CostScalar {
      value
    }
  }
`;

export const scheduleImpactFields = gql`
  fragment scheduleImpactFields on ScheduleImpact {
    type
    criticalPath
    days
    minImpact {
      type
      days
    }
    maxImpact {
      type
      days
    }
  }
`;

export const commonItemLikeItemFields = gql`
  fragment commonItemLikeItemFields on Item {
    id
    itemType
    bucket {
      id
      name
      date
    }
    project {
      id
      name
    }
    description
    descriptionStyled
    dueDate
    creationTime
    updateTime
    createdBy {
      id
      email
      name
      picture
    }
    milestone {
      id
      name
      date
      buckets {
        id
        name
      }
    }
    categories {
      ...commonCategoryFields
    }
    name
    number
    cost {
      ...costTypeFields
    }
    estimateCost {
      ...costTypeFields
    }
    contingencyDrawCost {
      ...costTypeFields
    }
    assignee {
      ...commonUserFields
      status
    }
    status
    visibility
    availableStates
    assetCount
    remoteAssetCount
    commentCount
    previousMilestoneStatus {
      hasEstimate
      milestoneID
      optionSubtotals {
        status
        adds
        deducts
      }
      itemType
      cost {
        ...costTypeFields
      }
      estimateCost {
        ...costTypeFields
      }
      contingencyDrawCost {
        ...costTypeFields
      }
      status
    }
    filteredMilestone
    filteredCategories
    scheduleImpact {
      ...scheduleImpactFields
    }
    activityIDs
  }
  ${commonCategoryFields}
  ${commonUserFields}
  ${costTypeFields}
  ${scheduleImpactFields}
`;

export const commonCategorizationFields = gql`
  fragment commonCategorizationFields on Categorization {
    id
    name
    description
    levels
    builtin
  }
`;

export const commonMarkupFields = gql`
  fragment commonMarkupFields on Markup {
    id
    estimateId
    errors {
      field
      error
    }
    name
    percent
    percentScale
    markupReference {
      appliesTo
      item {
        number
        name
        isDeprecated
      }
    }
    categoryFilters {
      categorization {
        id
        name
      }
      id
      name
      number
      level
      error
    }
    sourceFilterIDs
    sourceFilterIDsWithoutS2Reference
    total
    type
    displayType
    drawFromID
    value
    source {
      itemID
      estimateID
      milestoneID
      lineID
      markupID
    }
    disabled
    orderingNumerator
    orderingDenominator
    costTypeFilters
    shouldExcludeFromAllocation
    shouldNotApplyCategoryFiltersToOtherMarkups
  }
`;

export const commonItemLikeOptionFields = gql`
  fragment commonItemLikeOptionFields on Option {
    id
    itemType
    bucket {
      id
      name
      date
    }
    project {
      id
      name
    }
    description
    descriptionStyled
    dueDate
    creationTime
    updateTime
    createdBy {
      id
      email
      name
      picture
    }
    milestone {
      id
      name
      date
      buckets {
        id
        name
      }
    }
    categories {
      ...commonCategoryFields
    }
    name
    number
    cost {
      ...costTypeFields
    }
    estimateCost {
      ...costTypeFields
    }
    contingencyDrawCost {
      ...costTypeFields
    }
    assignee {
      ...commonUserFields
      status
    }
    status
    visibility
    availableStates
    assetCount
    remoteAssetCount
    commentCount
    previousMilestoneStatus {
      hasEstimate
      milestoneID
      optionSubtotals {
        status
        adds
        deducts
      }
      itemType
      cost {
        ...costTypeFields
      }
      estimateCost {
        ...costTypeFields
      }
      contingencyDrawCost {
        ...costTypeFields
      }
      status
    }
    filteredMilestone
    filteredCategories
    scheduleImpact {
      ...scheduleImpactFields
    }
    activityIDs
  }
  ${commonCategoryFields}
  ${commonUserFields}
  ${costTypeFields}
  ${scheduleImpactFields}
`;

export const commonCategoryUniqueFields = gql`
  fragment commonCategoryUniqueFields on Category {
    categorization {
      id
      name
    }
    id
    parentID
    name
    hasSubCategories
    number
    level
    levels {
      id
      name
      number
    }
    error
  }
`;

export const importEstimateErrorFields = gql`
  fragment importEstimateErrorFields on ImportEstimateError {
    id
    type
    level
    unresolvedCount
    resolvedCount
    created
    updated
    content {
      ... on ImportErrorContentMissingCategories {
        categorization {
          id
          name
        }
        lines
        categories {
          number
          name
        }
      }
    }
  }
`;

export const fullCategoryCategorizationFields = gql`
  fragment fullCategoryCategorizationFields on Category {
    categorization {
      id
      name
    }
    id
    name
    hasSubCategories
    number
    level
    error
  }
`;

export const commonCellFields = gql`
  fragment commonCellFields on Cell {
    estimateId
    field
    line
    value {
      ... on SourceCell {
        sourceIDs {
          estimateID
          itemID
          lineID
          markupID
          milestoneID
        }
      }
      ... on CategoryCell {
        category {
          ...commonCategoryUniqueFields
        }
        search
      }
      ... on RegularCell {
        string
      }
    }
    error
  }
  ${commonCategoryUniqueFields}
`;

const categoryGridFields = gql`
  fragment categoryGridFields on Category {
    categorization {
      id
      name
    }
    id
    name
    number
    error
  }
`;

const gridCellFields = gql`
  fragment gridCellFields on Cell {
    value {
      ... on SourceCell {
        sourceIDs {
          estimateID
          itemID
          lineID
          markupID
          milestoneID
        }
      }
      ... on CategoryCell {
        category {
          ...categoryGridFields
        }
        search
      }
      ... on RegularCell {
        string
        formula
        formulaDisplay {
          type
          text
          error
        }
      }
    }
    error
  }
  ${categoryGridFields}
`;

export const indexedGridCellFields = gql`
  fragment indexedGridCellFields on Cell {
    ...gridCellFields
    field
    line
  }
  ${gridCellFields}
`;

export const gridLineFields = gql`
  fragment gridLineFields on Line {
    id
    cells {
      ...gridCellFields
    }
    orderingNumerator
    orderingDenominator
  }
  ${gridCellFields}
`;

export const commonLineFields = gql`
  fragment commonLineFields on Line {
    id
    estimateId
    cells {
      ...commonCellFields
    }
    orderingNumerator
    orderingDenominator
  }
  ${commonCellFields}
`;

export const gridFieldFields = gql`
  fragment gridFieldFields on Field {
    id
    name
    type
    group
    categorization {
      ...commonCategorizationFields
      createdFrom {
        id
      }
    }
    errors {
      type
      count
      categoryErrorIndicies {
        categoryNumber
        indicies
      }
    }
  }
  ${commonCategorizationFields}
`;

export const limitedUpdateEstimateResult = gql`
  fragment limitedUpdateEstimateResult on EstimateUpdateResult {
    estimate {
      fields {
        ...gridFieldFields
      }
      inheritedMarkups {
        ...commonMarkupFields
      }
      markups {
        ...commonMarkupFields
      }
      incorporatedMarkups {
        ...commonMarkupFields
      }
      incorporatedDraws {
        ...commonMarkupFields
      }
      inheritedOwnerCostMarkups {
        ...commonMarkupFields
      }
      ownerCostEstimate {
        ...gridEstimateArmatureFields
      }
      subtotal
      markupSubtotal
      inheritedSubtotal
      incorporatedSubtotal
      incorporatedDrawsSubtotal
      inheritedOwnerCostMarkupsSubtotal
    }
  }
  ${gridFieldFields}
  ${commonMarkupFields}
  ${GridEstimateArmatureFieldsFragmentDoc}
`;

export const quantityUpdateEstimateResult = gql`
  fragment quantityUpdateEstimateResult on EstimateUpdateResult {
    estimate {
      fields {
        ...gridFieldFields
      }
      subtotal
    }
  }
  ${gridFieldFields}
`;

export const commonEstimateFields = gql`
  fragment commonEstimateFields on Estimate {
    id
    lines {
      ...commonLineFields
    }
    name
    fields {
      ...gridFieldFields
    }
    subtotal
    markupSubtotal
    incorporatedMarkups {
      ...commonMarkupFields
    }
    contingencyDraws {
      id
      type
      name
      error
      draw
      drawFromID
      isDrawingFullAmount
    }
    drawSubtotal
    inheritedSubtotal
    inheritedMarkups {
      ...commonMarkupFields
    }
    inheritedOwnerCostMarkups {
      ...commonMarkupFields
    }
    markups {
      ...commonMarkupFields
    }
    inheritedOwnerCostMarkups {
      ...commonMarkupFields
    }
    inheritedOwnerCostMarkupsSubtotal
    asset
    totalType
    isRunningTotal
  }
  ${gridFieldFields}
  ${commonLineFields}
  ${commonMarkupFields}
`;

export const draftEstimateInfoFields = gql`
  fragment draftEstimateInfoFields on DraftEstimateInfo {
    author
    asset
  }
`;

export const milestoneLinkFields = gql`
  fragment milestoneLinkFields on MilestoneLink {
    id
    name
  }
`;

export const userLinkFieldsWithoutPicture = gql`
  fragment userLinkFieldsWithoutPicture on UserLink {
    id
    name
    status
  }
`;

export const itemLinkFields = gql`
  fragment itemLinkFields on ItemLink {
    id
    name
    number
    status
    visibility
  }
`;

export const itemHistoryLinkFields = gql`
  fragment itemHistoryLinkFields on ItemHistoryLink {
    id
    name
    number
    status
    deprecated
    visibility
  }
`;

const itemsListItemSummaryFields = gql`
  fragment itemsListItemSummaryFields on ItemsListItem {
    createdAt
    creator {
      ...userLinkFieldsWithoutPicture
    }
    currentCost {
      ...costTypeFields
    }

    currentMilestone {
      ...milestoneLinkFields
    }

    currentStatus

    id
    name
    number
    options {
      ...itemLinkFields
    }
    status
    updateTime
    visibility
  }
  ${costTypeFields}
  ${itemLinkFields}
  ${milestoneLinkFields}
  ${userLinkFieldsWithoutPicture}
`;

export const commonCommentFields = gql`
  fragment commonCommentFields on Comment {
    author {
      ...commonUserFields
      status
    }
    id
    created
    text {
      contents
      mentions {
        id
        position
        text
        user {
          id
        }
      }
      itemReferences {
        id
        position
        text
        referencedItem {
          ...itemsListItemSummaryFields
        }
      }
    }
  }
  ${commonUserFields}
  ${itemsListItemSummaryFields}
`;

export const commonUserReportCommentFields = gql`
  fragment commonUserReportCommentFields on UserReportComment {
    comment {
      ...commonCommentFields
    }
    commentLineID
    type
    costs
  }
  ${commonCommentFields}
`;

export const detailedItemLikeOptionFields = gql`
  fragment detailedItemLikeOptionFields on Option {
    ...commonItemLikeOptionFields
    comments {
      ...commonCommentFields
    }
    assets {
      id
      name
    }
    activeEstimate {
      ...commonEstimateFields
    }
  }
  ${commonItemLikeOptionFields}
  ${commonCommentFields}
  ${commonEstimateFields}
`;

export const detailedItemLikeItemFields = gql`
  fragment detailedItemLikeItemFields on Item {
    ...commonItemLikeItemFields
    comments {
      ...commonCommentFields
    }
    assets {
      id
      name
    }
    activeEstimate {
      ...commonEstimateFields
    }
  }
  ${commonItemLikeItemFields}
  ${commonCommentFields}
  ${commonEstimateFields}
`;

export const detailedOptionFields = gql`
  fragment detailedOptionFields on Option {
    ...detailedItemLikeOptionFields
    parent
    parentVisibility
    hasEstimate
  }
  ${detailedItemLikeOptionFields}
`;

export const detailedItemFields = gql`
  fragment detailedItemFields on Item {
    ...detailedItemLikeItemFields
    options {
      ...detailedOptionFields
    }
    optionSubtotals {
      status
      adds
      deducts
    }
  }
  ${detailedItemLikeItemFields}
  ${detailedOptionFields}
`;

export const sharedDetailedItemLikeFields = gql`
  fragment sharedDetailedItemLikeFields on ItemLike {
    ... on Option {
      ...detailedOptionFields
    }
    ... on Item {
      ...detailedItemFields
    }
  }
  ${detailedOptionFields}
  ${detailedItemFields}
`;

export const sourceItemFields = gql`
  fragment sourceItemFields on Item {
    id
    name
    number
    status
  }
`;

export const sourceOptionFields = gql`
  fragment sourceOptionFields on Option {
    id
    name
    number
    status
  }
`;

export const sharedSourceItemLikeFields = gql`
  fragment sharedSourceItemLikeFields on ItemLike {
    ... on Option {
      ...sourceOptionFields
    }
    ... on Item {
      ...sourceItemFields
    }
  }
  ${sourceOptionFields}
  ${sourceItemFields}
`;

const categorizedItemCostsFields = gql`
  fragment categorizedItemCostsFields on CategorizedItemCost {
    id
    milestoneId
    name
    number
    parentId
    status
    canViewCosts
    categories {
      ...commonCategoryFields
    }
    directCostRange {
      ...costTypeFields
    }
    range {
      ...costTypeFields
    }
    itemContributionLines {
      categories {
        ...commonCategoryFields
      }
      directCostRange {
        ...costTypeFields
      }
      range {
        ...costTypeFields
      }
      lineID
      lineDescription
    }
  }
  ${commonCategoryFields}
  ${costTypeFields}
`;

export const sharedCostReportValueFields = gql`
  fragment sharedCostReportValueFields on CostReportItem {
    subtotalValue {
      ... on SubtotalCost {
        directCostRange {
          ...costTypeFields
        }
        range {
          ...costTypeFields
        }
      }
      ... on SubtotalAddsDeducts {
        directCostSegmented {
          adds
          deducts
        }
        segmented {
          adds
          deducts
        }
      }
      ... on SubtotalNumeric {
        value
      }
    }
  }
  ${costTypeFields}
`;

export const commonCostReportFields = gql`
  fragment commonCostReportFields on CostReport {
    directCostRange {
      ...costTypeFields
    }
    directCostSegmented {
      adds
      deducts
    }
    projectTotalRange {
      ...costTypeFields
    }
    projectTotalSegmented {
      adds
      deducts
    }
    range {
      ...costTypeFields
    }
    segmented {
      adds
      deducts
    }
    numeric
    subtotals {
      categories {
        ...commonCategoryFields
      }
      ...sharedCostReportValueFields
    }
    categorizedItemCosts {
      ...categorizedItemCostsFields
    }
    markupContributions {
      isIncorporated
      isOwnerCost
      markupId
      markupName
      displayType
      range {
        ...costTypeFields
      }
      segmented {
        adds
        deducts
      }
    }
    type
  }
  ${categorizedItemCostsFields}
  ${commonCategoryFields}
  ${costTypeFields}
  ${sharedCostReportValueFields}
`;

export const unitFields = gql`
  fragment unitFields on Unit {
    abbreviationPlural
    abbreviationSingular
    description
    id
    isDefault
    isEnabled
    name
    type
  }
`;

export const quantityFields = gql`
  fragment quantityFields on Quantity {
    id
    magnitude
    unit {
      ...unitFields
    }
    estimateID
    hasBreakdown
  }
  ${unitFields}
`;

export const commonCostReportColumnFields = gql`
  fragment commonCostReportColumnFields on CostReportColumn {
    report {
      ...commonCostReportFields
    }
    type
    columnKey
    quantity {
      ...quantityFields
    }
    isCategorized
  }
  ${quantityFields}
  ${commonCostReportFields}
`;

export const commonProjectStatusFields = gql`
  fragment commonProjectStatusFields on ProjectStatus {
    id
    name
    type
    description
  }
`;

export const commonProjectTypeFields = gql`
  fragment commonProjectTypeFields on ProjectType {
    id
    name
    parentID
    hidden
  }
`;

export const commonProjectDeliveryTypeFields = gql`
  fragment commonProjectDeliveryTypeFields on ProjectDeliveryType {
    id
    name
    abbreviation
  }
`;

export const commonLocationDetailsFields = gql`
  fragment commonLocationDetailsFields on LocationDetails {
    name
    lat
    lon
    city
    state
    stateLong
    country
  }
`;

export const commonProjectPropsFields = gql`
  fragment commonProjectPropsFields on Project {
    activeMilestone {
      id
      name
    }
    id
    description
    descriptionStyled
    name
    code
    status {
      ...commonProjectStatusFields
    }
    team {
      owner {
        name
        picture
      }
      generalContractor {
        name
        picture
      }
      designTeam {
        name
        picture
      }
    }
    type {
      id
      name
    }
    hasAccess
    location
    lat
    lon
    thumbnail
    templateEstimateID
    lastUpdated
    projectDeliveryType {
      ...commonProjectDeliveryTypeFields
    }
    locationDetails {
      ...commonLocationDetailsFields
    }
    orgNodes {
      id
      name
    }
  }
  ${commonProjectStatusFields}
  ${commonProjectDeliveryTypeFields}
  ${commonLocationDetailsFields}
`;

const commonProjectCompsCostTableColumnValueFields = gql`
  fragment commonProjectCompsCostTableColumnValueFields on ProjectCompsCostTableColumnValue {
    valueNumeric
    isEdited
    isMaxValue
    isMinValue
    hasError
    costTableColumnInput {
      key
      unitID
    }
  }
`;

const commonProjectCompsCostTableCostValuesFields = gql`
  fragment commonProjectCompsCostTableCostValuesFields on ProjectCompsCostTableCostValues {
    totalValue {
      ...costTypeFields
    }
    quantityValue {
      ...costTypeFields
    }
    quantityMagnitude
    percentValue
  }
  ${costTypeFields}
`;

const commonProjectCompsCostTableCategoryLineFields = gql`
  fragment commonProjectCompsCostTableCategoryLineFields on ProjectCompsCostTableCategoryLine {
    category {
      ...commonCategoryFields
    }
    description
    pinnedUnitValues {
      ...commonProjectCompsCostTableCostValuesFields
    }
    columnValues {
      ...commonProjectCompsCostTableColumnValueFields
    }
  }
  ${commonCategoryFields}
  ${commonProjectCompsCostTableCostValuesFields}
  ${commonProjectCompsCostTableColumnValueFields}
`;

const commonProjectCompsCostTableMarkupLineFields = gql`
  fragment commonProjectCompsCostTableMarkupLineFields on ProjectCompsCostTableMarkupLine {
    markupName
    description
    pinnedUnitValues {
      ...commonProjectCompsCostTableCostValuesFields
    }
    columnValues {
      ...commonProjectCompsCostTableColumnValueFields
    }
  }
  ${commonProjectCompsCostTableCostValuesFields}
  ${commonProjectCompsCostTableColumnValueFields}
`;

const commonProjectCompsCostTableSummaryLineFields = gql`
  fragment commonProjectCompsCostTableSummaryLineFields on ProjectCompsCostTableSummaryLine {
    pinnedUnitValues {
      ...commonProjectCompsCostTableCostValuesFields
    }
    columnValues {
      ...commonProjectCompsCostTableColumnValueFields
    }
  }
  ${commonProjectCompsCostTableCostValuesFields}
  ${commonProjectCompsCostTableColumnValueFields}
`;

const commonProjectCompsCostTableSummaryLinesFields = gql`
  fragment commonProjectCompsCostTableSummaryLinesFields on ProjectCompsCostTableSummaryLines {
    subtotalLine {
      ...commonProjectCompsCostTableSummaryLineFields
    }
    totalLine {
      ...commonProjectCompsCostTableSummaryLineFields
    }
    markupsTotalLine {
      ...commonProjectCompsCostTableSummaryLineFields
    }
  }
  ${commonProjectCompsCostTableCostValuesFields}
  ${commonProjectCompsCostTableSummaryLineFields}
`;

const commonProjectCompsCostTableFields = gql`
  fragment commonProjectCompsCostTableFields on ProjectCompsCostTable {
    categoryLines {
      ...commonProjectCompsCostTableCategoryLineFields
    }
    markupLines {
      ...commonProjectCompsCostTableMarkupLineFields
    }
    summaryLines {
      ...commonProjectCompsCostTableSummaryLinesFields
    }
  }
  ${commonProjectCompsCostTableCategoryLineFields}
  ${commonProjectCompsCostTableMarkupLineFields}
  ${commonProjectCompsCostTableSummaryLinesFields}
`;

export const commonDisplayCategoryFields = gql`
  fragment commonDisplayCategoryFields on Category {
    id
    name
    number
    level
    error
    categorization {
      id
      name
    }
  }
`;

export const averageCompInputReturnFields = gql`
  fragment averageCompInputReturnFields on AverageCompInputReturn {
    name
    description
    color
    isHidden
    thumbnailAssetID
    metrics {
      unitID
      quantityMagnitude
      hasMilestoneQuantity
    }
    location
    lat
    lon
    categoryLineInputs {
      category {
        name
        number
      }
      description
      editedValue
      cellType
      unitID
    }
    markupLineInputs {
      markupName
      description
      editedValue
      cellType
      unitID
    }
  }
`;

export const projectCompInputReturnFields = gql`
  fragment projectCompInputReturnFields on ProjectCompInputReturn {
    id
    projectID
    escalation {
      time
      timeFuture
      location
      timeMeta {
        isAutoEscalated
        isFuzzyMatch
        sourceLabel
        sourceIsFuzzyMatch
        targetLabel
        targetIsFuzzyMatch
      }
      locationMeta {
        isAutoEscalated
        isFuzzyMatch
        sourceLabel
        sourceIsFuzzyMatch
        targetLabel
        targetIsFuzzyMatch
      }
    }
    name
    description
    metrics {
      unitID
      quantityMagnitude
      hasMilestoneQuantity
    }
    categorizationID
    categorizationLevel
    isExcluded
    milestoneID
    categoryLineInputs {
      category {
        name
        number
      }
      description
      editedValue
      cellType
      unitID
    }
    markupLineInputs {
      markupName
      description
      editedValue
      cellType
      unitID
    }
    viewFilter {
      categories {
        categorizationID
        id
      }
    }
  }
`;

export const projectCompsSetInputReturnFields = gql`
  fragment projectCompsSetInputReturnFields on ProjectCompsSetInputReturn {
    addedMarkupNames
    averageInput {
      ...averageCompInputReturnFields
    }
    costTableColumnInputs {
      key
      unitID
    }
    markupMode
    pinnedUnitID
    projectCompInputs {
      ...projectCompInputReturnFields
    }
    selectedUnitIDs
    excludedCategoryContents {
      number
      name
    }
    excludedMarkupNames
  }
  ${averageCompInputReturnFields}
  ${projectCompInputReturnFields}
`;

export const commonProjectCompFields = gql`
  fragment commonProjectCompFields on ProjectComp {
    input {
      ...projectCompInputReturnFields
    }
    milestone {
      id
      date
      name
    }
    project {
      id
      name
      hasAccess
      location
      lat
      lon
      thumbnail
      status {
        name
      }
      milestones {
        id
        date
        name
      }
      activeMilestone {
        id
      }
      type {
        name
      }
    }
    projectCompsCostTable {
      ...commonProjectCompsCostTableFields
    }
    isResettable
  }
  ${commonProjectCompsCostTableFields}
  ${projectCompInputReturnFields}
`;

export const commonAverageCompFields = gql`
  fragment commonAverageCompFields on AverageComp {
    input {
      ...averageCompInputReturnFields
    }
    projectCompsCostTable {
      ...commonProjectCompsCostTableFields
    }
    isHidden
    isResettable
  }
  ${averageCompInputReturnFields}
  ${commonCategoryFields}
  ${commonProjectCompsCostTableFields}
`;

export const commonUserReportFields = gql`
  fragment commonUserReportFields on UserReport {
    id
    milestoneID
    reportType
    shared
    owner
    name
    description
    settings
    createdBy {
      id
      name
      email
    }
    updatedAt
    hasDistributionScheduled
    isFollowingActiveMilestone
  }
`;

export const commonReportDistributionFields = gql`
  fragment commonReportDistributionFields on ReportDistribution {
    cadence
    createdBy
    id
    reportID
    collaboratorIDs
    startDate
  }
`;

export const commonRoleFields = gql`
  fragment commonRoleFields on Role {
    id
    name
    type
    hasTrade
    permissionGroups {
      type
      permissions {
        id
        level
        resource
        actions {
          view
          add
          delete
          edit
        }
      }
    }
  }
`;

const commonItemLikeSummaryFields = gql`
  fragment commonItemLikeSummaryFields on ItemLikeSummary {
    id
    number
    kind
    visibility
  }
`;

const minimalBucketFields = gql`
  fragment minimalBucketFields on Bucket {
    id
    name
    date
    milestone {
      id
    }
  }
`;

export const commonTradeFields = gql`
  fragment commonTradeFields on Category {
    categorization {
      ...commonCategorizationFields
      draft
    }
    id
    name
    number
    hasSubCategories
    level
  }
  ${commonCategorizationFields}
`;

export const commonCollaboratorFields = gql`
  fragment commonCollaboratorFields on Collaborator {
    allTrades
    id
    isProjectLead
    responsibility
    role {
      ...commonRoleFields
    }
    trades {
      ...commonTradeFields
    }
    user {
      ...commonUserFields
      status
    }
  }
  ${commonRoleFields}
  ${commonTradeFields}
  ${commonUserFields}
`;

export const commonCompanyRoleFields = gql`
  fragment commonCompanyRoleFields on CompanyRole {
    id
    name
    permissionGroups {
      type
      permissions {
        id
        level
        resource
        actions {
          view
          add
          delete
          edit
        }
      }
    }
  }
`;

export const assetFields = gql`
  fragment assetFields on Asset {
    id
    derivations {
      id
      kind
      contents
    }
    name
    size
    lastUpdated
    location
    uploadedBy {
      id
      name
      email
    }
    type @client
  }
`;

export const remoteAssetFields = gql`
  fragment remoteAssetFields on RemoteAsset {
    id
    sourceAssetID
    name
    lastUpdated
    remoteLink
    uploadedBy {
      id
      name
      email
    }
    sourceSystem
  }
`;

export const attachmentFieldsQuery = gql`
  fragment attachmentFieldsQuery on Attachment {
    ... on Asset {
      ...assetFields
    }
    ... on RemoteAsset {
      ...remoteAssetFields
    }
  }
  ${assetFields}
  ${remoteAssetFields}
`;

const commonItemHistoryEventContentFields = gql`
  fragment commonItemHistoryEventContentFields on ItemHistoryEventContent {
    newNumber
    oldNumber
    name
    description
    descriptionStyled
    newMilestoneID
    oldMilestoneID
    status
    visibility
    dueDate
    assignee {
      ...commonUserFields
      status
    }
    deletedBy {
      ...commonUserFields
    }
    sharedUsers {
      ...commonUserFields
    }
    meeting {
      ...minimalBucketFields
    }
    addedAttachments {
      ...attachmentFieldsQuery
    }
    removedAssets {
      name
    }
    newCost {
      ...costTypeFields
    }
    oldCost {
      ...costTypeFields
    }
    categoryChanges {
      oldCategory {
        ...commonCategoryFields
      }
      newCategory {
        ...commonCategoryFields
      }
    }
    costChanges {
      oldCost {
        ...costTypeFields
      }
      newCost {
        ...costTypeFields
      }
      timestamp
    }
    scheduleImpactChanges {
      old {
        ...scheduleImpactFields
      }
      new {
        ...scheduleImpactFields
      }
      timestamp
    }
    oldTimelineActivities {
      id
      name
    }
    newTimelineActivities {
      id
      name
    }
    itemLikeSummary {
      ...commonItemLikeSummaryFields
    }
    removedOptions {
      ...commonItemLikeSummaryFields
    }
    attachedOptions {
      ...commonItemLikeSummaryFields
    }
    drawName
    drawFromValue
    drawToValue
    statusChangeDraws {
      name
      value
    }
    drawChanges {
      name
      fromValue
      toValue
      timestamp
    }
    referencedByItems {
      ...commonItemLikeSummaryFields
    }
    comment {
      ...commonCommentFields
    }
    copiedItem {
      id
      name
      number
      projectID
      status
      visibility
      hasAccess
    }
    itemIntegrationObject {
      sourceEntityID
      number
      title
    }
    milestoneID
  }
  ${attachmentFieldsQuery}
  ${commonCategoryFields}
  ${commonCommentFields}
  ${commonItemLikeSummaryFields}
  ${commonUserFields}
  ${costTypeFields}
  ${minimalBucketFields}
  ${scheduleImpactFields}
`;

export const itemHistoryFields = gql`
  fragment itemHistoryFields on ItemHistory {
    events {
      id
      user {
        ...commonUserFields
        status
      }
      itemID
      eventTypes
      timestamp
      eventContent {
        ...commonItemHistoryEventContentFields
      }
    }
  }
  ${commonUserFields}
  ${commonItemHistoryEventContentFields}
`;

export const itemActivityEventFields = gql`
  fragment itemActivityEventFields on ItemHistoryEvent {
    id
    user {
      ...commonUserFields
      status
    }
    itemID
    eventTypes
    timestamp
    eventContent {
      ...commonItemHistoryEventContentFields
    }
  }
  ${commonUserFields}
  ${commonItemHistoryEventContentFields}
`;

export const itemActivityFields = gql`
  fragment itemActivityFields on ItemActivity {
    paginationData {
      nextOffsetID
    }
    data {
      ...itemActivityEventFields
    }
  }
  ${itemActivityEventFields}
`;

export const eventDataItemFields = gql`
  fragment eventDataItemFields on EventItem {
    id
    cost {
      ...costTypeFields
    }
    name
    number
    status
    milestone {
      id
    }
  }
  ${costTypeFields}
`;

export const itemEventSummaryFields = gql`
  fragment itemEventSummaryFields on ItemSummary {
    id
    number
    currentNumber
    status
  }
`;

export const commonEventDataFields = gql`
  fragment commonEventDataFields on EventData {
    events {
      timestamp
      eventType
      item {
        ...eventDataItemFields
      }
      currentItemNumber
      user {
        ...commonUserFields
        status
      }
      costImpact {
        minPending
        maxPending
        cost
      }
      eventContent {
        oldCost {
          ...costTypeFields
        }
        newCost {
          ...costTypeFields
        }
        option {
          ...itemEventSummaryFields
        }
        options {
          ...itemEventSummaryFields
        }
        oldMilestone
        newMilestone
        oldOptionStatus
        newOptionStatus
        oldStatus
        newStatus
        parent {
          ...itemEventSummaryFields
        }
      }
      ids
      visible
    }
  }
  ${commonUserFields}
  ${eventDataItemFields}
  ${itemEventSummaryFields}
`;

export const shareResourcesFields = gql`
  fragment shareResourcesFields on ShareResourcesPayload {
    resources {
      id
      type
      accessLevels
      users {
        id
        email
        isEmailVerified
        jobTitle
        name
        picture
        status
      }
    }
  }
`;

export const getShareResourceFields = gql`
  fragment getShareResourceFields on GetSharedResourcePayload {
    resource {
      id
      type
      accessLevels
      users {
        id
        email
        isEmailVerified
        jobTitle
        name
        picture
        status
      }
    }
  }
`;

export const commonRemoteAssetFields = gql`
  fragment commonRemoteAssetFields on RemoteAsset {
    id
    name
    lastUpdated
    remoteLink
    uploadedBy {
      id
      name
      email
    }
  }
`;

export const attachmentFields = gql`
  fragment attachmentFields on Attachment {
    ... on Asset {
      ...assetFields
    }
    ... on RemoteAsset {
      ...commonRemoteAssetFields
    }
  }
  ${assetFields}
  ${commonRemoteAssetFields}
`;

export const detailedMilestoneFields = gql`
  fragment detailedMilestoneFields on Milestone {
    id
    buckets {
      date
      id
      name
      itemsCount
    }
    name
    description
    descriptionStyled
    deletable
    date
    isDraft
    createdBy {
      id
      name
      email
    }
    designPhase {
      id
      name
      abbreviation
    }
    timelinePhase {
      id
      name
      startDate
      endDate
    }
    itemsCount
    draftItemsCount
    assetCount
    remoteAssetCount
    activeEstimateID
    activeEstimateDraftID
    budgetID
    budgetDraftID
    importedEstimates {
      ...assetFields
    }
    quantities {
      ...quantityFields
    }
  }
  ${assetFields}
  ${quantityFields}
`;

export const commonUserNotificationSettingFields = gql`
  fragment commonUserNotificationSettingFields on UserNotificationSetting {
    assignments
    channel
    comments
    isGlobalSubscribed
    mentions
    newItems
    shareScenarios
  }
`;

export const commonProjectFields = gql`
  fragment commonProjectFields on Project {
    activeMilestone {
      id
      name
    }
    id
    description
    descriptionStyled
    name
    status {
      ...commonProjectStatusFields
    }
    team {
      owner {
        name
        picture
      }
      generalContractor {
        name
        picture
      }
      designTeam {
        name
        picture
      }
    }
    type {
      id
      name
    }
    location
    milestones {
      ...detailedMilestoneFields
    }
    thumbnail
    roles {
      ...commonRoleFields
    }
    projectDeliveryType {
      ...commonProjectDeliveryTypeFields
    }
    locationDetails {
      ...commonLocationDetailsFields
    }
  }
  ${detailedMilestoneFields}
  ${commonRoleFields}
  ${commonProjectStatusFields}
  ${commonProjectDeliveryTypeFields}
  ${commonLocationDetailsFields}
`;

export const commonTriggeringUserFields = gql`
  fragment commonTriggeringUserFields on User {
    name
    email
    id
  }
`;

export const commonNotificationProjectFields = gql`
  fragment commonNotificationProjectFields on Project {
    name
    id
  }
`;

export const assignItemFields = gql`
  fragment assignItemFields on AssignItem {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
`;

export const assignProjectLeadFields = gql`
  fragment assignProjectLeadFields on AssignProjectLead {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
`;

export const shareDraftItemFields = gql`
  fragment shareDraftItemFields on ShareDraftItem {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
`;

export const shareScenarioFields = gql`
  fragment shareScenarioFields on ShareScenario {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    scenario {
      id
      name
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
`;

export const newMentionFields = gql`
  fragment newMentionFields on NewMention {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
    comment {
      ...commonCommentFields
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
  ${commonCommentFields}
`;

export const NewReportMentionFields = gql`
  fragment NewReportMentionFields on NewReportMention {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    reportID
    reportName
    userReportComment {
      ...commonUserReportCommentFields
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
  ${commonUserReportCommentFields}
`;

export const newCommentFields = gql`
  fragment newCommentFields on NewComment {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
    comment {
      ...commonCommentFields
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
  ${commonCommentFields}
`;

export const newItemFields = gql`
  fragment newItemFields on NewItem {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    item {
      id
      name
      number
      visibility
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
`;

export const newOptionFields = gql`
  fragment newOptionFields on NewOption {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    parentItem {
      id
      name
      number
      visibility
    }
    option {
      id
      name
      number
      visibility
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
`;

export const projectInviteFields = gql`
  fragment projectInviteFields on InviteToProject {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
    message
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
`;

export const projectRemoveFields = gql`
  fragment projectRemoveFields on RemoveFromProject {
    triggeringUser {
      ...commonTriggeringUserFields
    }
    project {
      ...commonNotificationProjectFields
    }
  }
  ${commonTriggeringUserFields}
  ${commonNotificationProjectFields}
`;

export const commonInAppNotificationFields = gql`
  fragment commonInAppNotificationFields on InAppNotification {
    id
    notificationType
    projectID
    content {
      ... on AssignItem {
        ...assignItemFields
      }
      ... on AssignProjectLead {
        ...assignProjectLeadFields
      }
      ... on NewMention {
        ...newMentionFields
      }
      ... on NewReportMention {
        ...NewReportMentionFields
      }
      ... on InviteToProject {
        ...projectInviteFields
      }
      ... on NewComment {
        ...newCommentFields
      }
      ... on NewItem {
        ...newItemFields
      }
      ... on NewOption {
        ...newOptionFields
      }
      ... on RemoveFromProject {
        ...projectRemoveFields
      }
      ... on ShareDraftItem {
        ...shareDraftItemFields
      }
      ... on ShareScenario {
        ...shareScenarioFields
      }
    }
    createdAt
    readAt
  }
  ${assignItemFields}
  ${assignProjectLeadFields}
  ${newCommentFields}
  ${newItemFields}
  ${newMentionFields}
  ${newOptionFields}
  ${NewReportMentionFields}
  ${projectInviteFields}
  ${projectRemoveFields}
  ${shareDraftItemFields}
  ${shareScenarioFields}
`;

export const commonInAppNotificationsFields = gql`
  fragment commonInAppNotificationsFields on InAppNotifications {
    inAppNotifications {
      ...commonInAppNotificationFields
    }
    paginationData {
      nextOffsetID
    }
  }
  ${commonInAppNotificationFields}
`;

export const commonMilestoneFields = gql`
  fragment commonMilestoneFields on Milestone {
    id
    name
    description
    descriptionStyled
    deletable
    date
    isDraft
    createdBy {
      id
    }
    itemsCount
    draftItemsCount
    designPhase {
      id
      name
      abbreviation
    }
    timelinePhase {
      id
      name
      startDate
      endDate
    }
    buckets {
      id
      name
      itemsCount
    }
    activeEstimateID
    activeEstimateDraftID
    budgetID
    budgetDraftID
    quantities {
      ...quantityFields
    }
  }
  ${quantityFields}
`;

export const commonProjectCompsSetFields = gql`
  fragment commonProjectCompsSetFields on ProjectCompsSet {
    input {
      ...projectCompsSetInputReturnFields
    }
    averageComp {
      ...commonAverageCompFields
    }
    projectComps {
      ...commonProjectCompFields
    }
    categories {
      ...commonDisplayCategoryFields
    }
    units {
      ...unitFields
    }
    selectedUnits {
      ...unitFields
    }
    unitCounts {
      unitID
      countText
    }
    markups {
      name
      isCustom
    }
  }
  ${commonAverageCompFields}
  ${commonDisplayCategoryFields}
  ${commonProjectCompFields}
  ${projectCompsSetInputReturnFields}
  ${unitFields}
`;

export const estimateTotalTypeConflictInfoFields = gql`
  fragment estimateTotalTypeConflictInfoFields on EstimateTotalTypeConflictInfo {
    hasConflict
    mostDetailedEstimateTotalType
  }
`;

export const projectComparisonReportFields = gql`
  fragment projectComparisonReportFields on ProjectComparisonReport {
    id
    attachedProject {
      id
      name
      location
      type {
        id
        name
      }
      hasAccess
    }
    name
    description
    createdAt
    createdBy {
      id
      name
      email
    }
    updatedAt
    updatedBy {
      id
      name
      email
    }
    metadata {
      averageQuantityCost
      averageTotalCost
      pinnedUnit {
        ...unitFields
      }
      pinnedUnitQuantityMagnitude
      projectsCount
    }
  }
  ${unitFields}
`;

export const procoreChangeEventFields = gql`
  fragment procoreChangeEventFields on ProcoreChangeEvent {
    integrationObjectID
    number
    alphanumericNumber
    title
    description
    status
    eventScope
    eventType
    createdAt
    updatedAt
    changeReason
    cost
    isDeleted
  }
`;

export const autodeskPotentialChangeOrderFields = gql`
  fragment autodeskPotentialChangeOrderFields on AutodeskPotentialChangeOrder {
    integrationObjectID
    number
    title
    description
    eventType
    createdAt
    updatedAt
    cost
  }
`;

export const organizationFields = gql`
  fragment organizationFields on Org {
    id
    isDraft
    name
    levels
    nodes {
      id
      name
      parentID
    }
  }
`;

export const insightsProjectFields = gql`
  fragment insightsProjectFields on InsightsProject {
    id
    name
    projectLeadName: projectLead
    type: projectType
    location
    status
    lastUpdated
    lastUpdatedBy
    deliveryType
    milestoneDesignPhase
    milestoneGSF
    runningTotal
    budget
    estimate
    milestones {
      id
      name
      date
      active
      designPhase
    }
    orgNodes {
      id
      name
    }
    costTimeseries {
      date
      budget
      estimate
      runningTotal
      pendingMin
      pendingMax
    }
    contingenciesTimeseries {
      date
      allowanceUsed
      allowanceRemaining
      allowancePending
      allowanceTotal
      allowanceDraw
      allowanceNumDraws
      allowanceNumPendingDraws
      contingencyUsed
      contingencyRemaining
      contingencyPending
      contingencyTotal
      contingencyDraw
      contingencyNumDraws
      contingencyNumPendingDraws
    }
    pendingItemsPastDue
    pendingItemsDueInFuture
    pendingItemsMissingDueDates
    dueNext14DaysItems
    hasAccess
    thumbnail
    demoData
    activeAlerts {
      alertID
      description
      alertType
    }
  }
`;

export const insightsTriggerFields = gql`
  fragment insightsTriggerFields on InsightsTrigger {
    id
    metricType
    operator
    threshold
    metricID
  }
`;

export const programProjectFields = gql`
  fragment programProjectFields on ProgramProject {
    id
    name
    typeID
    thumbnail
    locationDetails {
      ...commonLocationDetailsFields
    }
  }
  ${commonLocationDetailsFields}
`;

export const searchProjectFields = gql`
  fragment searchProjectFields on ForecastingProject {
    budget
    estimate
    hasAccess
    id
    location
    milestoneDate
    milestoneGSF
    milestoneID
    milestoneName
    name
    orgNodes {
      id
      name
    }
    projectLeadName
    runningTotal
    status
    thumbnail
    type
    updatedAt
    updatedBy
  }
`;

export const programFields = gql`
  fragment programFields on Program {
    id
    name
    typeID
    type
    imageProjectID
    thumbnail
    locationDetails {
      ...commonLocationDetailsFields
    }
    projects {
      ...searchProjectFields
    }
    updatedAt
    updatedBy
    hasAccess
  }
  ${commonLocationDetailsFields}
  ${searchProjectFields}
`;

export const programCostFields = gql`
  fragment programCostFields on ProgramCosts {
    programID
    estimate
    budget
    runningTotal
    gap
    pendingMin
    pendingMax
    accepted
  }
`;

export const projectCostBreakdownFields = gql`
  fragment projectCostBreakdownFields on ProjectCostBreakdown {
    name
    cost
    percent
    hasEstimate
  }
`;

export const projectCostBreakdownReportFields = gql`
  fragment projectCostBreakdownReportFields on ProjectCostBreakdownReport {
    breakdown {
      ...projectCostBreakdownFields
    }
    total
  }
  ${projectCostBreakdownFields}
`;

export const programCostBreakdownFields = gql`
  fragment programCostBreakdownFields on ProgramCostBreakdownReport {
    projects {
      ...projectCostBreakdownFields
    }
    program {
      ...projectCostBreakdownFields
    }
    total
  }
  ${projectCostBreakdownFields}
`;

export const programRoleFields = gql`
  fragment programRoleFields on ProgramRole {
    projectName
    roleName
  }
`;

export const programUserFields = gql`
  fragment programUserFields on ProgramUser {
    id
    name
    picture
    pictureAsset {
      thumbnailURL
    }
  }
`;

export const programCollaboratorFields = gql`
  fragment programCollaboratorFields on ProgramCollaborator {
    user {
      ...programUserFields
    }
    hasAccess
    company
    mostCommonRoleName
    roles {
      ...programRoleFields
    }
  }
  ${programUserFields}
  ${programRoleFields}
`;
