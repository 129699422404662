import { isEnumValue } from '../utilities/types';

// 1. Add a new entry to RouteKeys
export enum RouteKeys {
  SPLAT = 'SPLAT',

  // The enum names should reflect the url path. For example, if the path is
  // ':projectId/team/roles', the enum name should be 'PROJECT_TEAM_ROLES'. This
  // maintains a level of specificity that provides clarity on how links are
  // used within our app and prevents conflicting or confusing path names.
  APOLLO = 'APOLLO',
  AUTHORIZE = 'AUTHORIZE',
  COMPANY = 'COMPANY',
  DEACTIVATED = 'DEACTIVATED',
  FORECASTING = 'FORECASTING',
  FORECASTING_EXPLORE = 'FORECASTING_EXPLORE',
  FORECASTING_PROJECT_COMPS = 'FORECASTING_PROJECT_COMPS',
  FORECASTING_PROJECT_COMPS_PRINT = 'FORECASTING_PROJECT_COMPS_PRINT',
  FORECASTING_PROJECT_COMPS_SAVED = 'FORECASTING_PROJECT_COMPS_SAVED',
  FORECASTING_PROJECT_COMPS_SAVED_PRINT = 'FORECASTING_PROJECT_COMPS_SAVED_PRINT',
  FORECASTING_REPORTS = 'FORECASTING_REPORTS',
  INSIGHTS = 'INSIGHTS',
  INSIGHTS_CLASSIC = 'INSIGHTS_CLASSIC',
  INSIGHTS_PROJECT = 'INSIGHTS_PROJECT',
  NOT_FOUND = 'NOT_FOUND',
  PROGRAM = 'PROGRAM',
  PROGRAM_DASHBOARD = 'PROGRAM_DASHBOARD',
  PROGRAM_DASHBOARD_PRINT = 'PROGRAM_DASHBOARD_PRINT',
  PROJECT = 'PROJECT',
  PROJECT_DASHBOARD = 'PROJECT_DASHBOARD',
  PROJECT_IDEAS = 'PROJECT_IDEAS',
  PROJECT_IDEAS_IDEA = 'PROJECT_IDEAS_IDEA',
  PROJECT_IDEAS_INDEX = 'PROJECT_IDEAS_INDEX',
  PROJECT_ITEMS = 'PROJECT_ITEMS',
  PROJECT_ITEMS_ACTIVITY = 'PROJECT_ITEMS_ACTIVITY',
  PROJECT_ITEMS_INDEX = 'PROJECT_ITEMS_INDEX',
  PROJECT_ITEMS_ITEM = 'PROJECT_ITEMS_ITEM',
  PROJECT_ITEMS_ITEM_ESTIMATE = 'PROJECT_ITEMS_ITEM_ESTIMATE',
  PROJECT_ITEMS_ITEM_INDEX = 'PROJECT_ITEMS_ITEM_INDEX',
  PROJECT_SCENARIOS = 'PROJECT_SCENARIOS',
  PROJECT_TEAM = 'PROJECT_TEAM',
  PROJECT_TEAM_COMPANIES = 'PROJECT_TEAM_COMPANIES',
  PROJECT_TEAM_ROLES = 'PROJECT_TEAM_ROLES',
  PROJECT_TEAM_ROLES_ROLE_EDIT = 'PROJECT_TEAM_ROLES_ROLE_EDIT',
  PROJECT_TEAM_ROLES_ROLE_VIEW = 'PROJECT_TEAM_ROLES_ROLE_VIEW',
  PROJECT_TEAM_TEAMMATES = 'PROJECT_TEAM_TEAMMATES',
  ROOT = 'ROOT',
  SEARCH_ITEMS = 'SEARCH_ITEMS',
  SEARCH_PROJECTS = 'SEARCH_PROJECTS',
}

type IndexRoutePath = { index: true };
type NonIndexRoutePath = { path: string; children?: RoutePaths };
type RoutePath = IndexRoutePath | NonIndexRoutePath;

type RoutePaths = { [key in RouteKeys]?: RoutePath };

// 2. Define your path
const routePaths: RoutePaths = {
  [RouteKeys.APOLLO]: { path: '/apollo' },
  [RouteKeys.DEACTIVATED]: { path: '/deactivated' },
  [RouteKeys.NOT_FOUND]: { path: '/404' },
  [RouteKeys.ROOT]: {
    path: '',
    children: {
      [RouteKeys.SEARCH_PROJECTS]: { path: '' },
      [RouteKeys.AUTHORIZE]: { path: 'authorize/:system' },
      [RouteKeys.SEARCH_ITEMS]: { path: 'items' },
      [RouteKeys.COMPANY]: { path: 'company', children: {} },
      [RouteKeys.FORECASTING]: {
        path: 'forecasting',
        children: {
          [RouteKeys.FORECASTING_EXPLORE]: { path: 'explore' },
          [RouteKeys.FORECASTING_REPORTS]: { path: 'reports' },
          [RouteKeys.FORECASTING_PROJECT_COMPS]: { path: 'project-comparison' },
          [RouteKeys.FORECASTING_PROJECT_COMPS_SAVED]: { path: 'project-comparison/:reportID' },
          [RouteKeys.FORECASTING_PROJECT_COMPS_PRINT]: { path: 'project-comparison/print' },
          [RouteKeys.FORECASTING_PROJECT_COMPS_SAVED_PRINT]: { path: 'project-comparison/:reportID/print' },
        },
      },
      [RouteKeys.INSIGHTS]: { path: 'insights', children: { [RouteKeys.INSIGHTS_PROJECT]: { path: ':projectId' } } },
      [RouteKeys.INSIGHTS_CLASSIC]: { path: 'insights-classic' },
      [RouteKeys.PROGRAM]: {
        path: 'program',
        children: {
          [RouteKeys.PROGRAM_DASHBOARD]: {
            path: ':programID',
          },
          [RouteKeys.PROGRAM_DASHBOARD_PRINT]: { path: ':programID/print' },
        },
      },
      [RouteKeys.PROJECT]: {
        path: ':projectId',
        children: {
          [RouteKeys.PROJECT_DASHBOARD]: { path: 'dashboard' },
          [RouteKeys.PROJECT_IDEAS]: {
            path: 'ideas',
            children: {
              [RouteKeys.PROJECT_IDEAS_INDEX]: { index: true },
              [RouteKeys.PROJECT_IDEAS_IDEA]: { path: ':ideaID' },
            },
          },
          [RouteKeys.PROJECT_ITEMS]: {
            path: 'items',
            children: {
              [RouteKeys.PROJECT_ITEMS_INDEX]: { index: true },
              [RouteKeys.PROJECT_ITEMS_ACTIVITY]: { path: 'item-activity' },
              [RouteKeys.PROJECT_ITEMS_ITEM]: {
                path: ':itemId',
                children: {
                  [RouteKeys.PROJECT_ITEMS_ITEM_INDEX]: { index: true },
                  [RouteKeys.PROJECT_ITEMS_ITEM_ESTIMATE]: { path: 'estimate' },
                },
              },
            },
          },
          [RouteKeys.PROJECT_SCENARIOS]: { path: 'scenarios' },
          [RouteKeys.PROJECT_TEAM]: {
            path: 'team',
            children: {
              [RouteKeys.PROJECT_TEAM_TEAMMATES]: { path: 'teammates' },
              [RouteKeys.PROJECT_TEAM_COMPANIES]: { path: 'companies' },
              [RouteKeys.PROJECT_TEAM_ROLES]: {
                path: 'roles',
                children: {
                  [RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW]: { path: ':roleId' },
                  [RouteKeys.PROJECT_TEAM_ROLES_ROLE_EDIT]: { path: ':roleId/edit' },
                },
              },
            },
          },
        },
      },
      [RouteKeys.SPLAT]: {
        // This is our fallback to the legacy style of routing.
        path: '*',
      },
    },
  },
};

// TODO: I dunno, is better types here worth it?
const flattenedPaths: { [key in RouteKeys]?: string } = {};

function addChildRoutes(route: NonIndexRoutePath) {
  if (!route.children) return;

  Object.entries(route.children ?? []).forEach((entry) => {
    const [childKey, child] = entry;

    if (isEnumValue(RouteKeys, childKey)) {
      if ('path' in child) {
        child.path = `${route.path}/${child.path}`;
        flattenedPaths[childKey] = child.path;
        addChildRoutes(child);
      }
    }
  });
}

Object.entries(routePaths).forEach(([key, value]) => {
  if (isEnumValue(RouteKeys, key) && 'path' in value) {
    flattenedPaths[key] = value.path;
    addChildRoutes(value);
  }
});

export default flattenedPaths;
