import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ForecastingExploreItemsFilters } from '../../../../../generated/graphql';
import { SearchToggleValue } from '../../types';

export const useUpdateSearchItemsFromURL = (
  setSearch: (search: string) => void,
  setFilter: (
    filterName: keyof ForecastingExploreItemsFilters,
    filterValue: ForecastingExploreItemsFilters[keyof ForecastingExploreItemsFilters]
  ) => void,
  resetFilters: () => void,
  setToggle?: (toggleValue: SearchToggleValue) => void
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const search = new URLSearchParams(location.search).get('search');
  const toggleValue = new URLSearchParams(location.search).get('toggle');
  const projectValue = new URLSearchParams(location.search).get('project');

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    if (search !== null) {
      setSearch(search);
      resetFilters();
      params.delete('search');
    }
    if (toggleValue !== null) {
      if (setToggle) {
        if (toggleValue === SearchToggleValue.ALL) setToggle(SearchToggleValue.ALL);
        else setToggle(SearchToggleValue.MY);
      }
      params.delete('toggle');
    }
    if (projectValue !== null) {
      setFilter('projectNames', [projectValue]);
      params.delete('project');
    }
    if (search !== null || toggleValue !== null || projectValue !== null)
      navigate(`?${params.toString()}`);
  }, [navigate, toggleValue, setToggle, search, setSearch, resetFilters, projectValue, setFilter]);
};
