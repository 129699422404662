//* * Pattern definition for filling in the cost chart area */
export default function CostFillPattern() {
  return (
    <defs>
      <pattern height="4" id="stripes" patternUnits="userSpaceOnUse" width="4">
        <path
          d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
          stroke="var(--colors-chart-pending-cost-area)"
          strokeWidth="1"
        />
      </pattern>
    </defs>
  );
}
