import { PageViews } from '../../../../analytics/analyticsEventProperties';

import LeadsBanner from './LeadsBanner';
import ProgramBanner from './ProgramBanner';

// returns the first non-null banner, banner components determine if they are null
export default function BannerManager() {
  const banners = [
    <ProgramBanner key="ProgramBanner" />,
    <LeadsBanner
      key="LeadsBanner"
      description="Update the Leads for projects in Company Settings > Project Stats."
      page={PageViews.PROJECT_LIST}
    />,
  ];

  return <>{banners.find((banner) => banner !== null)}</>;
}
