import usePrintWindow from '../Print/PrintHooks/usePrintWindow';

import ProgramDashboard from './ProgramDashboard';
import useProgramDashboardHooks from './ProgramDialog/hooks/useProgramDashboardHooks';

type Props = {
  programID: UUID;
};

export default function PrintProgramDashboardData({ programID }: Props) {
  const {
    isLoading,
    programCosts,
    trendline,
    breakdowns,
    program,
    programCategorizations,
    selectedProjectIDs,
    setSelectedProjectIDs,
  } = useProgramDashboardHooks(programID);

  usePrintWindow(!isLoading);

  return (
    <ProgramDashboard
      chartData={trendline}
      isPrinting
      program={program}
      programBreakdown={breakdowns?.program ?? []}
      programCategorizations={programCategorizations ?? []}
      programCosts={programCosts}
      projectBreakdown={breakdowns?.projects ?? []}
      selectedProjectIDs={selectedProjectIDs}
      setSelectedProjectIDs={setSelectedProjectIDs}
      totalProgramCost={breakdowns?.total ?? 0}
    />
  );
}
