import { useMemo } from 'react';

import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import {
  DD_PROJECT_IDEAS,
  NS_PROGRAM_REPORTING,
  PROJECT_COMPS,
  YC_INSIGHTS_V2,
} from '../../../../features';
import { PermissionResource } from '../../../../generated/graphql';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import useHasModuleEntitlement from '../../../../hooks/useHasModuleEntitlement';
import { FORECASTING, INSIGHTS_V2, JOIN_INSIGHTS } from '../../../../moduleEntitlementFlagsList';
import { RouteKeys } from '../../../../routes/paths';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { Icon } from '../../../scales';
import {
  DashboardIcon,
  ForecastingIcon,
  InsightsIcon,
  InsightsV2Icon,
  ItemLibraryIcon,
  ItemsIcon,
  MilestonesIcon,
  ReportsIcon,
  ScenariosIcon,
  SettingsIcon,
  TeamIcon,
  TimelineIcon,
} from '../../icons';
import { Link } from '../../types';

import { LastViewedEntry } from './useLastViewedEntries';

export default function useLinks(entry?: LastViewedEntry) {
  return {
    companyLinks: useCompanyLinks(),
    projectLinks: useProjectLinks(entry?.type === 'project' ? entry.id : ''),
    programLinks: useProgramLinks(entry?.type === 'program' ? entry.id : ''),
  };
}

function useProjectLinks(projectId?: UUID): Link[] {
  const hasProjectIdeasFeature = useHasFeature(DD_PROJECT_IDEAS);
  const { shouldDisplayCosts } = useShouldDisplayCosts(projectId);

  const links = useMemo(() => {
    if (!projectId) return [];

    return [
      ...(shouldDisplayCosts
        ? [
            {
              name: 'Dashboard',
              icon: <DashboardIcon />,
              path: generateSharedPath(JoinProjectRoutes.PROJECT_DASHBOARD, { projectId }),
            },
          ]
        : []),
      {
        name: 'Items',
        icon: <ItemsIcon />,
        path: generateSharedPath(RouteKeys.PROJECT_ITEMS, { projectId }),
      },
      ...(hasProjectIdeasFeature
        ? [
            {
              name: 'Ideas',
              icon: <Icon name="bulb" />,
              path: generateSharedPath(RouteKeys.PROJECT_IDEAS, { projectId }),
            },
          ]
        : []),
      {
        name: 'Milestones',
        icon: <MilestonesIcon />,
        path: generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }),
      },
      {
        name: 'Reports',
        icon: <ReportsIcon />,
        path: generateSharedPath(JoinProjectRoutes.REPORTS, { projectId }),
      },
      {
        name: 'Timeline',
        icon: <TimelineIcon />,
        path: generateSharedPath(JoinProjectRoutes.TIMELINE, { projectId }),
      },
      ...(shouldDisplayCosts
        ? [
            {
              name: 'Scenarios',
              icon: <ScenariosIcon />,
              path: generateSharedPath(JoinProjectRoutes.SCENARIOS, { projectId }),
            },
          ]
        : []),

      {
        name: 'Settings',
        icon: <SettingsIcon />,
        path: generateSharedPath(JoinProjectRoutes.SETTINGS, { projectId }),
      },
      {
        name: 'Team',
        icon: <TeamIcon />,
        path: generateSharedPath(RouteKeys.PROJECT_TEAM, { projectId }),
      },
    ];
  }, [hasProjectIdeasFeature, projectId, shouldDisplayCosts]);

  return links;
}

function useProgramLinks(programID?: UUID): Link[] {
  const hasProjectIdeasFeature = useHasFeature(NS_PROGRAM_REPORTING);

  if (!programID || !hasProjectIdeasFeature) return [];

  return [
    {
      name: 'Dashboard',
      icon: <DashboardIcon />,
      path: generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, { programID }),
    },
  ];
}

function useCompanyLinks(): Link[] {
  const { canView } = usePermissions();
  const hasForecastingModule = useHasModuleEntitlement(FORECASTING);
  const hasInsightsClassicModule = useHasModuleEntitlement(JOIN_INSIGHTS);
  const hasInsightsV2Module = useHasModuleEntitlement(INSIGHTS_V2);
  const hasForecastingFeature = useHasFeature(PROJECT_COMPS);
  const hasInsightsV2Feature = useHasFeature(YC_INSIGHTS_V2);

  return useMemo(() => {
    const links = [
      {
        name: 'Item Library',
        icon: <ItemLibraryIcon />,
        path: generateSharedPath(RouteKeys.SEARCH_ITEMS),
      },
    ];

    if (
      canView(PermissionResource.PROJECT_COMPS_ACCESS) &&
      (hasForecastingModule || hasForecastingFeature)
    ) {
      links.push({
        name: 'Forecasting',
        icon: <ForecastingIcon />,
        path: generateSharedPath(RouteKeys.FORECASTING),
      });
    }

    const hasInsightsPermissionOrProjects = canView(PermissionResource.INSIGHTS_ACCESS);
    if (hasInsightsPermissionOrProjects && hasInsightsClassicModule) {
      links.push({
        name: hasInsightsV2Feature ? 'Insights Classic' : 'Insights',
        icon: <InsightsIcon />,
        path: generateSharedPath(RouteKeys.INSIGHTS_CLASSIC),
      });
    }

    if (hasInsightsPermissionOrProjects && hasInsightsV2Module && hasInsightsV2Feature) {
      links.push({
        name: 'Insights',
        icon: <InsightsV2Icon />,
        path: generateSharedPath(RouteKeys.INSIGHTS),
      });
    }

    if (canView(PermissionResource.COMPANY_DETAILS)) {
      links.push({
        name: 'Company Settings',
        icon: <SettingsIcon />,
        path: generateSharedPath(RouteKeys.COMPANY),
      });
    }

    return links;
  }, [
    canView,
    hasForecastingFeature,
    hasForecastingModule,
    hasInsightsClassicModule,
    hasInsightsV2Feature,
    hasInsightsV2Module,
  ]);
}
