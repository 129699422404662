import { useState } from 'react';

import { Add } from '@material-ui/icons';

import DialogsAddCompanyEmployee from '../../Dialogs/DialogsAddCompanyEmployee/DialogsAddCompanyEmployee';
import { Button } from '../../scales';

type Props = {
  company: Company;
  companyRoles: PartialRole[];
  companyProjectRoles: CompanyProjectRoles[];
  companyUsers: CompanyUser[];
  onClick: () => void;
};

export default function AddEmployee(props: Props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        label="New Employee"
        onClick={() => {
          setOpen(true);
          props.onClick();
        }}
        startIcon={<Add />}
        type="primary"
      />
      <DialogsAddCompanyEmployee
        company={props.company}
        companyProjectRoles={props.companyProjectRoles}
        companyRoles={props.companyRoles}
        companyUsers={props.companyUsers}
        open={open}
        setDialogOpen={(open: boolean) => {
          setOpen(open);
        }}
      />
    </>
  );
}
