import { axisBottom } from 'd3-axis';
import type { ScaleTime } from 'd3-scale';

import './timeline.scss';

import {
  BaseDateSelection,
  DivSelection,
  SVGGSelection,
  SVGSVGSelection,
  type TimelineOptions,
} from './timeline.types';
import { TODAY_GREEN } from './timelineColor';
import { updateTooltips } from './timelineTooltips';
import {
  AXIS_HEIGHT,
  CHART_GROUP,
  FormatTime,
  X_AXIS_TOP,
  X_AXIS_TOP_NO_TIMELINE,
  getChartMaxHeight,
  getGroupContainerId,
  getGroupSvgId,
  getHeightParams,
  getWidthParams,
  isSettingsItems,
  isSettingsTimeline,
} from './utils';

export const updateGroups = (chart: DivSelection, options: TimelineOptions, height?: number) => {
  const { groupHeight } = getHeightParams(options);

  const groups: DivSelection = chart.select('#timeline-groups');

  if (!options.isTimeline) {
    addTimelineCollapsedContainer(groups);
    return;
  }
  const timelineContainerCollapsed: DivSelection = chart.select(
    `#${getGroupContainerId(CHART_GROUP)}-collapsed`
  );
  if (!timelineContainerCollapsed.empty()) {
    timelineContainerCollapsed.remove();
  }

  let group: DivSelection = groups.select(`#${getGroupContainerId(CHART_GROUP)}`);
  if (group.empty()) {
    // Create group container
    group = groups
      .append('div')
      .attr('id', getGroupContainerId(CHART_GROUP))
      .style('width', '100%')
      .style('height', height ?? `${groupHeight}px`)
      .style('overflow-y', 'auto')
      .style('overflow-x', 'hidden');
  }

  let svg: SVGSVGSelection = groups.select(`#${getGroupSvgId(CHART_GROUP)}`);
  if (svg.empty()) {
    // Create group svg
    svg = group
      .append('svg')
      .attr('id', getGroupSvgId(CHART_GROUP))
      .attr('width', '100%')
      .attr('height', height ?? `${getChartMaxHeight(groupHeight, options)}px`);
  } else {
    svg.attr('height', height ?? `${getChartMaxHeight(groupHeight, options)}px`);
  }

  // Add gridlines group
  svg.append('g').attr('class', 'gridlines');
};

export function addTimelineCollapsedContainer(chart: DivSelection) {
  const itemsContainer: DivSelection = chart.select(`#${getGroupContainerId(CHART_GROUP)}`);
  if (!itemsContainer.empty()) {
    itemsContainer.remove();
  }
  const itemsContainerCollapsed: DivSelection = chart.select(
    `#${getGroupContainerId(CHART_GROUP)}-collapsed`
  );
  if (itemsContainerCollapsed.empty()) {
    chart
      .append('div')
      .attr('id', `${getGroupContainerId(CHART_GROUP)}-collapsed`)
      .style('width', '100%')
      .style('height', `${0}px`);
  }
}

export const updateGridlines = (
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  options: TimelineOptions,
  groupId = `#${getGroupSvgId(CHART_GROUP)}`,
  height?: number
) => {
  const { groupHeight } = getHeightParams(options);
  const { ticksCount } = getWidthParams(options);

  const getTickTime = (date: Date) => date.getTime();

  const lines: BaseDateSelection = chart.select(groupId).select('.gridlines').selectAll('line');

  lines.data(timelineXScale.ticks(ticksCount), getTickTime).join(
    function enter(enter) {
      return enter
        .append('line')
        .attr('y1', X_AXIS_TOP)
        .attr('shape-rendering', 'crispEdges')
        .attr('class', 'stroke-chart-axis-ticks')
        .attr('x1', (d) => timelineXScale(d))
        .attr('x2', (d) => timelineXScale(d))
        .attr('y2', height ?? getChartMaxHeight(groupHeight, options));
    },
    function update(update) {
      return update
        .attr('x1', (d) => timelineXScale(d))
        .attr('x2', (d) => timelineXScale(d))
        .attr('y2', height ?? getChartMaxHeight(groupHeight, options))
        .attr('class', 'stroke-chart-axis-ticks');
    },
    function exit(exit) {
      return exit.remove();
    }
  );
};

export const updateTodayLine = (
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  options: TimelineOptions,
  groupId = `#${getGroupSvgId(CHART_GROUP)}`,
  height?: number
) => {
  const { today } = options;
  if (!today) return;

  const { groupHeight } = getHeightParams(options);

  const group = chart.select(groupId);
  if (group.empty()) return;

  const todayLine = group.select('.line-today');
  if (!todayLine.empty()) {
    todayLine.remove();
  }

  // Add today line
  group
    .data([new Date(today)], () => new Date(today).getTime())
    .append('line')
    .attr('class', 'line-today')
    .attr('y1', 0)
    .attr('shape-rendering', 'geometricPrecision')
    .attr('stroke-width', '1.5px')
    .attr('x1', (d: Date) => timelineXScale(d))
    .attr('x2', (d: Date) => timelineXScale(d))
    .attr('y2', height ?? `${getChartMaxHeight(groupHeight, options) + 20}px`)
    .attr('stroke', TODAY_GREEN);
  const svgGroups: SVGGSelection = group.select('.line-today');
  updateTooltips(svgGroups, options);
};

export const updateAxisTop = (
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  options: TimelineOptions
) => {
  const isTimeline = isSettingsTimeline(options.settings);
  const isItems = isSettingsItems(options.settings);
  const isAxisForItems = !isTimeline && isItems;

  updateAxisTopLine(chart, options, 2, 'timeline', 'timeline-groups');

  let axisContainer: DivSelection = chart.select('#timeline-x-axis-top');
  const { ticksCount } = getWidthParams(options);

  // Create container during first render
  if (axisContainer.empty()) {
    axisContainer = chart
      .insert('div', '#timeline-groups')
      .attr('id', 'timeline-x-axis-top')
      .style('width', '100%');
  }
  const noCharts = !isTimeline && !isItems;
  axisContainer.style('height', `${noCharts ? 6 : AXIS_HEIGHT}px`);
  const paddingTop = isAxisForItems ? X_AXIS_TOP_NO_TIMELINE : X_AXIS_TOP;
  axisContainer.style('padding-top', `${paddingTop}px`);

  const axisSvgRendered = axisContainer.select('#timeline-x-axis-top-svg');

  // Remove old Axis Svg
  if (!axisSvgRendered.empty()) {
    axisSvgRendered.remove();
  }

  if (!isTimeline && !isItems) return;

  // Add Axis if data exists
  if (!(options.data.length > 0)) return;
  // Create X-Axis
  const xAxis = axisBottom(timelineXScale)
    .ticks(ticksCount)
    // Format time ticks
    .tickFormat((d) => FormatTime(d as Date));

  // Add Axis Svg to chart
  const axisSvg = axisContainer
    .append('svg')
    .attr('id', 'timeline-x-axis-top-svg')
    .attr('width', '100%')
    .attr('height', `${AXIS_HEIGHT}px`)
    .call(xAxis);

  // Style Axis
  const ticksGroups = axisSvg.selectAll('.tick');
  ticksGroups
    // Remove ticks lines
    .select('line')
    .remove();
  ticksGroups
    // Style ticks text
    .select('text')
    .attr('class', 'type-body3 fill-chart-axis-label')
    .attr('y', 6);
  // Remove line
  axisSvg.select('.domain').remove();

  // Add axis line
  axisSvg
    .append('line')
    .attr('class', 'x-axis stroke-chart-axis stroke-2')
    .attr('shape-rendering', 'geometricPrecision')
    .attr('stroke-width', '2px')
    .attr('x1', 0 + 16)
    .attr('x2', options.width - 16);
};

export const updateAxisTopLine = (
  chart: DivSelection,
  options: TimelineOptions,
  top: number,
  unique: string,
  before: string,
  hide?: boolean
) => {
  const id = `timeline-x-axis-top-line-${unique}`;
  let axisContainer: DivSelection = chart.select(`#${id}`);

  // Create container during first render
  if (axisContainer.empty()) {
    axisContainer = chart
      .insert('div', `#${before}`)
      .attr('id', `${id}`)
      .style('width', '100%')
      .style('height', '1px')
      .style('padding-top', `${top}px`);
  }

  const axisSvg = axisContainer.select(`#${id}-svg`);

  // Remove old Axis Svg
  if (!axisSvg.empty()) {
    axisSvg.remove();
  }

  // Add Axis if data exists
  if (options.data.length > 0) {
    // Add Axis Svg to chart
    const axisSvg = axisContainer
      .append('svg')
      .attr('id', `${id}-svg`)
      .attr('width', '100%')
      .attr('height', '1px');

    // Add axis line
    axisSvg
      .append('line')
      .attr('class', `x-axis stroke-2 ${hide ? 'stroke-background-primary' : 'stroke-chart-axis'}`)
      .attr('shape-rendering', 'geometricPrecision')
      .attr('stroke-width', '2px')
      .attr('x1', 0 + 16)
      .attr('x2', options.width - 16);
  }
};

export const updateAxisBottom = (chart: DivSelection, options: TimelineOptions) => {
  let axisContainer: DivSelection = chart.select('#timeline-x-axis-bottom');

  // Create container during first render
  if (axisContainer.empty()) {
    axisContainer = chart
      .insert('div')
      .attr('id', 'timeline-x-axis-bottom')
      .style('width', '100%')
      .style('height', '1px');
  }

  const axisSvg = axisContainer.select('#timeline-x-axis-bottom-svg');

  // Remove old Axis Svg
  if (!axisSvg.empty()) {
    axisSvg.remove();
  }

  // Add Axis if data exists
  if (options.data.length > 0) {
    // Add Axis Svg to chart
    const axisSvg = axisContainer
      .append('svg')
      .attr('id', 'timeline-x-axis-bottom-svg')
      .attr('width', '100%')
      .attr('height', '1px');

    // Add axis line
    axisSvg
      .append('line')
      .attr('class', 'x-axis stroke-chart-axis stroke-2')
      .attr('shape-rendering', 'geometricPrecision')
      .attr('x1', 0 + 16)
      .attr('x2', options.width - 16);
  }
};

export const updateAxis = (
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  options: TimelineOptions
) => {
  if (options.settings.isInsights) return;
  updateAxisTop(chart, timelineXScale, options);
  updateAxisBottom(chart, options);
};
