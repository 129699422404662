import { ReactNode } from 'react';

import { TabPanel } from '../../../scales';

export function HUDTabPanel(props: {
  children: ReactNode;
  id: string;
  hasUnitToggleVisible: boolean;
}) {
  const height = props.hasUnitToggleVisible ? 'h-[146px]' : 'h-[114px]';
  return (
    <TabPanel id={props.id}>
      {/* If we moved this div that sets the height above the TabPanel components
          then the `TabPanel` would once again reset the size. We want a fixed size. */}
      <div className={`${height} px-2 pt-2`}>{props.children}</div>
    </TabPanel>
  );
}
