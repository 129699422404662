import { AnalyticsEvent } from '../../../../analytics/analyticsEventProperties';
import { ResourceType } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { toSharedResourceUsers } from '../../../../utilities/utilities';
import { useGetSharedResource } from '../../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';

import SharedUsersState from './SharedUserState';

type Props = {
  resourceID: UUID;
  resourceType: ResourceType;
  tooltipAnalyticsEvent?: AnalyticsEvent;
};

export default function SharedScenarioBadge(props: Props) {
  const sendAnalytics = useSendAnalytics();

  const { data } = useGetSharedResource(props.resourceID, props.resourceType);
  const sharedUsers = toSharedResourceUsers(data);

  return (
    <SharedUsersState
      onTooltipOpen={() => {
        if (props.tooltipAnalyticsEvent) sendAnalytics(props.tooltipAnalyticsEvent);
      }}
      sharedUsers={sharedUsers}
    />
  );
}
