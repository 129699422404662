import { ProgramCostFieldsFragment, ProgramFieldsFragment } from '../../generated/graphql';
import { formatCost } from '../../utilities/currency';
import BuildingCommercial from '../Icons/BuildingCommercial';
import Location from '../Icons/Location';
import Program from '../Icons/Program';
import { Chip } from '../scales';

import ProgramSelectionCollapse from './ProgramSectionCollapse';

type Props = {
  isOpen: boolean;
  isPrinting: boolean;
  selectedProjectIDs: UUID[];
  setIsOpen: (isOpen: boolean) => void;
  program?: ProgramFieldsFragment;
  programCosts?: ProgramCostFieldsFragment;
};

export default function ProgramDashboardHeader(props: Props) {
  if (!props.program) return null;
  if (!props.programCosts) return null;

  const { projects, locationDetails, type } = props.program;
  const { estimate, budget, runningTotal } = props.programCosts;

  return (
    <div className="flex w-full justify-between gap-2">
      <div className="flex flex-col gap-5">
        <div className="flex gap-2">
          <Chip icon={<Program />} text={`${projects.length} Projects`} />
          <Chip icon={<Location />} text={locationDetails.name} />
          <Chip icon={<BuildingCommercial />} text={type} />
        </div>
        {!props.isPrinting && (
          <ProgramSelectionCollapse
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            text={`Showing ${props.selectedProjectIDs.length} of ${projects.length} Projects`}
          />
        )}
      </div>
      <div className="flex flex-col justify-end gap-1 self-start">
        <div className="flex justify-between gap-6 type-body2">
          <div>Combined Estimate</div>
          <div className="type-table-number">{formatCost(estimate)}</div>
        </div>
        <div className="flex justify-between gap-6 type-body2">
          <div>Combined Running Total</div>
          <div className="type-table-number">{formatCost(runningTotal)}</div>
        </div>
        <div className="flex justify-between gap-6 text-entities-milestone type-body2">
          <div>Combined Budget</div>
          <div className="type-table-number">{formatCost(budget)}</div>
        </div>
      </div>
    </div>
  );
}
