import { gql, insightsProjectFields } from '../../../api/graphqlFragments';

export const insightsV2ProjectCountsQuery = gql`
  query InsightsV2ProjectCounts($input: InsightsInput!, $insightsOrgsInput: [InsightsOrgsInput]) {
    insightsV2ProjectCounts(input: $input, insightsOrgsInput: $insightsOrgsInput) {
      totalProjects
      filteredProjects
      projectsWithAlerts
      totalAlerts
      triggeredAlertsByProject {
        projectID
        projectName
        activeAlerts {
          alertID
          description
          alertType
        }
      }
      projectTypesBreakdown {
        label
        count
        countWithAlerts
        volume
        volumeWithAlerts
      }
      designPhasesBreakdown {
        label
        count
        countWithAlerts
        volume
        volumeWithAlerts
      }
      projectLeadsBreakdown {
        label
        count
        countWithAlerts
        volume
        volumeWithAlerts
      }
      organizationBreakdowns {
        organizationID
        breakdown {
          label
          count
          countWithAlerts
          volume
          volumeWithAlerts
        }
      }
    }
  }
`;

export const insightsProjectsQuery = gql`
  query InsightsProjects($input: InsightsInput!, $pagination: Pagination!) {
    insights(input: $input, pagination: $pagination) {
      ...insightsProjectFields
    }
  }
  ${insightsProjectFields}
`;

export const insightsProjectQuery = gql`
  query InsightsProject($projectID: UUID!, $companyID: UUID!) {
    insightsProject(projectID: $projectID, companyID: $companyID) {
      ...insightsProjectFields
    }
  }
  ${insightsProjectFields}
`;
