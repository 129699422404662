import { ComponentProps, useEffect, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import {
  IN_APP_NOTIFICATIONS_POLLING_INTERVAL_LONG,
  IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT,
} from '../../../constants';
import useLoadInAppNotificationsCountQuery from '../../InAppNotifications/hooks/useLoadInAppNotificationsCountQuery';
import InAppNotificationsPanelData from '../../InAppNotifications/InAppNotificationsPanel/InAppNotificationsPanelData';
import { IconButton, Popover, Tooltip } from '../../scales';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';
import { NotificationsIcon } from '../icons';

type Props = {
  projectID?: UUID;
  tooltipOffset?: ComponentProps<typeof Tooltip>['offset'];
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
};

export default function NotificationsButton(props: Props) {
  const sendAnalytics = useSendNavAnalytics();

  const notificationsOverlayState = useOverlayTriggerState({
    onOpenChange: (open) => open && sendAnalytics(navEventTypes.NOTIFICATIONS_OPENED),
  });
  const ref = useRef<HTMLButtonElement>(null);

  const notificationsCount = useNotificationsCount(
    notificationsOverlayState.isOpen
      ? IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT
      : IN_APP_NOTIFICATIONS_POLLING_INTERVAL_LONG
  );

  return (
    <>
      <Tooltip
        content="Notifications"
        offset={props.tooltipOffset}
        placement={props.tooltipPlacement}
      >
        <IconButton
          ref={ref}
          aria-label="view notifications"
          badge={notificationsCount && notificationsCount > 0 ? notificationsCount : undefined}
          data-cy="view-notifications-button"
          icon={<NotificationsIcon />}
          onClick={notificationsOverlayState.open}
          type="secondary"
        />
      </Tooltip>
      {notificationsOverlayState.isOpen && (
        <Popover
          className="w-100"
          placement="bottom start"
          state={notificationsOverlayState}
          triggerRef={ref}
        >
          <InAppNotificationsPanelData projectID={props.projectID} />
        </Popover>
      )}
    </>
  );
}

function useNotificationsCount(pollingInterval: number) {
  const { data, startPolling, stopPolling } = useLoadInAppNotificationsCountQuery();

  useEffect(() => {
    startPolling(pollingInterval);
    return stopPolling;
  }, [pollingInterval, startPolling, stopPolling]);

  return data?.loadInAppNotificationsCount.unreadNotificationsCount;
}
