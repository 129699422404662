import { useMemo } from 'react';

import { InsightsProjectAlert } from '../../../generated/graphql';
import { AlertDisplayNames } from '../utils';

type Props = {
  triggeredAlertsByProject: InsightsProjectAlert[];
};

const AlertsByTypeTooltip = (props: Props) => {
  // Calculate total alert types
  const alertTypeCounts = useMemo(() => {
    const counts: Record<string, number> = {};
    props.triggeredAlertsByProject.forEach((project) => {
      project.activeAlerts.forEach((alert) => {
        if (alert.alertType) {
          const alertDisplayName = AlertDisplayNames[alert.alertType];
          counts[alertDisplayName] = (counts[alertDisplayName] || 0) + 1;
        }
      });
    });
    return counts;
  }, [props.triggeredAlertsByProject]);

  return (
    <div className="flex w-75 flex-col gap-2 p-1">
      <strong className="type-body2">Alerts</strong>
      <div className="flex flex-col gap-1">
        {Object.entries(alertTypeCounts).map(([alertType, count]) => (
          <div key={alertType} className="flex items-center justify-between">
            <div className="text-type-primary type-body2">{alertType}</div>
            <strong className="text-type-primary type-body2">{count}</strong>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlertsByTypeTooltip;
