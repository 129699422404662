import { cloneDeep } from 'lodash';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

import { InsightsEvent, insightsEvent } from '../../analytics/analyticsEventProperties';
import { paginationHasMoreVar } from '../../api/apollo/reactiveVars';
import { JoinCompanyRoutes } from '../../api/gqlEnums';
import { COMPANY_DASHBOARD, FILTER_DESIGN_PHASE } from '../../constants';
import { InsightsSort, InsightsSortKey, SearchResultType } from '../../generated/graphql';
import { useProjectDeliveryTypes } from '../../hooks/useProjectDeliveryTypesQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../utilities/routes/links';
import { pluralizeString } from '../../utilities/string';
import { usePersistentStates } from '../../utilities/urlState';
import useOrganizationsQuery from '../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import RightArrow from '../Icons/RightArrow';
import { useDesignPhaseTypes } from '../Milestone/hooks/useDesignPhaseTypesQuery';
import { ProjectIcon } from '../Nav/icons';
import useSearchProjectsFilterOptionsQuery from '../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import { useFilterProjects } from '../ProjectsList/ProjectsListUtils';
import { Button, Tooltip } from '../scales';
import useMemoWrapper from '../useMemoWrapper';

import DesignPhaseStackedBarChart from './Charts/DesignPhaseStackedBarChart';
import CompanySelector from './CompanySelector';
import InsightsFilterMenu from './FilterMenu/InsightsFilterMenu';
import { useInsightsProjectsQuery } from './hooks/useInsightsProjectsQuery';
import { useInsightsV2ProjectCountsQuery } from './hooks/useInsightsV2ProjectCountsQuery';
import InsightsTabs from './InsightsTabs';
import HeaderDonutCharts from './PieCharts/HeaderDonutCharts';
import HeaderDonutOrgCharts, {
  InsightsOrgsDonutSelections,
} from './PieCharts/HeaderDonutOrgCharts';
import InsightsListCountsVolBar, { HeaderDisplayBy } from './PieCharts/InsightsListHeaderPieBar';
import ProjectsListTables from './ProjectsListTables';
import ScrollContainer from './ScrollContainer';
import AlertsByTypeTooltip from './ToolTips/AlertsByTypeTooltip';
import ProjectsWithAlertsTooltip from './ToolTips/ProjectsWithAlertsTooltip';
import { InsightsProject, InsightsTabId } from './types';
import {
  INSIGHTS_COUNTS_DEFAULTS,
  INSIGHTS_DEFAULTS,
  generateDesignPhaseChartInput,
  getInsightsInput,
  getSortDirection,
} from './utils';

const LIMIT = 10;

export default function InsightsProjectsList(props: { companyID: UUID }) {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();
  const [selectedTabID, setSelectedTabID] = useState(InsightsTabId.Costs);

  // Sorting
  const [sortState, setSortState] = useState<InsightsSort>({
    sortKey: InsightsSortKey.LAST_UPDATED,
    sortDirection: getSortDirection(InsightsSortKey.LAST_UPDATED),
  });
  const setSort = (sortBy: InsightsSortKey) => {
    const sortDirection = getSortDirection(sortBy);
    setSortState({ sortKey: sortBy, sortDirection });
  };

  // Filtering
  const [settings, setSettings] = usePersistentStates(
    window.location,
    '',
    INSIGHTS_DEFAULTS,
    'Insights Filters - ',
    (settings) => {
      const newSettings = settings;
      if (!settings.estimateCostRange.min && !settings.estimateCostRange.max) {
        newSettings.estimateCostRange = {
          max: null,
          min: null,
        };
      }
      if (!settings.gsfRange.min && !settings.gsfRange.max) {
        newSettings.gsfRange = {
          max: null,
          min: null,
        };
      }
      return newSettings;
    }
  );
  // Filters
  const filterManager = useFilterProjects(settings);
  const { setFilter } = filterManager;
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);
  // Organizations
  const organizationsQueryResult = useOrganizationsQuery(props.companyID);
  const orgs = organizationsQueryResult.data?.organizations;
  // State to manage orgLevels in the parent
  const [orgDonutLevels, setOrgDonutLevels] = useState<InsightsOrgsDonutSelections[]>(
    orgs?.map((org) => ({
      orgID: org.id,
      levelIndex: 0,
    })) || []
  );

  // Design Phases
  const designPhaseTypes = useDesignPhaseTypes();
  // Project Delivery Types
  const projectDeliveryTypes = useProjectDeliveryTypes();

  const [alertsOnly, setAlertsOnly] = useState(false);
  const alertsUrl = generateSharedPath(JoinCompanyRoutes.COMPANY_INSIGHTS_ALERTS, {});

  if (filterOptionsData && designPhaseTypes && projectDeliveryTypes) {
    const intialFilterOptions: SearchProjectsFilterOptions = cloneDeep(
      filterOptionsData.searchProjectsFilterOptions
    );
    intialFilterOptions.gsfRange = {
      max: null,
      min: null,
    };
    intialFilterOptions.estimateCostRange = {
      max: null,
      min: null,
    };
    intialFilterOptions.designPhases = designPhaseTypes.map((d) => d.name) || [];
    intialFilterOptions.deliveryMethods = projectDeliveryTypes.map((d) => d.name) || [];
    filterManager.filterOptions = intialFilterOptions;
    if (filterManager.filterOptions)
      filterManager.filterOptions.types =
        filterOptionsData.searchProjectsFilterOptions.projectTypes || [];
  }

  const insightsInput = useMemoWrapper(
    getInsightsInput,
    props.companyID,
    filterManager,
    filterOptionsData,
    sortState,
    alertsOnly
  );
  // Insights Projects counts
  const projectCounts =
    useInsightsV2ProjectCountsQuery(insightsInput, orgDonutLevels) || INSIGHTS_COUNTS_DEFAULTS;

  const projectCountText = projectCounts?.filteredProjects
    ? `${projectCounts?.filteredProjects} ${pluralizeString('Project', projectCounts?.filteredProjects)}`
    : '';

  const { data: insightsProjects, fetchMore } = useInsightsProjectsQuery({
    input: insightsInput,
    pagination: {
      offset: 0,
      limit: LIMIT,
    },
  });
  // Header pie charts display selector
  const [selectedDisplayByOption, setSelectedDisplayByOption] = useState<HeaderDisplayBy>(
    HeaderDisplayBy.COUNT
  );
  const handleOptionChange = (option: HeaderDisplayBy) => {
    setSelectedDisplayByOption(option);
    sendAnalytics(insightsEvent(InsightsEvent.SUMMARY_DISPLAY_SELECTOR, { type: option }));
  };

  // Generate design phase chart input data
  const designPhaseChartData = useMemoWrapper(
    generateDesignPhaseChartInput,
    projectCounts.designPhasesBreakdown,
    selectedDisplayByOption
  );

  const projectAlertsString = `${pluralizeString('project', projectCounts.projectsWithAlerts)} with alerts`;

  const topHeader = (
    <div className="flex items-center justify-between px-5 pb-6">
      <div className="flex items-center gap-2">
        <div className="flex type-heading1">{COMPANY_DASHBOARD}</div>
        <CompanySelector />
      </div>
      <div className="flex items-center gap-2">
        <InsightsFilterMenu
          filterManager={filterManager}
          orgs={orgs || []}
          setSettings={setSettings}
        />
      </div>
    </div>
  );

  const summaryHeader = (
    <div className="flex flex-col gap-2 px-5 pb-2">
      {/* Projects Count and Display By */}
      <div className="flex items-center justify-between gap-1">
        <div className="flex type-heading2">{projectCountText}</div>
        <div className="flex items-center gap-1">
          <div className="type-body1">Display by</div>
          <InsightsListCountsVolBar
            onOptionChange={handleOptionChange}
            selectedOption={selectedDisplayByOption}
          />
        </div>
      </div>

      {/* Alerts and Donuts Section */}
      <div className="flex h-36 flex-row justify-between">
        <div className="flex flex-col gap-2">
          <strong className="type-body2">Alerts</strong>
          {/* Total Alerts by Alert Type  */}
          <Tooltip
            content={
              projectCounts.triggeredAlertsByProject && (
                <div>
                  <AlertsByTypeTooltip
                    triggeredAlertsByProject={projectCounts.triggeredAlertsByProject}
                  />
                </div>
              )
            }
          >
            <div className="flex items-center gap-1">
              <ErrorOutline color="error" style={{ width: 20, height: 20 }} />
              <strong className="type-body1">
                {projectCounts.totalAlerts} {pluralizeString('alert', projectCounts.totalAlerts)}
              </strong>
            </div>
          </Tooltip>
          {/* Projects with alerts */}
          <Tooltip
            content={
              projectCounts.triggeredAlertsByProject && (
                <div>
                  <ProjectsWithAlertsTooltip
                    triggeredAlertsByProject={projectCounts.triggeredAlertsByProject}
                  />
                </div>
              )
            }
          >
            <div className="flex items-center gap-1">
              <ProjectIcon />
              <strong className="type-body1">
                {projectCounts.projectsWithAlerts} {projectAlertsString}
              </strong>
            </div>
          </Tooltip>
          <div className="pt-2">
            <Button
              aria-label="configure alerts"
              endIcon={<RightArrow />}
              label="Configure alerts"
              onClick={() => {
                navigate(alertsUrl);
                insightsEvent(InsightsEvent.SUMMARY_SETTINGS);
              }}
              type="secondary"
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <strong className="pl-1 type-body2">Projects by</strong>
          <div className="flex flex-row items-center gap-8">
            {/* Header Design Phases Stacked Chart */}
            <DesignPhaseStackedBarChart
              data={designPhaseChartData}
              onBarTooltip={() =>
                sendAnalytics(
                  insightsEvent(InsightsEvent.SUMMARY_TOOLTIP_VIEW, {
                    area: 'DesignPhase',
                    level: 'detail',
                  })
                )
              }
              onCategorySelect={(designPhaseName) => {
                const selectedDesignPhase = designPhaseTypes.find(
                  (d) => d.name === designPhaseName
                );
                if (selectedDesignPhase) {
                  setFilter(
                    {
                      type: FILTER_DESIGN_PHASE,
                      value: selectedDesignPhase.name,
                    },
                    setSettings
                  );
                }
              }}
              onSummaryTooltip={() =>
                sendAnalytics(
                  insightsEvent(InsightsEvent.SUMMARY_TOOLTIP_VIEW, {
                    area: 'DesignPhase',
                    level: 'summary',
                  })
                )
              }
              selectedDisplayByOption={selectedDisplayByOption}
              showBarHoverTooltip
              showLegendTooltip
            />
            <div className="flex gap-8">
              <HeaderDonutOrgCharts
                filterManager={filterManager}
                orgLevels={orgDonutLevels}
                orgs={orgs || []}
                projectCounts={projectCounts || []}
                selectedDisplayBy={selectedDisplayByOption}
                setOrgLevels={setOrgDonutLevels} // Function to update orgLevels
                setSettings={setSettings}
              />
              <HeaderDonutCharts
                filterManager={filterManager}
                projectCounts={projectCounts || []}
                selectedDisplayBy={selectedDisplayByOption}
                setSettings={setSettings}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const viewHeader = (
    <div className="flex flex-col justify-stretch px-2 pb-5">
      <InsightsTabs
        alertsOnly={alertsOnly}
        onSortByChange={(sort) => {
          setSort(sort);
          sendAnalytics(insightsEvent(InsightsEvent.SUMMARY_SORT_BY, { sort }));
        }}
        onTabChange={(tabId) => {
          setSelectedTabID(tabId);
          sendAnalytics(insightsEvent(InsightsEvent.SUMMARY_VIEW_SELECTOR, { view: tabId }));
        }}
        selectedSortBy={sortState.sortKey}
        selectedTabID={selectedTabID}
        setAlertsOnly={(checked) => {
          setAlertsOnly(checked);
          sendAnalytics(insightsEvent(InsightsEvent.SUMMARY_ALERT_ONLY, { status: checked }));
        }}
      />
    </div>
  );

  return (
    <div className="flex h-full w-full">
      <div className="flex grow flex-col gap-2 overflow-x-auto py-4">
        {topHeader}
        <ProjectsListWithPageLoading
          key={JSON.stringify(insightsInput)}
          insightsProjects={insightsProjects || []}
          onFetchMore={fetchMore}
          projectCounts={{
            current: projectCounts.filteredProjects,
            total: projectCounts.totalProjects,
          }}
          sortState={sortState}
          summaryHeader={summaryHeader}
          tabId={selectedTabID}
          viewHeader={viewHeader}
        />
      </div>
    </div>
  );
}

export function ProjectsListWithPageLoading(props: {
  projectCounts: { current: number; total: number };
  insightsProjects: InsightsProject[];
  sortState: InsightsSort;
  summaryHeader: ReactNode;
  tabId: InsightsTabId;
  onFetchMore: () => void;
  viewHeader: ReactNode;
}) {
  const currentCount = props.insightsProjects.length;
  const hasMore = useReactiveVar(paginationHasMoreVar);
  const [nextCount, setNextCount] = useState(currentCount);
  const pageLoading =
    hasMore &&
    currentCount < nextCount &&
    // There are entries beyond the first page
    currentCount >= LIMIT;

  const loadingFooter = (
    <div key="loading" className="mx-auto mt-2 h-20 w-full">
      <div className="flex h-full items-center justify-center">
        <CircularProgress />
      </div>
    </div>
  );

  return (
    <ScrollContainer
      className="gap-2"
      direction="vertical"
      hasNoShadow
      onNeedMoreData={() => {
        props.onFetchMore();
        setNextCount(currentCount + LIMIT);
      }}
    >
      {props.summaryHeader}
      <div className="px-3">
        <ProjectsListTables {...props} />
      </div>
      {pageLoading && loadingFooter}
    </ScrollContainer>
  );
}
