import * as d3 from 'd3';

import { projectSettingsVar } from '../../api/apollo/reactiveVars';
import { renderCostString } from '../CostReport/CostReportUtils';

type Props = {
  yScale: d3.ScaleLinear<number, number>;
  xWidth: number;
};

export default function EstimateChartYAxis(props: Props) {
  const settings = projectSettingsVar();

  return (
    <g
      ref={(node) => {
        if (node) {
          const axis = d3
            .axisLeft(props.yScale)
            .ticks(8)
            .tickFormat((v) => renderCostString({ cost: Number(v), short: true }));
          const axisGroup = d3.select(node).call(axis);

          // Style the axis line (domain)
          axisGroup.select('.domain').style('stroke', '#e6e6e9').style('stroke-width', 2);

          // Style the ticks
          axisGroup.selectAll('.tick line').style('stroke', '#e6e6e9');

          // Style the tick text
          axisGroup.selectAll('.tick text').attr('class', 'type-body2');

          // Add horizontal lines at each tick
          axisGroup
            .selectAll('.tick')
            .append('line')
            .attr('x1', 0)
            .attr('x2', props.xWidth > 0 ? props.xWidth : 0)
            .attr('y1', 0)
            .attr('y2', 0)
            .style('stroke', '#e6e6e9');

          // Add y-axis label
          // Select the existing x-axis label or append a new one if it doesn't exist
          const yAxisLabel: d3.Selection<SVGTextElement, object, SVGGElement, unknown> = axisGroup
            .selectAll<SVGTextElement, object>('.y-axis-label')
            .data([{}]);

          // Enter and merge the label to ensure updates on resize
          yAxisLabel
            .enter()
            .append('text')
            .attr('class', 'type-body1 y-axis-label')
            .merge(yAxisLabel)
            .attr('x', -150) // Center the label vertically based on the y-axis range
            .attr('y', -60) // Position to the left of the axis
            .attr('transform', 'rotate(-90)') // Rotate the text to be parallel with the y-axis
            .attr('text-anchor', 'middle') // Center text anchor
            .style('fill', 'black') // Text color
            .text(`Estimate in ${settings.CURRENCY}`); // Set the label text
        }
      }}
      transform="translate(0, 0)"
    />
  );
}
