import { useCallback, useMemo, useState } from 'react';

import { searchEventTypes } from '../../../../../analytics/analyticsEventProperties';
import { itemSidebarHomeOpenVar } from '../../../../../api/apollo/reactiveVars';
import {
  ForecastingExploreItemsFilters,
  ForecastingExploreItemsSortBy,
  ForecastingExploreItemsSortKey,
  ForecastingProjectsFilters,
  SearchResultType,
  SortDirection,
} from '../../../../../generated/graphql';
import { isEnumValue } from '../../../../../utilities/types';
import { useSavedQuery } from '../../../../ForecastingRoute/hooks/useSavedQuery';
import { SortManager } from '../../../../scales';
import useCoreSearchPermissions from '../../hooks/useCoreSearchPermissions';
import useSendSearchAnalytics from '../../hooks/useSendSearchAnalytics';
import useSearchProjectsQueryParams from '../../projects/hooks/useSearchProjectsQueryParams';
import { SearchToggleValue } from '../../types';
import { DEFAULT_ITEMS_SEARCH_QUERY } from '../SearchItemsUtils';

import useSearchItemsToggleCounts from './useSearchItemsToggleCounts';

type SearchItemsVariables = {
  filters: ForecastingExploreItemsFilters;
  projectsFilters: ForecastingProjectsFilters;
  search: string;
  sortBy: ForecastingExploreItemsSortBy;
  toggleValue: SearchToggleValue;
};

const LOCAL_STORAGE_KEY = 'SEARCH_ITEMS_QUERY';
const ALL_ITEMS_LABEL = 'All Items';
const MY_ITEMS_LABEL = "My Projects' Items";

export default function useSearchItemsQueryParams() {
  const sendSearchAnalytics = useSendSearchAnalytics();

  const { hasAllItemsAccess } = useCoreSearchPermissions();
  const [savedQuery, setSavedQuery] = useSavedQuery<SearchItemsVariables>(
    LOCAL_STORAGE_KEY,
    DEFAULT_ITEMS_SEARCH_QUERY
  );

  const [toggleValue, setToggleValue] = useState(
    hasAllItemsAccess ? savedQuery.toggleValue : SearchToggleValue.MY
  );

  const [filters, setFilters] = useState<ForecastingExploreItemsFilters>(savedQuery.filters);
  const projectsParams = useSearchProjectsQueryParams();
  const [projectsFilters, setProjectsFilters] = useState<ForecastingProjectsFilters>(
    projectsParams.variables.filters
  );
  const [search, setSearch] = useState(savedQuery.search);
  const [sortBy, setSortBy] = useState<ForecastingExploreItemsSortBy>(savedQuery.sortBy);

  const toggleCounts = useSearchItemsToggleCounts();
  const analyticEventTypeName = 'Items';

  const onChangeFilters = useCallback(
    (
      filterName: keyof ForecastingExploreItemsFilters,
      filterValue: ForecastingExploreItemsFilters[keyof ForecastingExploreItemsFilters]
    ) => {
      setFilters((prevState) => ({
        ...prevState,
        [filterName]: filterValue,
      }));
      setSavedQuery({
        filters: { [filterName]: filterValue },
      });
      sendSearchAnalytics(searchEventTypes.SEARCH_FILTERS, {
        filterSelected: filterValue,
        filterType: filterName,
        type: analyticEventTypeName,
        filterSection: 'itemsFilter',
      });
      if (itemSidebarHomeOpenVar !== null) {
        itemSidebarHomeOpenVar(null);
      }
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onChangeProjectsFilters = useCallback(
    (
      filterName: keyof ForecastingProjectsFilters,
      filterValue: ForecastingProjectsFilters[keyof ForecastingProjectsFilters]
    ) => {
      setProjectsFilters((prevState) => ({
        ...prevState,
        [filterName]: filterValue,
      }));
      setSavedQuery({
        projectsFilters: { [filterName]: filterValue },
      });
      projectsParams.onChangeFilters(filterName, filterValue, false);
      // modify not only projects but items filters, too
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      let itemFilterName: any;
      if (filterName === 'locations') {
        itemFilterName = 'projectLocations';
      }
      if (filterName === 'milestoneDateRange') {
        itemFilterName = 'milestoneDate';
      }
      if (filterName === 'types') {
        itemFilterName = 'projectTypes';
      }
      if (filterName === 'projectTypes') {
        itemFilterName = 'projectTypes';
      }
      if (itemFilterName) {
        setFilters((prevState) => ({
          ...prevState,
          [itemFilterName]: filterValue,
        }));
        setSavedQuery({
          filters: { [itemFilterName]: filterValue },
        });
      }
      sendSearchAnalytics(searchEventTypes.SEARCH_FILTERS, {
        filterSelected: filterValue,
        filterType: filterName,
        type: analyticEventTypeName,
        filterSection: 'projectsFilter',
      });

      if (itemSidebarHomeOpenVar !== null) {
        itemSidebarHomeOpenVar(null);
      }
    },
    [projectsParams, sendSearchAnalytics, setSavedQuery]
  );
  const onResetFilters = useCallback(() => {
    setFilters(DEFAULT_ITEMS_SEARCH_QUERY.filters);
    setProjectsFilters(DEFAULT_ITEMS_SEARCH_QUERY.projectsFilters);
    projectsParams.onResetFilters();
    setSavedQuery({
      filters: DEFAULT_ITEMS_SEARCH_QUERY.filters,
      projectsFilters: DEFAULT_ITEMS_SEARCH_QUERY.projectsFilters,
    });
  }, [projectsParams, setSavedQuery]);
  const onChangeSearch = useCallback(
    (search: string) => {
      setSearch(search);
      setSavedQuery({ search });
      sendSearchAnalytics(searchEventTypes.SEARCH_TEXT_ENTERED, {
        search,
        type: analyticEventTypeName,
        location: 'Home',
      });
      if (itemSidebarHomeOpenVar !== null) {
        itemSidebarHomeOpenVar(null);
      }
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onChangeSortBy = useCallback(
    (sortBy: ForecastingExploreItemsSortBy) => {
      setSortBy(sortBy);
      setSavedQuery({ sortBy });
      sendSearchAnalytics(searchEventTypes.SEARCH_SORT, {
        sortDirection: sortBy.sortDirection,
        sortKey: sortBy.sortKey,
        type: analyticEventTypeName,
      });
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onChangeToggleValue = useCallback(
    (toggleValue: SearchToggleValue) => {
      setToggleValue(toggleValue);
      setSavedQuery({ toggleValue });
      sendSearchAnalytics(searchEventTypes.SEARCH_TOGGLE, {
        toggleValue,
        type: analyticEventTypeName,
      });
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const sortManager = useMemo(
    () => getSortManager(sortBy, onChangeSortBy),
    [sortBy, onChangeSortBy]
  );
  const toggleParams = useMemo(() => {
    if (!hasAllItemsAccess) return undefined;
    return {
      options: [
        {
          value: SearchToggleValue.ALL,
          label: ALL_ITEMS_LABEL,
          count: toggleCounts.all,
        },
        {
          value: SearchToggleValue.MY,
          label: MY_ITEMS_LABEL,
          count: toggleCounts.my,
        },
      ],
      value: toggleValue.toString(),
      onChange: (value: string) => {
        if (isEnumValue(SearchToggleValue, value)) onChangeToggleValue(value);
      },
    };
  }, [hasAllItemsAccess, onChangeToggleValue, toggleCounts, toggleValue]);

  return useMemo(
    () => ({
      onChangeFilters,
      onChangeProjectsFilters,
      onChangeSearch,
      onResetFilters,
      sortManager,
      toggleCounts,
      toggleParams,
      variables: {
        filters,
        projectsFilters,
        searchResultType:
          toggleValue === SearchToggleValue.ALL ? SearchResultType.ALL : SearchResultType.MY,
        search,
        sortBy,
        pagination: {
          offset: 0,
          limit: 25,
        },
      },
    }),
    [
      filters,
      projectsFilters,
      onChangeFilters,
      onChangeProjectsFilters,
      onChangeSearch,
      onResetFilters,
      search,
      sortBy,
      sortManager,
      toggleCounts,
      toggleParams,
      toggleValue,
    ]
  );
}

const getSortManager = (
  sortBy: ForecastingExploreItemsSortBy,
  setSortBy: (sortBy: ForecastingExploreItemsSortBy) => void
) => {
  const sortManager: SortManager = {
    setSort: (input) => {
      let { sortDirection } = input;
      let sortKey: ForecastingExploreItemsSortKey;
      switch (input.sortKey) {
        case ForecastingExploreItemsSortKey.COST_IMPACT:
        case ForecastingExploreItemsSortKey.MILESTONE_DATE:
        case ForecastingExploreItemsSortKey.PROJECT_NAME:
        case ForecastingExploreItemsSortKey.STATUS:
        case ForecastingExploreItemsSortKey.UPDATED_AT:
          sortKey = input.sortKey;
          break;
        case ForecastingExploreItemsSortKey.SCORE:
          // Never let users sort by the worst score
          sortDirection = SortDirection.SORT_DESCENDING;
          sortKey = input.sortKey;
          break;
        default:
          sortKey = ForecastingExploreItemsSortKey.SCORE;
          break;
      }
      setSortBy({ sortDirection, sortKey });
    },
    sortState: sortBy,
  };
  return sortManager;
};
