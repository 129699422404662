import { ComponentProps } from 'react';

import { ForecastingProjectsSortKey } from '../../../../generated/graphql';
import { formatNumber } from '../../../../utilities/string';
import ProjectCell from '../../../dragon-scales/ProjectCell/ProjectCell';
import MilestoneCell from '../../../ForecastingRoute/ForecastingExplore/projects/cells/MilestoneCell';
import { SortManager, Table } from '../../../scales';

import ActiveMilestoneCosts from './cells/ActiveMilestoneCosts';
import LastUpdatedCell from './cells/LastUpdatedCell';
import { columnWidths } from './utils';

type Project = ComponentProps<typeof ProjectCell>['project'] &
  ComponentProps<typeof ActiveMilestoneCosts>['project'] & {
    milestoneName: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
  };

type Props = {
  counts: { current: number; total: number };
  isLoading: boolean;
  onFetchMore: () => void;
  projects: Project[];
  searchText: string;
  sortManager: SortManager;
};

export default function SearchProjects(props: Props) {
  return (
    <Table
      columnWidths={columnWidths}
      entries={props.projects.map((project) => {
        const key = project.id;

        return [
          {
            key,
            component: <ProjectCell project={project} searchText={props.searchText} />,
          },
          {
            key,
            component: <MilestoneCell milestoneName={project?.milestoneName || ''} />,
          },
          {
            key,
            component: (
              <LastUpdatedCell
                updatedAt={project.updatedAt ?? undefined}
                updatedBy={project.updatedBy ?? undefined}
              />
            ),
          },
          {
            key,
            centered: true,
            component: <ActiveMilestoneCosts project={project} />,
          },
        ];
      })}
      headerContent={[
        {
          copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
            props.counts.total
          )})`,
          key: 'projects',
          headerSortKey: ForecastingProjectsSortKey.NAME,
        },
        {
          copy: 'Active milestone',
          key: 'active-milestone',
          headerSortKey: ForecastingProjectsSortKey.MILESTONE_NAME,
        },
        {
          copy: 'Last updated',
          key: 'last-updated',
          headerSortKey: ForecastingProjectsSortKey.UPDATED_AT,
        },
        { copy: 'Project costs', key: 'active-milestone-costs' },
      ]}
      isLoading={props.isLoading}
      onNeedMoreData={props.onFetchMore}
      sortManager={props.sortManager}
    />
  );
}
