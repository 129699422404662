import { Link } from 'react-router-dom';

import useLocalStorage from '../../../hooks/useLocalStorage';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Button, Chip, Icon, IconMenuButton } from '../../scales';
import Star from '../common/Star';
import { ProjectIdea } from '../types';

import IdeaRelevance from './IdeaRelevance';
import IdeaTabs from './IdeaTabs';
import { AlternateTile, RootTile } from './Tile';

type Props = { projectID: UUID; idea: ProjectIdea; onCreateItem: (alternateID?: UUID) => void };

export default function Idea(props: Props) {
  const [hiddenAlternatesByIdea, setHiddenAlternatesByIdea] = useLocalStorage<Record<UUID, UUID[]>>(
    `HIDDEN_ALTERNATES_BY_IDEA`,
    {}
  );
  const [starredIdeas, setStarredIdeas] = useLocalStorage<UUID[]>('STARRED_IDEAS', []);

  const hiddenAlternates = hiddenAlternatesByIdea[props.idea.id] ?? [];
  return (
    <div className="flex flex-col gap-6 py-6">
      <div className="flex flex-col gap-6 px-6">
        <IdeaHeader
          canShowAll={hiddenAlternates.length > 0}
          idea={props.idea}
          isStarred={starredIdeas.includes(props.idea.id)}
          onCreateItem={props.onCreateItem}
          onShowAll={() =>
            setHiddenAlternatesByIdea({ ...hiddenAlternatesByIdea, [props.idea.id]: [] })
          }
          onStarIdea={() =>
            setStarredIdeas(
              starredIdeas.includes(props.idea.id)
                ? starredIdeas.filter((id) => id !== props.idea.id)
                : [...starredIdeas, props.idea.id]
            )
          }
          projectID={props.projectID}
        />
        <IdeaRelevance idea={props.idea} />
        <div className="flex flex-col gap-4">
          <h2 className="type-heading2">Overview</h2>
          <Tiles
            hiddenAlternates={hiddenAlternates}
            idea={props.idea}
            onCreateItem={props.onCreateItem}
            onHideAlternate={(alternateID: UUID) => {
              const hidden = hiddenAlternatesByIdea[props.idea.id] ?? [];
              setHiddenAlternatesByIdea({
                ...hiddenAlternatesByIdea,
                [props.idea.id]: [...hidden.filter((id) => id !== alternateID), alternateID],
              });
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="px-6 type-heading2">Details</h2>
        <IdeaTabs hiddenAlternates={hiddenAlternates} idea={props.idea} />
      </div>
    </div>
  );
}

function IdeaHeader(props: {
  canShowAll: boolean;
  idea: ProjectIdea;
  isStarred: boolean;
  onCreateItem: () => void;
  onShowAll: () => void;
  onStarIdea: () => void;
  projectID: UUID;
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-4">
        <h1 className="flex w-full max-w-max items-center gap-2 type-heading2">
          <Link
            className="text-type-link"
            to={generateSharedPath(RouteKeys.PROJECT_IDEAS, { projectId: props.projectID })}
          >
            Ideas
          </Link>
          <div>/</div>
          <div>{props.idea.name}</div>
          <Star
            aria-label="Star Idea"
            isSelected={props.isStarred}
            onChange={() => props.onStarIdea()}
          />
        </h1>
        <div className="flex gap-2">
          <Button label="Add Idea To Project" onClick={props.onCreateItem} type="primary" />
          <IconMenuButton
            aria-label="more actions button"
            entries={[
              {
                id: 'show',
                label: 'Show all Alternates',
                startAdornment: <Icon name="show" />,
                onClick: () => props.onShowAll?.(),
                disabled: !props.canShowAll,
              },
            ]}
            type="secondary"
          />
        </div>
      </div>
      <div className="flex gap-6">
        {props.idea.root.uniFormat && (
          <Category categorization="UniFormat" category={props.idea.root.uniFormat} />
        )}
        {props.idea.root.masterFormat && (
          <Category categorization="MasterFormat" category={props.idea.root.masterFormat} />
        )}
      </div>
    </div>
  );
}

function Category(props: { categorization: string; category: { name: string; number: string } }) {
  return (
    <div className="flex flex-col gap-0.5">
      <div className="type-label">{props.categorization}</div>
      <div className="flex flex-row items-center gap-2">
        <div className="line-clamp-1">{props.category.name}</div>
        <Chip text={props.category.number} />
      </div>
    </div>
  );
}

function Tiles(props: {
  hiddenAlternates: UUID[];
  idea: ProjectIdea;
  onCreateItem: (alternateID: UUID) => void;
  onHideAlternate: (alternateID: UUID) => void;
}) {
  return (
    <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(310px,1fr))] gap-6">
      <RootTile material={props.idea.root} />
      {props.idea.alternates
        .filter(({ id }) => !props.hiddenAlternates.includes(id))
        .map((alternate) => (
          <AlternateTile
            key={alternate.id}
            alternate={alternate}
            onCreateItem={() => props.onCreateItem(alternate.id)}
            onHide={() => props.onHideAlternate(alternate.id)}
          />
        ))}
    </div>
  );
}
