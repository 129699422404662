import { useState } from 'react';
import { Navigate, useOutlet, useParams } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import { AccessLevel, ResourceType } from '../../generated/graphql';
import { useCurrentUser } from '../contexts/current-user';
import { useGetSharedResource } from '../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';

export default function ProgramRoute() {
  const { programID } = useParams();
  const user = useCurrentUser();

  const [hasPermission, setHasPermisison] = useState<boolean | undefined>(undefined);

  const { data: sharedResourceData } = useGetSharedResource(programID, ResourceType.PROGRAM, {
    onCompleted: () => {
      const userHasAccess = sharedResourceData?.getSharedResource?.resource?.users.find((u, i) => {
        return (
          u.id === user.id &&
          sharedResourceData?.getSharedResource?.resource?.accessLevels[i] !== AccessLevel.NONE
        );
      });
      setHasPermisison(!!userHasAccess);
    },
  });

  const outlet = useOutlet();

  if (hasPermission === undefined) {
    return <LinearProgress />;
  }

  if (!programID || (hasPermission !== undefined && !hasPermission))
    return <Navigate replace to="/404" />;

  return outlet;
}
