import { useState } from 'react';

import { SortDirection } from '../../../../generated/graphql';

export enum CompanyUserSortKey {
  AVATAR = 'avatar',
  NAME = 'Name',
  JOB_TITLE = 'Job title',
  COMPANY_ROLE = 'Company role',
}

export const useSortUserManager = () => {
  const [sortState, setSort] = useState<SortBy>({
    sortDirection: SortDirection.SORT_NONE,
    sortKey: CompanyUserSortKey.NAME,
  });

  return {
    sortState,
    setSort,
  };
};
