import { ProgramProjectFieldsFragment } from '../../../../../generated/graphql';
import ProjectSelection from '../../../../ProjectsList/ProjectSelection/ProjectSelection';
import { Button, DialogFlowStep, Tooltip } from '../../../../scales';

type Props = {
  onChange: (newProjectIDs: UUID[]) => void;
  onSubmit: () => void;
  selectedProjects: ProgramProjectFieldsFragment[];
  isCreate: boolean;
};

export default function getProjectSelectionStep(props: Props): DialogFlowStep {
  const selectedProjectCount = props.selectedProjects.length;
  const projectCountWarning = selectedProjectCount < 2 ? `You must select at least 2 Projects` : '';
  const tooltipContent =
    selectedProjectCount < 2
      ? projectCountWarning
      : `Select at least 2 Projects you are an Admin role in`;

  const buttonLabel = props.isCreate
    ? `Select ${selectedProjectCount} and add Details`
    : `Select ${selectedProjectCount} and edit Details`;

  return {
    title: 'New Program',
    renderContent: () => (
      <ProjectSelectionContent
        isCreate={props.isCreate}
        onChange={props.onChange}
        selectedProjects={props.selectedProjects}
      />
    ),
    renderFooterRight: (args) => {
      const disabled = selectedProjectCount < 2;
      return (
        <div className="flex items-center gap-4">
          <div>{tooltipContent}</div>
          <Tooltip content={tooltipContent}>
            <Button
              isDisabled={disabled}
              label={buttonLabel}
              onClick={() => {
                props.onSubmit();
                args.onNext();
              }}
              type="primary"
            />
          </Tooltip>
        </div>
      );
    },
  };
}

type ProjectSelectionContentProps = {
  selectedProjects: ProgramProjectFieldsFragment[];
  onChange: (newProjectIDs: UUID[]) => void;
  isCreate: boolean;
};

function ProjectSelectionContent(props: ProjectSelectionContentProps) {
  return (
    <div>
      {props.isCreate && (
        <div className="flex h-16 items-center px-6">
          Select at least 2 Projects you are an Administrator role in to create a Program
        </div>
      )}
      <ProjectSelection
        disableIfNotAdmin
        onChange={(newProjectIDs) => props.onChange(newProjectIDs)}
        selectedProjectIDs={props.selectedProjects.map((p) => p.id)}
        selectionMode="multiple"
      />
    </div>
  );
}
