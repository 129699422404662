import { useState } from 'react';

import DialogsCompanyRoles from '../../Dialogs/DialogsCompanyRoles/DialogsCompanyRoles';
import { Button, Icon } from '../../scales';

type Props = {
  onClick: () => void;
};

export default function CompanyTabRoleInfo(props: Props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        label="View Company Roles"
        onClick={() => {
          setOpen(true);
          props.onClick();
        }}
        startIcon={<Icon name="group" />}
        type="secondary"
      />
      <DialogsCompanyRoles
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      />
    </>
  );
}
