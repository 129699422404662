import { useState } from 'react';
import { Link } from 'react-router-dom';

import { COMPANY_DASHBOARD } from '../../../constants';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { LongRightIcon } from '../../Milestone/MilestoneDetails/MilestoneDetailsNav/MilestoneDetailsNavIcons';
import { Button } from '../../scales';
// eslint-disable-next-line no-restricted-imports
import LazyLoadedScrollShadow from '../../scales/ScrollContainer/LazyLoadedScrollShadow';
import ScrollContainer from '../ScrollContainer';
import { InsightsProject as InsightsProjectType } from '../types';

import InsightsProject from './InsightsProject';
import InsightsProjectBaseline from './InsightsProjectBaseline';
import InsightsProjectHeader from './InsightsProjectHeader';

// Vertical scroll top, after which the baseline header appears
const SCROLLED_TOP = 120;

export default function InsightsProjectDetails(props: {
  companyID: UUID;
  project: InsightsProjectType;
}) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const breadcrumbsHeader = (
    <div className="flex justify-between px-5">
      <div className="flex items-center gap-4 type-heading1">
        <div>
          <Link to={generateSharedPath(RouteKeys.INSIGHTS)}>
            <span className="text-type-link">{COMPANY_DASHBOARD}</span>
          </Link>
          <span>&nbsp;/&nbsp;</span>
          <span>{props.project?.name}</span>
        </div>
        {props.project.hasAccess && (
          <Link to={generateSharedPath(RouteKeys.PROJECT, { projectId: props.project.id })}>
            <Button endIcon={<LongRightIcon />} label="Go to project" type="secondary" />
          </Link>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex max-h-full min-w-[800px] max-w-full grow flex-col justify-between gap-4 overflow-auto pt-4">
      {breadcrumbsHeader}
      <ScrollContainer
        direction="vertical"
        hasNoShadow
        onScroll={(event) =>
          setIsScrolled(event.currentTarget.scrollTop > SCROLLED_TOP + headerHeight)
        }
      >
        <InsightsProjectHeader
          companyID={props.companyID}
          onResize={setHeaderHeight}
          project={props.project}
        />
        <div
          className="sticky top-0 z-50 bg-background-primary px-5 pb-2 pt-3"
          style={{
            opacity: isScrolled ? 1 : 0,
            transition: 'opacity 0.3s',
            height: isScrolled ? 'auto' : 0,
          }}
        >
          <InsightsProjectBaseline project={props.project} />
          <LazyLoadedScrollShadow position="top" />
        </div>
        <InsightsProject project={props.project} />
      </ScrollContainer>
    </div>
  );
}
