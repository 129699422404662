import * as d3 from 'd3';

import { DEFAULT_MARGIN } from '../../../../constants';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';

import InsightsMilestoneTip from './InsightsMilestoneTip';
import { InsightsMilestone } from './types';
import { useGmps } from './utils';

type Props = {
  data: InsightsMilestone[];
  height: number;
  margin?: typeof DEFAULT_MARGIN;
  today: Date;
  totalRange: [Date, Date];
};

export default function InsightsBaseChips(props: Props) {
  const { data, height, today, totalRange } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = props.margin ?? DEFAULT_MARGIN;

  const { activeGmp, activeMilestone, comingGmp } = useGmps(data, today);

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yMin, yMax] = [0 - (height / 10) * 100, (height / 90) * 100];
  const yDomain = [yMin, yMax];
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  const bounds = {
    right: x(xMax),
    left: x(xMin),
  };

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {/* Tips info */}
      {data
        .filter(({ id }) => activeGmp === id || comingGmp === id || activeMilestone === id)
        .map((data) => (
          <InsightsMilestoneTip
            key={`tip-${data.id}`}
            bounds={bounds}
            data={data}
            today={today}
            value={100}
            x={x}
            y={y}
          />
        ))}
    </SVGWithDimensions>
  );
}
