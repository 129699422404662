import { Outlet, useParams } from 'react-router-dom';

import { COMPANY_DASHBOARD } from '../../constants';
import { useUserProfile } from '../../utilities/userProfile';
import { useCompanyTabID } from '../CompanyTab/CompanyTabUtils';

import InsightsProjectsList from './InsightsProjectsList';

export default function ExecutiveDashboardV2() {
  const profile = useUserProfile();
  // Company data
  const companyID = useCompanyTabID();
  // Project
  const { projectId } = useParams();
  document.title = `${COMPANY_DASHBOARD} ${profile ? ` - ${profile.nickname}` : ''}`;

  // The Outlet for rendering Project details route
  if (projectId) return <Outlet />;

  if (!companyID) return null;

  return <InsightsProjectsList companyID={companyID} />;
}
