import { useState } from 'react';

import {
  ProgramCategorizationsQuery,
  ProgramCostFieldsFragment,
  ProgramCostTrendline,
  ProgramFieldsFragment,
  ProjectCostBreakdown,
} from '../../generated/graphql';
import { pieChartDefaultColors } from '../Charts/ChartsD3/ChartsPieGraph/utils';
import ProgramCostTrendlineChart from '../dragon-scales/TimelineCharts/ProgramCostTrendline/ProgramCostTrendline';
import {
  costBreakdownTypeToColorMap,
  programCostBreakdownHeaders,
  projectCostBreakdownHeaders,
} from '../ProjectCostBreakdownReport/utils';

import AllProjectsFilteredOutEmptyState from './AllProjectsFilteredOutEmptyState';
import ProgramCombinedEstimateChart from './ProgramCombinedEstimateChart';
import ProgramDashboardHeader from './ProgramDashboardHeader';
import ProgramDashboardPieChart from './ProgramDashboardPieChart';
import ProgramDashboardProjectSelection from './ProgramDashboardProjectSelection';

type Props = {
  chartData?: ProgramCostTrendline;
  isPrinting: boolean;
  program?: ProgramFieldsFragment;
  programBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  programCategorizations: ProgramCategorizationsQuery['programCategorizations'];
  programCosts?: ProgramCostFieldsFragment;
  projectBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  projects: ForecastingProject[];
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
  totalProgramCost: number;
};

export default function ProgramDashboard({
  program,
  isPrinting,
  programCosts,
  programBreakdown,
  projects,
  projectBreakdown,
  selectedProjectIDs,
  setSelectedProjectIDs,
  totalProgramCost,
  chartData,
  programCategorizations,
}: Props) {
  const [isOpen, setIsOpen] = useState(isPrinting);

  const projectColors = new Map<string, string>();
  projectBreakdown.forEach((project, i) => {
    projectColors.set(project.name, pieChartDefaultColors[i]);
  });

  return (
    <div
      className={`flex w-full flex-col justify-center gap-6 overflow-visible p-6 ${
        !selectedProjectIDs.length ? 'h-full' : ''
      }`}
    >
      <ProgramDashboardHeader
        isOpen={isOpen}
        isPrinting={isPrinting}
        program={program}
        programCosts={programCosts}
        selectedProjectIDs={selectedProjectIDs}
        setIsOpen={setIsOpen}
      />
      <ProgramDashboardProjectSelection
        isOpen={isOpen}
        isPrinting={isPrinting}
        projectBreakdown={projectBreakdown}
        projects={projects}
        selectedProjectIDs={selectedProjectIDs}
        setSelectedProjectIDs={setSelectedProjectIDs}
      />
      {!selectedProjectIDs.length && (
        <AllProjectsFilteredOutEmptyState
          program={program}
          setSelectedProjectIDs={setSelectedProjectIDs}
        />
      )}
      {!!selectedProjectIDs.length && program && (
        <>
          <ProgramCostTrendlineChart
            combined={chartData?.combined?.combinedCostTimeSeries ?? []}
            dataRange={[chartData?.combined?.minCost ?? 0, chartData?.combined?.maxCost ?? 0]}
            height={500}
            isPrint={false}
            separated={chartData?.separated}
            separatedProjectColors={projectColors}
            timeRange={[new Date(chartData?.minDate ?? 0), new Date(chartData?.maxDate ?? 0)]}
          />
          <ProgramDashboardPieChart
            breakdown={projectBreakdown}
            colorMap={projectColors}
            headers={projectCostBreakdownHeaders}
            name="Breakdown By Project"
            total={totalProgramCost}
          />
          <ProgramDashboardPieChart
            breakdown={programBreakdown}
            colorMap={costBreakdownTypeToColorMap}
            headers={programCostBreakdownHeaders}
            name="Breakdown By Cost"
            total={totalProgramCost}
          />
          {!!programCategorizations.length && (
            <ProgramCombinedEstimateChart
              programCategorizations={programCategorizations}
              programID={program.id}
              selectedProjectIDs={selectedProjectIDs}
            />
          )}
        </>
      )}
    </div>
  );
}
