import { useEffect, useMemo } from 'react';

import { Divider } from '@material-ui/core';

import { NS_PROGRAM_REPORTING } from '../../../../features';
import { SearchResultType } from '../../../../generated/graphql';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import AdvancedFilters from '../../../frame/AdvancedFiltersSidebar/AdvancedFilters';
import ProgramsFilters from '../../../frame/AdvancedFiltersSidebar/ProgramsFilters';
import ProjectsFilters from '../../../frame/AdvancedFiltersSidebar/ProjectsFilters';
import SearchHeader from '../SearchHeader';

import { activeFiltersCountProjects } from './activeFilterCountProjects';
import BannerManager from './BannerManager';
import { useProgramsQuery } from './hooks/useProgramsQuery';
import { useSearchProjectsQuery } from './hooks/useSearchProjectsQuery';
import useSearchProjectsQueryParams, {
  ProgramToggleValue,
} from './hooks/useSearchProjectsQueryParams';
import { useUpdateSearchProjectsFromURL } from './hooks/useUpdateSearchProjectsFromURL';
import SearchPrograms from './SearchPrograms';
import SearchProjects from './SearchProjects';
import { getProgramVariables, useFilterOptions } from './utils';

type Props = {
  isFilterMenuOpen: boolean;
  onToggleFilterMenu: (isOpen: boolean) => void;
};

const SearchProjectsData = (props: Props) => {
  useEffect(() => {
    document.title = 'Projects';
  }, []);

  const hasProgramReporting = useHasFeature(NS_PROGRAM_REPORTING);

  const params = useSearchProjectsQueryParams();
  useUpdateSearchProjectsFromURL(
    params.onChangeSearch,
    params.onResetFilters,
    params.toggleParams?.onChange
  );

  const {
    data: projectData,
    fetchMore,
    loading: loadingProjects,
    previousData,
  } = useSearchProjectsQuery(params.variables);
  const programVariables = getProgramVariables(params.variables);
  const programQuery = useProgramsQuery(programVariables);

  const { searchResultType, filters } = params.variables;
  const filterOptions = useFilterOptions(searchResultType !== SearchResultType.MY, filters);
  const projects = projectData?.searchProjects?.data ?? previousData?.searchProjects?.data ?? [];
  // const programs = programData?.programs ?? [];
  const programs = programQuery.data?.programs ?? programQuery.previousData?.programs ?? [];
  const counts =
    params.programToggleParams?.value === ProgramToggleValue.PROJECTS
      ? {
          current: projectData?.searchProjects?.total ?? previousData?.searchProjects?.total ?? 0,
          total:
            searchResultType !== SearchResultType.MY
              ? params.toggleCounts.all
              : params.toggleCounts.my,
        }
      : {
          current: programQuery?.data?.programs?.length ?? 0,
          total:
            searchResultType !== SearchResultType.MY
              ? params.toggleCounts.all
              : params.toggleCounts.my,
        };

  const activeFiltersCount = useMemo(() => {
    if (params.programToggleParams?.value === ProgramToggleValue.PROGRAMS) return 0;

    return activeFiltersCountProjects(filters);
  }, [filters, params.programToggleParams?.value]);

  const isViewingPrograms = params.programToggleParams?.value === ProgramToggleValue.PROGRAMS;

  return (
    <div className="flex h-full grow flex-col">
      <SearchHeader
        activeFiltersCount={activeFiltersCount}
        isFilterMenuOpen={props.isFilterMenuOpen}
        onChangeSearch={params.onChangeSearch}
        onToggleFilterMenu={props.onToggleFilterMenu}
        search={params.variables.search}
        searchPlaceholder="Search projects"
        toggleParams={hasProgramReporting ? params.programToggleParams : params.toggleParams}
      />
      <Divider />
      <BannerManager />
      <div className="flex flex-grow overflow-hidden">
        {props.isFilterMenuOpen && (
          <AdvancedFilters
            hasActiveFilters={activeFiltersCount > 0}
            onCloseFiltersPanel={props.onToggleFilterMenu}
            onResetFilters={params.onResetFilters}
          >
            {hasProgramReporting && isViewingPrograms && params.toggleParams && (
              <ProgramsFilters isExpanded searchToggle={params.toggleParams} />
            )}
            {!isViewingPrograms && (
              <ProjectsFilters
                filterOptions={filterOptions}
                filterValues={params.variables.filters}
                isExpanded
                onChangeFilters={params.onChangeFilters}
                searchToggleFilterParams={params.toggleParams}
              />
            )}
          </AdvancedFilters>
        )}
        <div className="grow overflow-x-auto">
          {isViewingPrograms ? (
            <SearchPrograms
              counts={counts}
              isLoading={programQuery?.loading}
              programs={programs}
              searchText={params.variables.search}
              sortManager={params.sortManager}
            />
          ) : (
            <SearchProjects
              counts={counts}
              isLoading={loadingProjects}
              onFetchMore={fetchMore}
              projects={projects}
              searchText={params.variables.search}
              sortManager={params.sortManager}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchProjectsData;
