import { ComponentProps } from 'react';

import { BusinessOutlined } from '@material-ui/icons';

import ExpandableFiltersSection from '../common/ExpandableFiltersSection';

import SearchToggleRadioGroup from './SearchToggleRadioGroup';

type Props = {
  isExpanded: boolean;
  searchToggle: ComponentProps<typeof SearchToggleRadioGroup>;
};

export default function ProgramsFilters(props: Props) {
  return (
    <ExpandableFiltersSection
      icon={<BusinessOutlined />}
      isExpanded={props.isExpanded}
      title="Program Filters"
    >
      <SearchToggleRadioGroup
        onChange={props.searchToggle.onChange}
        options={props.searchToggle.options}
        value={props.searchToggle.value}
      />
    </ExpandableFiltersSection>
  );
}
