import { Link } from 'react-router-dom';

import { BusinessOutlined, ErrorOutline, LocationOnOutlined } from '@material-ui/icons';

import { RouteKeys } from '../../../routes/paths';
import { fromNow } from '../../../utilities/dates';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Chip } from '../../scales';
import { InsightsTabId } from '../types';
import { getUniqueAlertDescriptionCounts } from '../utils';

type Props = {
  project: InsightsProjects;
  tabType: InsightsTabId;
};

const ProjectTableTooltip = ({ project, tabType }: Props) => {
  const chips = (
    <div className="flex flex-wrap gap-2">
      <div className="max-w-[200px]">
        <Chip icon={<LocationOnOutlined />} text={project.location} />
      </div>
      {project.type && (
        <div className="max-w-[200px]">
          <Chip icon={<BusinessOutlined />} text={project.type} />
        </div>
      )}
      {project.status && (
        <div className="max-w-[200px]">
          <Chip text={project.status} />
        </div>
      )}
      {project.projectLeadName && (
        <div className="max-w-[200px]">
          <Chip
            icon={
              <div>
                <img alt="project lead icon" src="/img/GroupsOutlined.svg" width={16} />
              </div>
            }
            text={project.projectLeadName}
          />
        </div>
      )}
      {project.orgNodes.map((node) => (
        <div key={node?.id} className="max-w-[200px]">
          {node?.name && (
            <Chip
              icon={
                <div>
                  <img alt="organization icon" src="/img/OrganizationTree.svg" width={12} />
                </div>
              }
              text={node?.name}
            />
          )}
        </div>
      ))}
    </div>
  );

  const uniqueAlerts = getUniqueAlertDescriptionCounts(project.activeAlerts);
  const alerts = uniqueAlerts.map((a) => a.description);

  return (
    <div className="flex w-75 flex-col gap-2 p-2">
      <div>
        {project.hasAccess ? (
          <Link
            to={{
              pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, {
                projectId: project.id,
              }),
              hash: `#${tabType}`,
            }}
          >
            <div className="line-clamp-1 text-type-link type-heading3">{project.name}</div>
          </Link>
        ) : (
          <div className="line-clamp-1 type-heading3">{project.name}</div>
        )}
      </div>
      {chips}
      <div className="type-body2">
        Last updated {fromNow(project.lastUpdated)} by {project.lastUpdatedBy}
      </div>

      {/* Alerts */}
      {alerts.map((alert) => (
        <div key={alert} className="flex items-center gap-1">
          <ErrorOutline color="error" style={{ width: 20, height: 20 }} />
          <div className="text-type-error type-body2">{alert}</div>
        </div>
      ))}
    </div>
  );
};

export default ProjectTableTooltip;
