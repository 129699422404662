import * as d3 from 'd3';

import { DEFAULT_MARGIN } from '../../../../constants';
import { formatCost } from '../../../../utilities/currency';
import { isNonNullable } from '../../../../utilities/types';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import useMemoWrapper from '../../../useMemoWrapper';
import CostFillPattern from '../ChartsCostTrendline/CostFillPattern';
import CostLabelBlur from '../ChartsCostTrendline/CostLabelBlur';
import TimelineArea from '../TimelineArea';
import TimelineHoverSections from '../TimelineHoverSections';
import TimelineMultiTooltip from '../TimelineMultiTooltip/TimelineMultiTooltip';
import TimelinePath from '../TimelinePath';

import InsightsEmptyChart from './InsightsEmptyChart';
import { CostTimeSeries } from './types';
import { getYDomain, getYDomainPadded, isNonNullUSCents } from './utils';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  costData: CostTimeSeries[];
  height: number;
  hoverDate?: Date | undefined;
  isPrint?: boolean;
  setHoverDate: (date: Date | undefined) => void;
  today: Date;
  totalRange: [Date, Date];
};

export default function InsightsCostTrendline(props: Props) {
  const { costData, height, hoverDate, isPrint, setHoverDate, today, totalRange } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = DEFAULT_MARGIN;
  // Last data index, the first point for horizontal lines after today
  const lastIndex = costData.length - 1;

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, costData);
  // Add vertical padding
  const { yDomain, yMin, yMax } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const bounds = {
    right: x(xMax),
    left: x(xMin),
    top: y(yMin),
    bottom: y(yMax),
  };

  // Hover index for tooltips
  const hoverIndex = costData.findIndex(({ date }) => date.getTime() === hoverDate?.getTime());
  const hoverCost = costData[hoverIndex];
  const hasEstimateValue = isNonNullUSCents(hoverCost?.estimate);
  const hasRunningTotalValue = isNonNullUSCents(hoverCost?.runningTotal);
  const hasBudgetValue = isNonNullUSCents(hoverCost?.budget);
  const hasAtLeastOne = hasEstimateValue || hasRunningTotalValue || hasBudgetValue;

  if (!costData.length) {
    return <InsightsEmptyChart height={height} today={today} totalRange={totalRange} />;
  }

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {/* Pending Area */}
      <TimelineArea<{ pendingMin: number; pendingMax: number }, { date: Date }>
        data={costData}
        fieldDate="date"
        fieldMax="pendingMax"
        fieldMin="pendingMin"
        fill="url(#stripes)"
        filterNulls
        stroke="var(--colors-chart-pending-cost-area)"
        x={x}
        y={y}
      />
      {/* Estimate */}
      <TimelinePath<{ estimate: number }>
        // Before today
        data={costData}
        field="estimate"
        filterNulls
        hasSteps
        stroke="stroke-entities-estimate"
        strokeDasharray="1, 2"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath<{ estimate: number }>
        // After today
        data={[costData[lastIndex], { date: xMax, estimate: costData[lastIndex].estimate }]}
        field="estimate"
        filterNulls
        stroke="stroke-chart-axis"
        strokeDasharray="1, 2"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Running Total */}
      <TimelinePath<{ runningTotal: number }>
        // Before today
        data={costData}
        field="runningTotal"
        filterNulls
        stroke="stroke-entities-estimate"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath<{ runningTotal: number }>
        // After today
        data={[costData[lastIndex], { date: xMax, runningTotal: costData[lastIndex].runningTotal }]}
        field="runningTotal"
        filterNulls
        stroke="stroke-chart-axis"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Budget */}
      <TimelinePath<{ budget: number }>
        // Before today
        data={costData}
        field="budget"
        filterNulls
        hasSteps
        stroke="stroke-entities-milestone"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath<{ budget: number }>
        // After today
        data={[costData[lastIndex], { date: xMax, budget: costData[lastIndex].budget }]}
        field="budget"
        filterNulls
        stroke="stroke-chart-axis"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Line */}
      {hoverDate && (
        <TimelinePath<{ value: number }>
          // current hover date
          data={[
            { date: hoverDate, value: yMin },
            { date: hoverDate, value: yMax },
          ]}
          stroke="stroke-chart-axis"
          strokeWidth={1.5}
          x={x}
          y={y}
        />
      )}
      {/* Today */}
      <TimelinePath<{ value: number }>
        data={todayData}
        stroke="stroke-selection-focus-fill"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Points */}
      {!isPrint && hoverDate && hasAtLeastOne && (
        <TimelineMultiTooltip
          key={`tooltip-${hoverIndex}-${hoverDate}`}
          backgroundColor={[
            hasEstimateValue ? 'var(--colors-button-primary-hover)' : null,
            hasRunningTotalValue ? 'var(--colors-button-primary)' : null,
            hasBudgetValue ? 'var(--colors-entities-milestone)' : null,
          ].filter(isNonNullable)}
          content={[
            hasEstimateValue ? formatCost(hoverCost.estimate, costFormat) : null,
            hasRunningTotalValue ? formatCost(hoverCost.runningTotal, costFormat) : null,
            hasBudgetValue ? formatCost(hoverCost.budget, costFormat) : null,
          ].filter(isNonNullable)}
          data={[
            // Data set
            hasEstimateValue ? { ...hoverCost, value: hoverCost.estimate } : null,
            hasRunningTotalValue ? { ...hoverCost, value: hoverCost.runningTotal } : null,
            hasBudgetValue ? { ...hoverCost, value: hoverCost.budget } : null,
          ].filter(isNonNullable)}
          fill={[
            hasEstimateValue ? 'fill-type-primary' : null,
            hasRunningTotalValue ? 'fill-type-primary' : null,
            hasBudgetValue ? 'fill-entities-milestone' : null,
          ].filter(isNonNullable)}
          isOpen
          x={x}
          y={y}
          yDomain={yDomain}
        />
      )}
      <TimelineHoverSections
        bounds={bounds}
        data={costData}
        onHoverIndex={(index) =>
          index === -1 ? setHoverDate(undefined) : setHoverDate(costData[index]?.date)
        }
        x={x}
      />
      <CostFillPattern />
      <CostLabelBlur />
    </SVGWithDimensions>
  );
}
