import { Checkbox, ScrollContainer, Tooltip } from '../../scales';

import ProjectSelectionListEntry from './ProjectSelectionListEntry';

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disableIfNotAdmin?: boolean;
  fetchMore: () => void;
  hasHover?: boolean;
  isLink?: boolean;
  onChange?: (projectIDs: UUID[]) => void;
  projects: SearchProject[];
  searchTerm: string;
  selectedProjectIDs?: UUID[];
  selectionMode: 'single' | 'multiple';
};

export default function ProjectSelectionList({
  disableIfNotAdmin = false,
  fetchMore,
  hasHover = false,
  isLink = false,
  onChange,
  projects,
  searchTerm,
  selectedProjectIDs = [],
  selectionMode,
}: Props) {
  const selectionColor = hasHover ? 'bg-selection-selected' : '';
  return (
    <ScrollContainer className="gap-2" direction="vertical" onNeedMoreData={fetchMore}>
      {projects.map((project) => {
        const isCheckboxDisabled = disableIfNotAdmin && !project?.isAdmin;
        return (
          <div
            key={project.id}
            className={`rounded px-4 ${
              selectedProjectIDs?.includes(project.id) ? selectionColor : 'bg-background-primary'
            } ${hasHover ? 'border hover:bg-selection-hover' : ''}`}
          >
            {selectionMode === 'multiple' ? (
              <Tooltip
                content={
                  isCheckboxDisabled ? 'You must be an Admin role on this Project to add' : ''
                }
              >
                <Checkbox
                  key={project.id}
                  aria-label="select this project"
                  data-cy="project-selection-entry"
                  isDisabled={isCheckboxDisabled}
                  isSelected={selectedProjectIDs.includes(project.id)}
                  onChange={(selected) => {
                    if (selected) {
                      onChange?.([...selectedProjectIDs, project.id]);
                    } else {
                      onChange?.(selectedProjectIDs?.filter((id) => id !== project.id));
                    }
                  }}
                >
                  <ProjectSelectionListEntry
                    key={project.id}
                    isLink={isLink}
                    project={project}
                    searchTerm={searchTerm}
                  />
                </Checkbox>
              </Tooltip>
            ) : (
              <button
                aria-label="select this project"
                className="block w-full text-left"
                data-cy="project-selection-entry"
                onClick={() => {
                  onChange?.([project.id]);
                }}
              >
                <ProjectSelectionListEntry
                  key={project.id}
                  project={project}
                  searchTerm={searchTerm}
                />
              </button>
            )}
          </div>
        );
      })}
    </ScrollContainer>
  );
}
