import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import ProgramThumbnail from '../../ProgramReporting/ProgramThumbnail';
import { Chip, Chips, Icon, IconButton, Tooltip } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';
import CollapseIcon from '../../shared-widgets/CollapseIcon';
import ProjectLink from '../ProjectLink';

import ClampedContent from './ClampedContent';
import { Program } from './types';
import { CHIP_LABEL_LENGTH_LIMIT, getTruncatedText } from './utils';

type Props = {
  isCollapsed: boolean;
  onClick: () => void;
  program: Program;
  searchText: string;
};

export default function ProgramCell(props: Props) {
  const chips = getChips(props.program);
  return (
    <div className="flex items-center gap-5 py-2">
      <IconButton
        aria-label="Expand Project"
        icon={<CollapseIcon className="!icon-lg" isCollapsed={props.isCollapsed} />}
        onClick={props.onClick}
        type="secondary"
      />

      <ProgramThumbnail
        projectCount={props.program.projects.length ?? 0}
        projectThumbnail={props.program.thumbnail}
      />
      <div className="flex flex-col gap-2">
        <ProjectLink
          hasAccess={props.program.hasAccess ?? false}
          projectName={props.program.name}
          to={generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, { programID: props.program.id })}
        >
          <div className="type-heading2" data-project-id={props.program.id}>
            <SearchText searchTerm={props.searchText} text={props.program.name} />
          </div>
        </ProjectLink>
        <ClampedContent toolTipEntries={chips.map((c) => c.label)}>
          <Chips>
            {chips.map((chip) => (
              <Tooltip
                key={`program-${props.program.id}-${chip.label}`}
                content={chip.label}
                isDisabled={chip.label.length <= CHIP_LABEL_LENGTH_LIMIT}
              >
                <Chip
                  icon={chip.icon}
                  text={
                    <SearchText searchTerm={props.searchText} text={getTruncatedText(chip.label)} />
                  }
                />
              </Tooltip>
            ))}
          </Chips>
        </ClampedContent>
      </div>
    </div>
  );
}

const getChips = (program: Program) => {
  const programProjectCount = program.projects.length.toString();
  return [
    {
      label: `${programProjectCount} Projects`,
      icon: <Icon name="program" size="sm" />,
    },
    {
      label: program.type,
      icon: <BusinessOutlined />,
    },
    {
      label: program.locationDetails.name,
      icon: <LocationOnOutlined />,
    },
  ];
};
