import { ProjectCostBreakdown } from '../../generated/graphql';
import { pieChartDefaultColors } from '../Charts/ChartsD3/ChartsPieGraph/utils';
import ProjectSelectionList from '../ProjectsList/ProjectSelection/ProjectSelectionList';
import ProjectSelectionListEntry from '../ProjectsList/ProjectSelection/ProjectSelectionListEntry';

type Props = {
  isOpen: boolean;
  isPrinting: boolean;
  projectBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  projects: ForecastingProject[];
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
};

export default function ProgramDashboardProjectSelection({
  isOpen,
  isPrinting,
  projects,
  projectBreakdown,
  selectedProjectIDs,
  setSelectedProjectIDs,
}: Props) {
  const projectColors = new Map<string, string>();
  projectBreakdown.forEach((project, i) => {
    projectColors.set(project.name, pieChartDefaultColors[i]);
  });
  if (!isPrinting && isOpen)
    return (
      <ProjectSelectionList
        key="ProjectSelectionList"
        fetchMore={() => {}}
        hasHover={false}
        onChange={setSelectedProjectIDs}
        projects={projects}
        searchTerm=""
        selectedProjectIDs={selectedProjectIDs}
        selectionMode="multiple"
      />
    );

  if (isPrinting)
    return (
      <>
        {projects.map((project) => (
          <div key={project.id} className="px-4">
            <ProjectSelectionListEntry key={project.id} project={project} searchTerm="" />
          </div>
        ))}
      </>
    );

  return null;
}
