import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeleteOutline, Edit, MoreVert, Print } from '@material-ui/icons';

import { AccessLevel, ProgramFieldsFragment, ResourceType } from '../../generated/graphql';
import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { useCurrentUser } from '../contexts/current-user';
import { Export } from '../Icons/Export';
import ShareGroupAdd from '../Icons/ShareGroupAdd';
import { useGetSharedResource } from '../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import { Button, IconMenuButton, MenuEntry } from '../scales';

import { useDeleteProgramMutation } from './ProgramDialog/hooks/useDeleteProgramMutation';
import ProgramDialog from './ProgramDialog/ProgramDialog';
import ProgramUsersDialogData from './ProgramUsersDialog/ProgramUsersDialogData';

type Props = {
  isPrinting: boolean;
  program?: ProgramFieldsFragment;
};

export default function ProgramDashboardMenu({ isPrinting, program }: Props) {
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isProgramOwner, setIsProgramOwner] = useState<boolean | undefined>(undefined);

  const user = useCurrentUser();

  // Hooks
  const { data: sharedResourceData } = useGetSharedResource(program?.id, ResourceType.PROGRAM, {
    onCompleted: () => {
      const userIsOwner = sharedResourceData?.getSharedResource?.resource?.users.find((u, i) => {
        return (
          u.id === user.id &&
          sharedResourceData?.getSharedResource?.resource?.accessLevels[i] === AccessLevel.OWNER
        );
      });
      setIsProgramOwner(!!userIsOwner);
    },
  });
  const navigate = useNavigate();
  const [deleteProgram] = useDeleteProgramMutation(program?.id || '', {
    onCompleted: () => {
      navigate(generateSharedPath(RouteKeys.SEARCH_PROJECTS));
    },
  });

  const menuEntries: MenuEntry[] = [];
  menuEntries.push(
    {
      disabled: false,
      id: 'edit-program',
      label: 'Edit Program',
      onClick: () => {
        setIsOpenEdit(true);
      },
      startAdornment: <Edit />,
      type: 'default',
    },
    {
      disabled: false,
      id: 'delete-program',
      label: 'Delete Program',
      onClick: deleteProgram,
      startAdornment: <DeleteOutline />,
      type: 'destructive',
    }
  );

  const exportEntries = [
    {
      id: 'print-dashboard',
      label: 'Print Dashboard',
      startAdornment: <Print />,
      onClick: () =>
        window.open(
          generateSharedPath(RouteKeys.PROGRAM_DASHBOARD_PRINT, { programID: program?.id }),
          '_blank'
        ),
    },
  ];

  return (
    <div className="flex h-20 items-center gap-2 bg-background-primary p-6">
      {program && (
        <ProgramUsersDialogData isOpen={isOpenShare} program={program} setIsOpen={setIsOpenShare} />
      )}
      {program && isOpenEdit && (
        <ProgramDialog
          isCreate={false}
          onClose={() => setIsOpenEdit(false)}
          onCompleted={(programID) => {
            navigate(generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, { programID }));
          }}
          program={program}
        />
      )}
      <div className="flex-grow type-giant type-heading1">{program?.name}</div>
      {isProgramOwner && !isPrinting && (
        <>
          <Button
            label="Share"
            onClick={() => setIsOpenShare(true)}
            startIcon={<ShareGroupAdd />}
            type="primary"
          />
          <IconMenuButton
            aria-label="Open Print View"
            entries={exportEntries}
            icon={<Export />}
            type="secondary"
          />
          <IconMenuButton
            aria-label="export-menu"
            entries={menuEntries}
            icon={<MoreVert />}
            type="secondary"
          />
        </>
      )}
    </div>
  );
}
