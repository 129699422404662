import { FC, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@material-ui/core';
import {
  ChartLabel,
  FlexibleXYPlot,
  Hint,
  HorizontalGridLines,
  LabelSeries,
  VerticalBarSeries,
  XAxis,
  YAxis,
  // @ts-ignore JIRA: CT-224
} from 'react-vis/dist';
import 'react-vis/dist/style.css';

import { JoinProjectRoutes, TermKey } from '../../../api/gqlEnums';
import { CostReportColumnType } from '../../../generated/graphql';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost, simpleCost } from '../../../utilities/currency';
import truncateLabel, {
  PRINT_CHART_BAR_COUNT_BREAKPOINT,
  cleanCategoryForChart,
  fontSize,
  fontSizeTable,
  getTableData,
  tickFormatFn,
} from '../../../utilities/dashboard';
import { generateSharedPath } from '../../../utilities/routes/links';
import { EMPTY_COST, categoryTitle, removeYear } from '../../../utilities/string';
import DashboardChartPlaceholder from '../../dashboard/DashboardCharts/DashboardChartsPlaceholder';
import ChartLegend from '../../dragon-scales/TimelineCharts/ChartLegend';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import CTALink from '../../shared-widgets/CTALink';
import {
  CHART_BOTTOM_PADDING,
  CHART_RIGHT_PADDING,
} from '../ChartsAllMilestones/ChartsAllMilestonesUtils';
import {
  CHART_HEIGHT,
  allDataFilteredMessage,
  getChartLabelWithCurrency,
  yLabelStyle,
} from '../ChartsUtils';

import styles, { CHART_LEFT_MARGIN } from './ChartsEstimateStyles';

const lineBottomPosition = (y: number) => 0.9999 * y;
const BAR_WIDTH = 0.85;

const getXLabel = (viewBy: string) =>
  removeYear(viewBy).replace('MF ', 'MasterFormat ').replace('UF ', 'UniFormat ');

type ChartsEstimateProps = {
  classes: Classes<typeof styles>;
  clearFilters?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  groupedCategories: any;
  isFiltered?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  legend: any;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
  milestoneId: UUID;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  rounded?: boolean;
  printWidth?: number;
  projectId: UUID;
  viewByName?: string;
  isTableDisplayed?: boolean;
};

const ChartsEstimate: FC<ChartsEstimateProps> = ({
  classes,
  clearFilters = undefined,
  groupedCategories,
  isFiltered = false,
  legend,
  loading = false,
  milestoneId,
  rounded = true,
  printWidth,
  projectId,
  viewByName,
  isTableDisplayed = false,
}) => {
  const navigate = useNavigate();
  const t = useContext(ProjectTermStore);

  const tableData =
    (isTableDisplayed && groupedCategories && getTableData(groupedCategories)) || [];
  // only hover if we are not in print
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const [value, setHoverValue] = useState<any>(null);
  const isPrint = printWidth !== undefined;
  const setValue = !isPrint ? setHoverValue : () => {};
  const [width, setWidth] = useState(printWidth || 400);
  const xLabel = getXLabel(viewByName || 'Category');
  const yLabel = getChartLabelWithCurrency(t.rawTerm(TermKey.ESTIMATE));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const tickData: (i: number) => any = tickFormatFn(groupedCategories, width);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const barValue = (category: any, y: any) => ({
    x: categoryTitle(category),
    y,
    ...cleanCategoryForChart(category, t),
  });

  const bucketCount = (groupedCategories && groupedCategories.length) || 0;
  const chartWidth = width - CHART_LEFT_MARGIN - CHART_RIGHT_PADDING;

  const xLine = (chartWidth / bucketCount) * (BAR_WIDTH / 2);
  const xLineSpacing = xLine - 2;
  const xLineTable =
    (chartWidth / bucketCount) * (bucketCount - 1) + (chartWidth / bucketCount) * 0.925; // to-do: rewrite with BAR_WIDTH constant

  const shouldRotateXLabels =
    groupedCategories &&
    groupedCategories.some((_c: Category, i: number) => !tickData(i) || !tickData(i).name);
  // TODO - variance report specific hovers
  const hint = useMemo(() => {
    if (!value) return null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const bold = (v: any, match: string) =>
      `${v && v.type === match ? classes.bold : ''} ${classes.inline}`;
    const { name, x } = value;
    const estimate = value[CostReportColumnType.ESTIMATE_REPORT] || 0;
    const budget = value[CostReportColumnType.TARGET_REPORT] || 0;
    const accepted = value[CostReportColumnType.ACCEPTED_REPORT] || 0;
    const remaining = value[CostReportColumnType.REMAINING_REPORT] || 0;
    const running = value[CostReportColumnType.RUNNINGTOTAL_REPORT] || 0;

    const costFormatting = { rounded: true, showCurrencySymbol: xLineTable < 10 };

    const estimateText = formatCost(estimate, costFormatting);
    const budgetText = formatCost(budget, costFormatting);
    return (
      <Hint
        align={{
          horizontal: Hint.ALIGN.AUTO,
          vertical: Hint.ALIGN.AUTO,
        }}
        value={value}
      >
        <div className={`${classes.hint} rv-hint__content`}>
          <div className={`${classes.bold} ${classes.category}`}>{x}</div>
          <div className={classes.category}>{name}</div>
          <div className={bold(value, 'estimate')}>
            <div className={classes.rowName}>{`${t.titleCase(TermKey.ESTIMATE)} Total`}</div>
            <div className={classes.spacer} />
            <div className={classes.number} data-cy="cost-summary-estimate">
              {estimate ? estimateText : EMPTY_COST}
            </div>
          </div>
          {accepted !== 0 && (
            <div>
              <div className={bold(value, 'accepted')}>
                <div className={classes.accepted}>Accepted Changes</div>
                <div className={classes.spacer} />
                <div
                  className={`${classes.number} ${classes.accepted}`}
                  data-cy="costSummary-accepted"
                >
                  {formatCost(accepted, costFormatting)}
                </div>
              </div>
              <Divider className={classes.divider} />
              <div className={bold(value, 'running')}>
                <div className={classes.rowName}>{t.titleCase(TermKey.RUNNING_TOTAL)}</div>
                <div className={classes.spacer} />
                <div className={classes.number} data-cy="cost-summary-running-total">
                  {formatCost(running, costFormatting)}
                </div>
              </div>
            </div>
          )}
          {(remaining < 0 || remaining > 0) && remaining !== budget && (
            <div className={bold(value, 'budget')}>
              <div className={`${classes.remaining} ${classes.rowName}`}>
                {t.titleCase(TermKey.GAP)}
              </div>
              <div className={classes.spacer} />
              <div className={`${classes.number} ${classes.remaining}`} data-cy="cost-summary-gap">
                {formatCost(remaining, costFormatting)}
              </div>
            </div>
          )}
          <Divider className={classes.divider} />
          <div className={bold(value, 'budget')}>
            <div className={`${classes.budget} ${classes.rowName}`}>
              {t.titleCase(TermKey.TARGET)}
            </div>
            <div className={classes.spacer} />
            <div className={`${classes.number} ${classes.budget}`} data-cy="cost-summary-budget">
              {budget ? budgetText : EMPTY_COST}
            </div>
          </div>
        </div>
      </Hint>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [classes, rounded, t, value]);

  const emptyState =
    isFiltered || loading ? (
      <DashboardChartPlaceholder
        clearFilters={clearFilters}
        emptyMessage={allDataFilteredMessage}
        loading={loading}
      />
    ) : (
      !isPrint && (
        <CTALink
          linkText="Enter a milestone estimate to see a chart."
          onClick={() => {
            navigate(
              generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                projectId,
                milestoneId,
              })
            );
          }}
        />
      )
    );

  // ZOOM RANGE FOR CATEGORIES, ROTATED X LABEL TEXT
  const RANGE_MIN = 20;
  const RANGE_MAX = 60;
  const MIN_ZOOM = 6 / fontSize.name;
  let rotatedFontZoom = 1;
  const barCount = (groupedCategories && groupedCategories.length) || 0;
  if (barCount > RANGE_MAX) {
    rotatedFontZoom = MIN_ZOOM;
  } else if (barCount > RANGE_MIN) {
    rotatedFontZoom = 1 - ((barCount - RANGE_MIN) / (RANGE_MAX - RANGE_MIN)) * (1 - MIN_ZOOM);
  }

  const horizontalGrid = (
    <HorizontalGridLines
      style={{ strokeWidth: 2, stroke: '#000' }}
      tickTotal={1}
      tickValues={[0]}
    />
  );

  const tableLineTop = (
    <HorizontalGridLines
      style={{ strokeWidth: 1, stroke: theme.palette.primaryGrey }}
      tickTotal={1}
      tickValues={[0]}
      width={xLineTable}
      y="322" // need to have this number to reference a const instead - in regards to CHART_HEIGHT?
    />
  );

  const tableLineBottom = (
    <HorizontalGridLines
      style={{ strokeWidth: 1, stroke: theme.palette.primaryGrey }}
      tickTotal={1}
      tickValues={[0]}
      width={xLineTable}
      y="346"
    />
  );

  const xAxis = (
    <XAxis
      style={{ fill: theme.palette.primaryGrey }}
      tick={isTableDisplayed}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      tickFormat={(_: any, i: any) => {
        const { tooltip, name, description, cost } = tickData(i);
        // if no name, and in print,
        if (shouldRotateXLabels) {
          return (
            <tspan
              style={{
                fontSize: fontSize.name * rotatedFontZoom,
                fontWeight: 400,
              }}
              x="24"
              y="2"
            >
              {truncateLabel(categoryTitle(groupedCategories[i]), 6, true)}
            </tspan>
          );
        }
        return (
          <tspan>
            <title id={tooltip}>{tooltip}</title>
            <tspan
              dy="1em"
              style={{
                fontSize: fontSize.name,
                fontWeight: 400,
              }}
              x="0"
              y="-8"
            >
              {name}
              &nbsp;
            </tspan>
            <tspan
              dy="1em"
              style={{
                fontSize: fontSize.description,
              }}
              x="0"
            >
              {description}
              &nbsp;
            </tspan>
            <tspan
              dy="1em"
              style={{
                fontSize: fontSize.cost,
                ...theme.typography.number,
              }}
              x="0"
            >
              {cost}
            </tspan>
          </tspan>
        );
      }}
      tickLabelAngle={shouldRotateXLabels ? 45 : 0}
      tickSizeInner={0}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      tickValues={groupedCategories && groupedCategories.map((c: any) => categoryTitle(c))}
    />
  );

  const xAxisTable = (
    <XAxis
      style={{ fill: theme.palette.primaryGrey, ticks: { stroke: 'none' } }}
      tick
      tickFormat={(_: string, i: number) => {
        const { description } = tickData(i); // this will not display if too long b/c of tickFormatFn
        const tableLabel = tableData[i];
        const isMinBarCountTableBreakpoint = barCount > PRINT_CHART_BAR_COUNT_BREAKPOINT;
        const categoryName = isMinBarCountTableBreakpoint
          ? truncateLabel(categoryTitle(groupedCategories[i]), 6, true)
          : truncateLabel(categoryTitle(groupedCategories[i]), 10, true);
        const labelTextSize = isMinBarCountTableBreakpoint
          ? fontSizeTable.categorySmall
          : fontSizeTable.categoryRegular;

        // LEFT LABEL ALIGNMENT
        const xLeftLabel = 91; // Enough space to read ACCEPTED CHANGES (originally 87px)
        const xTranslation = (chartWidth / bucketCount) * 0.5 + xLeftLabel; // keeps left label placement consistent
        const xLineAdjust = xLineSpacing + xTranslation;
        const xLabelAdjust = 3; // moving to the right in tspan so Accepted Changes doesn't get cutoff
        const labelTextCutoff = 14;

        // NEW LEFT SIDE LABELS HERE
        // Adjust foreignObject x (negative), tspan x
        return (
          <foreignObject height="400" width={width} x={-xTranslation} y="0">
            <svg height="400" width="600">
              {/* LEFT SIDE LABELS */}
              {i === 0 && (
                <>
                  <line
                    stroke="black"
                    strokeWidth="1"
                    x1="0"
                    x2={chartWidth}
                    y1="4.5em"
                    y2="4.5em"
                  />
                  <line stroke="black" strokeWidth="1" x1="0" x2={chartWidth} y1="6em" y2="6em" />
                  <text x="0" y="3em">
                    <tspan
                      className={classes.estimate}
                      dy="1em"
                      style={{
                        fontSize: fontSizeTable.estimate,
                        fontWeight: 400,
                        textAnchor: 'end',
                      }}
                      x={xLeftLabel + xLabelAdjust} // modify here (tspan) for x, not text
                    >
                      {truncateLabel(
                        `${t.titleCase(TermKey.ESTIMATE)} Total`,
                        labelTextCutoff,
                        true,
                        true
                      )}
                    </tspan>
                    <tspan
                      className={classes.accepted}
                      dy="1em"
                      style={{
                        fontSize: fontSizeTable.accepted,
                        fontWeight: 400,
                        textAnchor: 'end',
                        fill: theme.palette.accepted,
                      }}
                      x={xLeftLabel + xLabelAdjust}
                    >
                      Accepted Changes
                    </tspan>
                  </text>
                  <text x="0" y="4.5em">
                    <tspan
                      dy="1em"
                      style={{
                        fontSize: fontSizeTable.running,
                        fontWeight: 'bold',
                        textAnchor: 'end',
                      }}
                      x={xLeftLabel + xLabelAdjust}
                    >
                      {truncateLabel(
                        `${t.titleCase(TermKey.RUNNING_TOTAL)}`,
                        labelTextCutoff,
                        true,
                        true
                      )}
                    </tspan>
                    <tspan
                      dy="1em"
                      style={{
                        fontSize: fontSizeTable.remaining,
                        fontWeight: 400,
                        textAnchor: 'end',
                        fill: theme.palette.shadedGrey,
                      }}
                      x={xLeftLabel + xLabelAdjust}
                    >
                      {truncateLabel(`${t.titleCase(TermKey.GAP)}`, labelTextCutoff, true, true)}
                    </tspan>
                  </text>
                  <text x="0" y="6em">
                    <tspan
                      dy="1em"
                      style={{
                        fontSize: fontSizeTable.budget,
                        fontWeight: 400,
                        textAnchor: 'end',
                        fill: theme.palette.budget,
                      }}
                      x={xLeftLabel + xLabelAdjust}
                    >
                      {truncateLabel(`${t.titleCase(TermKey.TARGET)}`, labelTextCutoff, true, true)}
                    </tspan>
                  </text>
                </>
              )}
              {/* TABLE CONSTRUCTION */}
              <line
                stroke="black"
                strokeWidth="1"
                x1={xLine + xTranslation} // without the side margin
                x2={xLine + xTranslation}
                y1="0"
                y2="110"
              />
              <text x="200" y="10">
                <tspan
                  dy="1em"
                  style={{
                    fontSize: labelTextSize,
                    fontWeight: 400,
                    textAnchor: 'end',
                  }}
                  x={xLineAdjust}
                >
                  {categoryName}
                </tspan>
                <tspan
                  dy="1em"
                  style={{
                    fontSize: fontSizeTable.description,
                    fontWeight: 400,
                    textAnchor: 'end',
                  }}
                  x={xLineAdjust}
                >
                  {description}
                </tspan>
              </text>
              <text x="200" y="3em">
                <tspan
                  dy="1em"
                  style={{
                    fontSize: fontSizeTable.estimate,
                    fontWeight: 400,
                    textAnchor: 'end',
                  }}
                  x={xLineAdjust}
                >
                  {tableLabel[CostReportColumnType.ESTIMATE_REPORT]}
                </tspan>
                <tspan
                  className={classes.accepted}
                  dy="1em"
                  style={{
                    fontSize: fontSizeTable.accepted,
                    fill: theme.palette.accepted,
                    fontWeight: 400,
                    textAnchor: 'end',
                  }}
                  x={xLineAdjust}
                >
                  {tableLabel[CostReportColumnType.ACCEPTED_REPORT]}
                </tspan>
              </text>
              <text x="400" y="4.5em">
                <tspan
                  dy="1em"
                  style={{
                    fontSize: fontSizeTable.running,
                    fontWeight: 'bold', // Get it to reference RunningTotal classes styling directly
                    textAnchor: 'end',
                  }}
                  x={xLineAdjust}
                >
                  {tableLabel[CostReportColumnType.RUNNINGTOTAL_REPORT]}
                  {/* need to check double-check this logic for edge cases */}
                  {/* {(remaining < 0 || remaining > 0) && remaining !== budget ? running : EMPTY_COST} */}
                </tspan>
                <tspan
                  dy="1em"
                  style={{
                    fontSize: fontSizeTable.remaining,
                    fill: theme.palette.shadedGrey,
                    fontWeight: 400,
                    textAnchor: 'end',
                  }}
                  x={xLineAdjust}
                >
                  {tableLabel[CostReportColumnType.REMAINING_REPORT]}
                </tspan>
              </text>
              <text x="400" y="6em">
                <tspan
                  dy="1em"
                  style={{
                    fontSize: fontSizeTable.budget,
                    fill: theme.palette.budget,
                    fontWeight: 400,
                    textAnchor: 'end',
                  }}
                  x={xLineAdjust}
                >
                  {tableLabel[CostReportColumnType.TARGET_REPORT]}
                </tspan>
              </text>
            </svg>
          </foreignObject>
        );
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      tickValues={groupedCategories && groupedCategories.map((c: any) => categoryTitle(c))}
    />
  );

  const yAxis = (
    <YAxis
      style={{
        fill: theme.palette.primaryGrey,
        ...theme.typography.number,
        fontSize: fontSize.cost,
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      tickFormat={(v: any) => formatCost(v * 100, { short: true, showCurrencySymbol: false })}
      tickTotal={6}
    />
  );

  const xAxisChartLabel = (
    <ChartLabel
      className={classes.axisLabel}
      includeMargin={false}
      style={{
        y: 80,
        textAnchor: 'middle',
      }}
      text={xLabel}
      xPercent={0.5}
      yPercent={1}
    />
  );

  const xAxisChartLabelTable = (
    <ChartLabel
      className={classes.axisLabel}
      includeMargin={false}
      style={{
        y: '9em',
        textAnchor: 'middle',
      }}
      text={xLabel}
      xPercent={0.5}
      yPercent={1}
    />
  );

  const yAxisChartLabel = (
    <ChartLabel
      className={classes.axisLabel}
      includeMargin={false}
      style={yLabelStyle}
      text={yLabel}
      x="100px"
      xPercent={-0}
      yPercent={0.5}
    />
  );

  // DISPLAY TABLE VIEW
  const displayXChartLabel = isTableDisplayed ? xAxisChartLabelTable : xAxisChartLabel;
  const displayXAxis = isTableDisplayed ? xAxisTable : xAxis;
  const displayHint = isTableDisplayed ? null : hint;
  const chartYRange = isTableDisplayed ? [240, 20] : [290, 20];
  const marginBottom = isTableDisplayed ? 140 : CHART_BOTTOM_PADDING;
  const displayTableLineTop = isTableDisplayed ? tableLineTop : null;
  const displayTableLineBottom = isTableDisplayed ? tableLineBottom : null;

  return (
    <div className={classes.root}>
      {groupedCategories && groupedCategories.length > 0 && !loading ? (
        <div
          ref={(r) => {
            if (r && r.getBoundingClientRect()) {
              const w = r.getBoundingClientRect().width;
              if (!width || (width !== w && !isPrint)) {
                setWidth(w);
              }
            }
          }}
          data-cy="ChartsEstimate"
        >
          <div className={classes.legendContainer}>
            <ChartLegend legendLabels={legend} />
          </div>
          <FlexibleXYPlot
            className={classes.graph}
            height={CHART_HEIGHT}
            margin={{
              left: CHART_LEFT_MARGIN,
              bottom: marginBottom,
              right: CHART_RIGHT_PADDING,
            }}
            onMouseLeave={() => setValue(null)}
            stackBy="y"
            width={width}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            xDomain={groupedCategories.map((x: any) => categoryTitle(x))}
            xPadding={60}
            xType="ordinal"
            yBaseValue={0}
            yRange={chartYRange}
          >
            {/* <VerticalGridLines /> */}
            <HorizontalGridLines />
            {horizontalGrid}
            {yAxis}
            {yAxisChartLabel}
            {displayXChartLabel}
            {displayXAxis}
            {displayTableLineTop}
            {displayTableLineBottom}
            {/* The non-stacking "bars" are the lines */}
            <VerticalBarSeries
              className={classes.estimate}
              data={groupedCategories
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .map((category: any) => {
                  const estimateTotal = category[CostReportColumnType.ESTIMATE_REPORT] || 0;
                  const y = simpleCost(estimateTotal);
                  if (Number.isNaN(y)) return null;
                  return {
                    x: categoryTitle(category),
                    y,
                    y0: lineBottomPosition(y),
                    style: { ...theme.typography.number },
                  };
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .filter((c: any) => !!c)}
              stack={false}
            />
            <VerticalBarSeries
              className={classes.running}
              data={groupedCategories
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .map((category: any) => {
                  const runningTotal = category[CostReportColumnType.RUNNINGTOTAL_REPORT] || 0;
                  const estimateTotal = category[CostReportColumnType.ESTIMATE_REPORT] || 0;
                  if (estimateTotal !== runningTotal) {
                    const v = simpleCost(runningTotal);
                    if (Number.isNaN(v)) return null;
                    return {
                      x: categoryTitle(category),
                      y: v,
                      y0: lineBottomPosition(v),
                    };
                  }
                  return null;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .filter((c: any) => !!c)}
              stack={false}
            />
            {/* Hover volumes with unique Hints states */}
            <VerticalBarSeries
              className={classes.remaining}
              data={groupedCategories
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .map((category: any) => {
                  const runningTotal = category[CostReportColumnType.RUNNINGTOTAL_REPORT] || 0;
                  const estimateTotal = category[CostReportColumnType.ESTIMATE_REPORT] || 0;
                  if (estimateTotal !== 0) {
                    let y = runningTotal;
                    let type = 'running';
                    if (runningTotal >= estimateTotal) {
                      y = estimateTotal;
                      type = 'estimate';
                    }
                    return {
                      ...barValue(category, simpleCost(y)),
                      type,
                    };
                  }
                  return null;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .filter((c: any) => !!c)}
              onValueMouseOut={() => setValue(null)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              onValueMouseOver={(v: any) => setValue(v)}
              stack
            />
            <VerticalBarSeries
              className={classes.remaining}
              colorType="literal"
              data={groupedCategories
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .map((category: any) => {
                  const runningTotal = category[CostReportColumnType.RUNNINGTOTAL_REPORT] || 0;
                  const estimateTotal = category[CostReportColumnType.ESTIMATE_REPORT] || 0;
                  if (runningTotal < estimateTotal) {
                    const y = estimateTotal - runningTotal;
                    return {
                      ...barValue(category, simpleCost(y)),
                      type: 'accepted',
                    };
                  }
                  return null;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .filter((c: any) => !!c)}
              onValueMouseOut={() => setValue(null)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              onValueMouseOver={(v: any) => setValue(v)}
              stack
            />
            <VerticalBarSeries
              className={classes.change}
              colorType="literal"
              data={groupedCategories
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .map((category: any) => {
                  const runningTotal = category[CostReportColumnType.RUNNINGTOTAL_REPORT] || 0;
                  const estimateTotal = category[CostReportColumnType.ESTIMATE_REPORT] || 0;
                  if (runningTotal >= estimateTotal) {
                    const y = runningTotal - estimateTotal;
                    return {
                      ...barValue(category, simpleCost(y)),
                      type: 'accepted',
                    };
                  }
                  return null;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .filter((c: any) => !!c)}
              onValueMouseOut={() => setValue(null)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              onValueMouseOver={(v: any) => setValue(v)}
              stack
            />
            <VerticalBarSeries
              className={classes.budget}
              data={groupedCategories
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .map((category: any) => {
                  const budgetTotal = category[CostReportColumnType.TARGET_REPORT] || 0;
                  if (budgetTotal > 0) {
                    const y = simpleCost(budgetTotal);
                    if (Number.isNaN(y)) return null;
                    return {
                      ...barValue(category, y),
                      type: 'budget',
                      y0: lineBottomPosition(y),
                    };
                  }
                  return null;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .filter((c: any) => !!c)}
              onValueMouseOut={() => setValue(null)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              onValueMouseOver={(v: any) => setValue(v)}
              stack={false}
            />
            <LabelSeries
              className={classes.icon}
              data={groupedCategories
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .map((category: any) => {
                  const runningTotal = category[CostReportColumnType.RUNNINGTOTAL_REPORT] || 0;
                  const estimateTotal = category[CostReportColumnType.ESTIMATE_REPORT] || 0;
                  if (runningTotal !== estimateTotal) {
                    return {
                      x: categoryTitle(category),
                      y: simpleCost((runningTotal + estimateTotal) / 2),
                      v: runningTotal > estimateTotal,
                    };
                  }
                  return null;
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                .filter((c: any) => !!c)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              getLabel={(d: any) => (d.v ? '↑' : '↓')}
              labelAnchorX="middle"
              labelAnchorY="middle"
              stack={false}
            />
            {displayHint}
          </FlexibleXYPlot>
        </div>
      ) : (
        emptyState
      )}
    </div>
  );
};

export default withStyles(styles)(ChartsEstimate);
