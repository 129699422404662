import { useMemo, useState } from 'react';

import { UserStatus } from '../../../api/gqlEnumsBe';
import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import { useCompanyUsersQuery } from '../../CompanyTab/useCompanyUsersQuery';

export const useSearchForecastingUsers = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const companyUserQueryResult = useCompanyUserQuery();
  const companyID = companyUserQueryResult.data?.companyUser?.company?.id;

  const companyUsersQueryResult = useCompanyUsersQuery({
    variables: { companyID },
    skip: !companyID,
  });
  const usersLoading = companyUsersQueryResult.loading || companyUserQueryResult.loading;
  const companyUsers = companyUsersQueryResult.data?.companyUsers;

  const { displayCompanyUsers, forecastingUsersCount } = useMemo(() => {
    const forecastingUsers = (companyUsers ?? [])
      .filter((cu) => cu.role?.name !== 'Basic')
      .filter((cu) => cu.user && cu.user?.status !== UserStatus.DEACTIVATED);
    const forecastingUsersCount = forecastingUsers.length;

    if (searchTerm.length === 0)
      return { forecastingUsersCount, displayCompanyUsers: forecastingUsers };

    const generateFilterText = (cu: CompanyUser) =>
      [
        (cu.user?.name ?? '').toLocaleLowerCase(),
        (cu.user?.jobTitle ?? '').toLocaleLowerCase(),
        (cu.role?.name ?? '').toLocaleLowerCase(),
      ].join(' ');

    const displayCompanyUsers = forecastingUsers
      .slice()
      .filter((c: CompanyUser) => generateFilterText(c).includes(searchTerm.toLocaleLowerCase()));
    return { forecastingUsersCount, displayCompanyUsers };
  }, [companyUsers, searchTerm]);

  return { displayCompanyUsers, forecastingUsersCount, usersLoading, setSearchTerm, searchTerm };
};
