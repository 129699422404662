import {
  GetProjectPropsQuery,
  GetProjectPropsQueryVariables,
  ProgramQuery,
  ProgramQueryVariables,
} from '../../../../generated/graphql';
import { getProjectPropsQuery } from '../../../../hooks/queries';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { programQuery } from '../../../ProgramReporting/queries';

export function useProjectOrProgramQuery(projectID?: UUID, programID?: UUID) {
  const query = projectID ? getProjectPropsQuery : programQuery;
  const opts = projectID
    ? { variables: { id: projectID }, skip: !projectID }
    : { variables: { programID }, skip: !programID };

  return useQuery<
    GetProjectPropsQuery | ProgramQuery,
    GetProjectPropsQueryVariables | ProgramQueryVariables
  >(query, opts);
}
