import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

export default function CostYAxis({
  tickFormat,
  tickTotal,
  x,
  y,
  showHorizontalLines,
}: {
  tickFormat: (value: number) => string | null;
  tickTotal: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showHorizontalLines?: boolean;
}) {
  const ticks = y.ticks(tickTotal).map((value) => ({
    value,
    yOffset: y(value),
  }));

  return (
    <svg>
      {ticks.map(({ value, yOffset }) => (
        <g key={`y-tick-${value}`} transform={`translate(0, ${yOffset})`}>
          {showHorizontalLines && (
            <line
              className="stroke-chart-axis"
              strokeWidth={2}
              x1={x.range()[0]}
              x2={x.range()[1]}
            />
          )}
          <text key={`${value}`} className="type-body2">
            {tickFormat(value)}
          </text>
        </g>
      ))}
    </svg>
  );
}
