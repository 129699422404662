import UserAvatar from '../../../Collaborators/UserAvatar';
import { useCurrentUser } from '../../../contexts/current-user';
import SearchText from '../../../Search/SearchText/SearchText';
import TableSelectable from '../../TableSelectable/TableSelectable';

import { getValueOrEmpty, useSortCompanyUsers } from './useSortCompanyUsers';
import { CompanyUserSortKey, useSortUserManager } from './useSortUserManager';

type Props = {
  companyUsers: CompanyUser[];
  isLoading: boolean;
  searchTerm: string;
  selectedUserIDs: UUID[];
  setSelectedUserIDs: (userIDs: UUID[]) => void;
};

const usersTableGridWidth = [
  '48px',
  'minmax(160px, 3fr)',
  'minmax(140px, 2.5fr)',
  'minmax(140px, 2.5fr)',
];

const membersHeaderContent = [
  {
    headerSortKey: null,
    key: CompanyUserSortKey.AVATAR,
  },
  {
    copy: CompanyUserSortKey.NAME,
    headerSortKey: CompanyUserSortKey.NAME,
    key: CompanyUserSortKey.NAME,
  },
  {
    copy: CompanyUserSortKey.JOB_TITLE,
    headerSortKey: CompanyUserSortKey.JOB_TITLE,
    key: CompanyUserSortKey.JOB_TITLE,
  },
  {
    copy: CompanyUserSortKey.COMPANY_ROLE,
    headerSortKey: CompanyUserSortKey.COMPANY_ROLE,
    key: CompanyUserSortKey.COMPANY_ROLE,
  },
];

export default function CompanyUserTable(props: Props) {
  const currentUserID = useCurrentUser().id;
  const sortManager = useSortUserManager();
  const sortedCompanyUsers = useSortCompanyUsers(props.companyUsers, sortManager.sortState);

  return (
    <TableSelectable
      columnWidths={usersTableGridWidth}
      disabledIndices={sortedCompanyUsers.map((cu) => cu.user?.id === currentUserID)}
      entries={sortedCompanyUsers.map((cu) => [
        {
          component: <UserAvatar assignee={cu.user} />,
          key: CompanyUserSortKey.AVATAR,
        },
        {
          component: (
            <div data-cy="company-user-name-text-input">
              <SearchText searchTerm={props.searchTerm} text={getValueOrEmpty(cu.user?.name)} />
            </div>
          ),
          key: CompanyUserSortKey.NAME,
        },
        {
          component: (
            <div data-cy="company-user-job-title-text-input">
              <SearchText searchTerm={props.searchTerm} text={getValueOrEmpty(cu.user?.jobTitle)} />
            </div>
          ),
          key: CompanyUserSortKey.JOB_TITLE,
        },
        {
          component: (
            <div data-cy="company-user-role-name">
              <SearchText searchTerm={props.searchTerm} text={getValueOrEmpty(cu.role?.name)} />
            </div>
          ),
          key: CompanyUserSortKey.COMPANY_ROLE,
        },
      ])}
      headerContent={membersHeaderContent}
      indeterminateIndices={[]}
      loading={props.isLoading}
      onSelectAll={() => {
        const allUserIDs: UUID[] = [];
        sortedCompanyUsers.forEach((cu) => {
          if (cu.user) allUserIDs.push(cu.user.id);
        });
        // always include currentUser, even if filtered
        if (!allUserIDs.includes(currentUserID)) allUserIDs.push(currentUserID);
        props.setSelectedUserIDs(allUserIDs);
      }}
      onToggleEntry={(index: number) => {
        const newSelectedUserIDs = props.selectedUserIDs.slice();
        const userID = sortedCompanyUsers[index].user?.id;
        if (userID) {
          if (newSelectedUserIDs.includes(userID)) {
            newSelectedUserIDs.splice(newSelectedUserIDs.indexOf(userID), 1);
          } else {
            newSelectedUserIDs.push(userID);
          }
        }
        props.setSelectedUserIDs(newSelectedUserIDs);
      }}
      onUnselectAll={() => {
        props.setSelectedUserIDs([currentUserID]);
      }}
      selectableIndices={sortedCompanyUsers.map((cu) => !!cu.user)}
      selectedIndices={sortedCompanyUsers.map((cu) => {
        const userID = cu.user?.id;
        if (!userID || !props.selectedUserIDs) return false;
        return props.selectedUserIDs.includes(userID);
      })}
      sortManager={sortManager}
    />
  );
}
