import { ProgramFieldsFragment, ResourceType } from '../../../generated/graphql';
import { useGetSharedResource } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import { useProgramCollaboratorsQuery } from '../ProgramDialog/hooks/useProgramCollaboratorsQuery';

import ProgramUsersDialog from './ProgramUsersDialog';

type Props = {
  isOpen: boolean;
  program: ProgramFieldsFragment;
  setIsOpen: (value: boolean) => void;
};

export default function ProgramUsersDialogData(props: Props) {
  const { data, loading: loadingCollaborators } = useProgramCollaboratorsQuery(props.program.id);

  const { data: sharedResourceData } = useGetSharedResource(props.program.id, ResourceType.PROGRAM);
  const resource = sharedResourceData?.getSharedResource?.resource;

  if (loadingCollaborators || !resource) return null;

  return (
    <ProgramUsersDialog
      collaborators={data?.programCollaborators ?? []}
      isOpen={props.isOpen}
      program={props.program}
      resource={resource}
      setIsOpen={props.setIsOpen}
    />
  );
}
