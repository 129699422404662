import React from 'react';

import { Tooltip } from '../../../scales';

import ChartsPieGraphGradient from './ChartsPieGraphGradient';
import ChartsPieGraphLabels from './ChartsPieGraphLabels';
import ChartsPieGraphPaths from './ChartsPieGraphPaths';
import { CenterLabel, PieChartData, PieChartOptions, PieChartSize } from './ChartsPieGraphUtils';
import TooltipPieSegment from './TooltipPieSegment';
import { chartColorUnassigned, getColor, pieChartDefaultColors } from './utils';

type Props = {
  centerLabel?: CenterLabel;
  chartSize: PieChartSize;
  colors?: string[];
  data: PieChartData[];
  dataOther?: PieChartData[]; // Represents data that is combined into "Other" entry (data from this is used for tooltips)
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  displayLegendTooltip?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  displaySectionTooltip?: boolean;
  headerTitle?: string;
  isCurrency?: boolean;
  labelStyle?: string;
  options?: PieChartOptions;
  onSliceClick?: (name: string) => void;
  onSliceTooltip?: () => void;
  onSummaryTooltip?: () => void;
  title?: string;
};

const PieChart: React.FC<Props> = ({
  centerLabel,
  chartSize,
  colors = pieChartDefaultColors, // Default color scheme for first 20 entries
  data,
  dataOther,
  displayLegendTooltip = false,
  displaySectionTooltip = false,
  headerTitle,
  isCurrency = false,
  labelStyle,
  onSliceClick,
  onSliceTooltip,
  onSummaryTooltip,
  options,
  title,
}) => {
  if (!data) return null;

  const shareSum = data.reduce((acc, curr) => acc + curr.share, 0);
  const { diameter, insideDiameter } = chartSize;
  const nonZeroData = data.filter((item) => item.share > 0);

  const renderCircle = (
    fill: string,
    data?: PieChartData[],
    onSliceClick?: (name: string) => void
  ) => {
    // Use the first item in data as tooltipData, if available
    const tooltipData = data?.[0];
    const totalShare = tooltipData?.share || 0;

    return (
      <Tooltip
        key={tooltipData?.name || ''}
        content={
          <TooltipPieSegment
            fill={chartColorUnassigned}
            isCurrency={isCurrency}
            name={tooltipData?.name || ''}
            share={tooltipData?.share || 0}
            totalShare={totalShare}
          />
        }
        isDisabled={!displaySectionTooltip || !data}
        placement="bottom"
      >
        <circle
          cx="50%"
          cy="50%"
          fill={fill}
          onClick={() => tooltipData?.name && onSliceClick?.(tooltipData.name)}
          r={diameter / 2}
          style={{ cursor: onSliceClick ? 'pointer' : 'default' }}
        />
      </Tooltip>
    );
  };

  const renderInnerCircle = () =>
    insideDiameter &&
    insideDiameter > 0 && (
      <circle className="fill-background-primary" cx="50%" cy="50%" r={insideDiameter / 2} />
    );

  if (shareSum === 0) {
    return (
      <div className="flex flex-col" style={{ width: diameter }}>
        {headerTitle && (
          <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap pb-1 pl-1 type-label">
            {headerTitle}
          </div>
        )}
        <div style={{ position: 'relative' }}>
          <svg height={diameter} width={diameter}>
            {renderCircle(chartColorUnassigned)}
            {renderInnerCircle()}
            <ChartsPieGraphLabels
              centerLabel={centerLabel}
              chartSize={chartSize}
              colors={colors}
              data={nonZeroData}
              displayLegendTooltip={displayLegendTooltip}
              isCurrency={isCurrency}
              labelStyle={labelStyle}
              title={title}
            />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col" style={{ width: diameter }}>
      {headerTitle && (
        <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap pb-1 pl-1 type-label">
          {headerTitle}
        </div>
      )}
      <div style={{ position: 'relative' }}>
        <svg height={diameter} width={diameter}>
          {nonZeroData.length > 1 ? (
            <ChartsPieGraphPaths
              chartSize={chartSize}
              colors={colors}
              data={nonZeroData}
              dataOther={dataOther}
              displaySectionTooltip={displaySectionTooltip}
              isCurrency={isCurrency}
              onSliceClick={onSliceClick}
              onTooltip={onSliceTooltip}
              options={options}
            />
          ) : (
            renderCircle(getColor(colors, nonZeroData[0], 0), nonZeroData, onSliceClick)
          )}
          <ChartsPieGraphGradient chartSize={chartSize} options={options} />
          {renderInnerCircle()}
          <ChartsPieGraphLabels
            centerLabel={centerLabel}
            chartSize={chartSize}
            colors={colors}
            data={nonZeroData}
            displayLegendTooltip={displayLegendTooltip}
            isCurrency={isCurrency}
            labelStyle={labelStyle}
            onTooltip={onSummaryTooltip}
            title={title}
          />
        </svg>
      </div>
    </div>
  );
};

export default PieChart;
