import { Thumbnail } from '../dragon-scales';

type Props = {
  projectCount: number;
  projectThumbnail: string;
  size?: 'sm' | 'md' | 'lg';
};
// Sizes:
// sm = 40
// md = 62
// lg = 70

export default function ProgramThumbnail({ projectThumbnail, projectCount, size = 'md' }: Props) {
  let rootStyle = '';
  let thumbnailSize = 0;
  let bottomOffsetStyle = '';
  let countOffset = '';
  let rotatedThumbnailStyle = '';
  switch (size) {
    case 'sm':
      rootStyle = 'h-[42px] w-[42px]';
      bottomOffsetStyle = 'bottom-[32px] ';
      rotatedThumbnailStyle = 'right-2';
      thumbnailSize = 32;
      break;
    case 'md':
      rootStyle = 'h-[62px] w-[62px]';
      bottomOffsetStyle = 'bottom-[45px] left-[5px]';
      countOffset = 'bottom-[105px] left-[45px]';
      rotatedThumbnailStyle = 'top-[5px] right-1';
      thumbnailSize = 50;
      break;
    default:
      rootStyle = 'h-[72px] w-[72px]';
      bottomOffsetStyle = 'bottom-[70px] ';
      countOffset = 'bottom-[150px] left-[65px]';
      rotatedThumbnailStyle = 'right-2';
      thumbnailSize = 70;
      break;
  }

  return (
    <div className={rootStyle}>
      <div
        className={`relative z-0 -rotate-12 border border-background-primary ${rotatedThumbnailStyle}`}
      >
        <Thumbnail size={thumbnailSize} thumbnail={projectThumbnail} />
      </div>
      <div className={`relative left-1 z-10 border border-background-primary ${bottomOffsetStyle}`}>
        <Thumbnail size={thumbnailSize} thumbnail={projectThumbnail} />
      </div>
      {size !== 'sm' && (
        <div
          className={`relative z-20 flex min-h-[20px] w-5 flex-col justify-center rounded-[10px] bg-background-1 text-center type-body3 ${countOffset}`}
        >
          {projectCount}
        </div>
      )}
    </div>
  );
}
