import { ReactNode } from 'react';

import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { Thumbnail } from '..';
import { JoinRoutes } from '../../../api/gqlEnums';
import {
  HOME_PROJECT_LOCATION,
  HOME_PROJECT_NAME,
  HOME_PROJECT_STATUS,
  HOME_PROJECT_TYPE,
} from '../../../tagConstants';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import { Chip, Chips, Icon, Tooltip } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';
import ProjectLink from '../ProjectLink';

import ClampedContent from './ClampedContent';
import { Project } from './types';
import { CHIP_LABEL_LENGTH_LIMIT, getTruncatedText } from './utils';

type Props = {
  isSelected?: boolean;
  programName?: string;
  project: Project;
  searchText: string;
};

export default function ProjectCell(props: Props) {
  const chips = getChips(props.project, props.programName);
  return (
    <div
      className={`flex items-center gap-5 py-2 ${props.isSelected ? 'bg-selection-selected' : ''} ${props.programName ? 'ml-24' : ''}`}
    >
      <Thumbnail size={72} thumbnail={props.project.thumbnail} />
      <div key={`${props.project.id}-${props.programName}`} className="flex flex-col gap-2">
        <ProjectLink
          hasAccess={props.project.hasAccess ?? false}
          projectName={props.project.name}
          to={generateSharedPath(JoinRoutes.PROJECT, { projectId: props.project.id })}
        >
          <div
            className="type-heading2"
            data-cy={`${HOME_PROJECT_NAME}-${props.project.name}`}
            data-project-id={props.project.id}
          >
            <SearchText searchTerm={props.searchText} text={props.project.name} />
          </div>
        </ProjectLink>
        <ClampedContent toolTipEntries={chips.map((c) => c.label)}>
          <Chips>
            {chips.map((chip) => (
              <Tooltip
                key={`project-${props.project.id}-${chip.label}-${props.programName}`}
                content={chip.label}
                isDisabled={chip.label.length <= CHIP_LABEL_LENGTH_LIMIT}
              >
                <Chip
                  data-cy={`${chip['data-cy']}-${props.project.name}-${chip.label}`}
                  icon={chip.icon}
                  text={
                    <SearchText searchTerm={props.searchText} text={getTruncatedText(chip.label)} />
                  }
                />
              </Tooltip>
            ))}
          </Chips>
        </ClampedContent>
      </div>
    </div>
  );
}

type ChipDefinition = {
  'data-cy'?: string;
  label: string;
  icon: ReactNode;
};

const getChips = (project: Project, programName?: string) => {
  const chips: ChipDefinition[] = [
    {
      'data-cy': HOME_PROJECT_TYPE,
      label: project.type,
      icon: <BusinessOutlined />,
    },
    {
      'data-cy': HOME_PROJECT_STATUS,
      label: project.status,
      icon: null,
    },
    {
      'data-cy': HOME_PROJECT_LOCATION,
      label: project.location,
      icon: <LocationOnOutlined />,
    },
  ];

  if (programName)
    chips.unshift({
      label: programName,
      icon: <Icon name="program" size="sm" />,
    });

  if (project.milestoneGSF) {
    chips.push({
      label: `${formatNumber(project.milestoneGSF)} GSF`,
      icon: <Icon name="straighten" />,
    });
  }

  if (project.projectLeadName)
    chips.push({
      label: project.projectLeadName ?? undefined,
      icon: <img alt="project lead icon" src="/img/GroupsOutlined.svg" width={16} />,
    });

  // add org nodes
  project.orgNodes
    .flatMap((o) => (o ? [o] : []))
    .forEach((node) =>
      chips.push({
        label: node.name,
        icon: <Icon name="group_by" size="sm" />,
      })
    );
  return chips;
};
