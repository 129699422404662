import { IdeaQuery, IdeaQueryVariables } from '../../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../../hooks/useMountAwareQuery';

import { ideaQuery } from './queries';

export default function useIdeaQuery(
  projectID: UUID,
  ideaID: UUID,
  options?: QueryHookOptions<IdeaQuery, IdeaQueryVariables>
) {
  return useQuery<IdeaQuery, IdeaQueryVariables>(ideaQuery, {
    skip: !projectID || !ideaID,
    ...options,
    variables: { projectID, ideaID },
  });
}
