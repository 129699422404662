import { getStatusColor } from '../CompanyTabUtils';

export default function StatusColorDot(props: { status: UserStatus }) {
  return (
    <div
      className="h-4 w-4 shrink-0 rounded-full"
      style={{ backgroundColor: getStatusColor(props.status) }}
    />
  );
}
