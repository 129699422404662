import { mountedProgramsVar } from '../../../api/apollo/reactiveVars';
import { ProgramCostsQuery, ProgramCostsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { programCostsQuery } from '../queries';

export const useProgramCostsQuery = (includedProjectIDs: UUID[], programID: UUID | undefined) =>
  useQuery<ProgramCostsQuery, ProgramCostsQueryVariables>(
    programCostsQuery,
    {
      variables: { includedProjectIDs, programID },
      skip: !programID,
      context: { batch: true },
    },
    programID && mountedProgramsVar().get(programID)
      ? MountPolicy.SKIP_ON_MOUNT
      : MountPolicy.FETCH_ON_MOUNT
  );
