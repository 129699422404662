import ProgramDashboard from './ProgramDashboard';
import useProgramDashboardHooks from './ProgramDialog/hooks/useProgramDashboardHooks';

type Props = {
  programID: UUID;
};

export default function ProgramDashboardData({ programID }: Props) {
  const {
    programCosts,
    trendline,
    breakdowns,
    program,
    programCategorizations,
    selectedProjectIDs,
    setSelectedProjectIDs,
  } = useProgramDashboardHooks(programID);

  return (
    <ProgramDashboard
      chartData={trendline}
      isPrinting={false}
      program={program}
      programBreakdown={breakdowns?.program ?? []}
      programCategorizations={programCategorizations ?? []}
      programCosts={programCosts}
      projectBreakdown={breakdowns?.projects ?? []}
      selectedProjectIDs={selectedProjectIDs}
      setSelectedProjectIDs={setSelectedProjectIDs}
      totalProgramCost={breakdowns?.total ?? 0}
    />
  );
}
