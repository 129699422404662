import * as d3 from 'd3';

import { DEFAULT_MARGIN } from '../../../../constants';
import { formatCost } from '../../../../utilities/currency';
import { isNonNullable } from '../../../../utilities/types';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import useMemoWrapper from '../../../useMemoWrapper';
import CostLabelBlur from '../ChartsCostTrendline/CostLabelBlur';
import InsightsEmptyChart from '../InsightsCost/InsightsEmptyChart';
import { getYDomainPadded, isNonNullUSCents } from '../InsightsCost/utils';
import TimelineArea from '../TimelineArea';
import TimelineHoverSections from '../TimelineHoverSections';
import TimelineMultiTooltip from '../TimelineMultiTooltip/TimelineMultiTooltip';
import TimelinePath from '../TimelinePath';

import ContingenciesFillPattern from './ContingenciesFillPattern';
import { ContingenciesTimeSeries } from './types';
import { getAreaDataAllow, getAreaDataCont, getYDomain } from './utils';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  data: ContingenciesTimeSeries[];
  height: number;
  hoverDate?: Date | undefined;
  hasAllowance: boolean;
  hasContingency: boolean;
  isPrint?: boolean;
  setHoverDate: (date: Date | undefined) => void;
  today: Date;
  totalRange: [Date, Date];
};

export default function InsightsContingencies(props: Props) {
  const {
    data,
    height,
    hoverDate,
    hasAllowance,
    hasContingency,
    isPrint,
    setHoverDate,
    today,
    totalRange,
  } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = DEFAULT_MARGIN;

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, data, hasAllowance, hasContingency);
  // Add vertical padding
  const { yDomain, yMin, yMax } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  // Get area data separated
  const pendingContingencies = useMemoWrapper(getAreaDataCont, data);
  const pendingAllowances = useMemoWrapper(getAreaDataAllow, data);
  // Last data index, the first point for horizontal lines after today
  const lastIndex = data.length - 1;

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const bounds = {
    right: x(xMax),
    left: x(xMin),
    top: y(yMin),
    bottom: y(yMax),
  };

  // Hover index for tooltips
  const hoverIndex = data.findIndex(({ date }) => date.getTime() === hoverDate?.getTime());
  const hoverCA = data[hoverIndex];
  const hasContingencyValue = isNonNullUSCents(hoverCA?.contingencyRemaining);
  const hasAllowanceValue = isNonNullUSCents(hoverCA?.allowanceRemaining);

  if (!data.length || !data[0]) {
    return <InsightsEmptyChart height={height} today={today} totalRange={totalRange} />;
  }

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {/* Hover Line */}
      {hoverDate && (
        <TimelinePath<{ value: number }>
          // current hover date
          data={[
            { date: hoverDate, value: yMin },
            { date: hoverDate, value: yMax },
          ]}
          stroke="stroke-chart-axis"
          strokeWidth={1.5}
          x={x}
          y={y}
        />
      )}
      {hasContingency && (
        <>
          {/* Pending Area Contingencies */}
          <TimelineArea<{ y0: number; y: number }, { x: Date }>
            data={pendingContingencies}
            fill="url(#pattern-contingencies)"
            filterNulls
            hasSteps
            x={x}
            y={y}
          />
          {/* Total number Contingencies */}
          <TimelinePath<{ contingencyRemaining: number }>
            // Before today
            data={data}
            field="contingencyRemaining"
            filterNulls
            hasSteps
            stroke="stroke-entities-contingencies"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelinePath<{ value: number }>
            // After today Contingencies
            data={[
              { date: data[lastIndex].date, value: data[lastIndex].contingencyRemaining },
              { date: xMax, value: data[lastIndex].contingencyRemaining },
            ]}
            filterNulls
            stroke="stroke-entities-contingencies-future"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
        </>
      )}
      {hasAllowance && (
        <>
          {/* Pending Area Allowances */}
          <TimelineArea<{ y0: number; y: number }, { x: Date }>
            data={pendingAllowances}
            fill="url(#pattern-allowances)"
            filterNulls
            hasSteps
            x={x}
            y={y}
          />
          {/* Total number Allowances */}
          <TimelinePath<{ allowanceRemaining: number }>
            // Before today
            data={data}
            field="allowanceRemaining"
            filterNulls
            hasSteps
            stroke="stroke-entities-allowances"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelinePath<{ value: number }>
            // After today Allowances
            data={[
              { date: data[lastIndex].date, value: data[lastIndex].allowanceRemaining },
              { date: xMax, value: data[lastIndex].allowanceRemaining },
            ]}
            filterNulls
            stroke="stroke-entities-allowances-future"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
        </>
      )}
      {/* Today */}
      <TimelinePath<{ value: number }>
        data={todayData}
        stroke="stroke-selection-focus-fill"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Points */}
      {!isPrint && hoverDate && (
        <TimelineMultiTooltip
          key={`tooltip-${hoverIndex}-${hoverDate}`}
          backgroundColor={[
            // Tooltip background colors
            hasContingency && hasContingencyValue
              ? 'var(--colors-entities-contingencies-label)'
              : null,
            hasAllowance && hasAllowanceValue ? 'var(--colors-entities-allowances-label)' : null,
          ].filter(isNonNullable)}
          content={[
            hasContingency && hasContingencyValue
              ? formatCost(hoverCA.contingencyRemaining, costFormat)
              : null,
            hasAllowance && hasAllowanceValue
              ? formatCost(hoverCA.allowanceRemaining, costFormat)
              : null,
          ].filter(isNonNullable)}
          data={[
            hasContingency && hasContingencyValue
              ? {
                  value: hoverCA.contingencyRemaining,
                  date: hoverCA.date,
                }
              : null,
            hasAllowance && hasAllowanceValue
              ? {
                  value: hoverCA.allowanceRemaining,
                  date: hoverCA.date,
                }
              : null,
          ].filter(isNonNullable)}
          fill={[
            // Points fill colors
            hasContingency && hasContingencyValue ? 'fill-entities-contingencies' : null,
            hasAllowance && hasAllowanceValue ? 'fill-entities-allowances' : null,
          ].filter(isNonNullable)}
          isOpen
          x={x}
          y={y}
          yDomain={yDomain}
        />
      )}
      <TimelineHoverSections
        bounds={bounds}
        data={data}
        onHoverIndex={(index) =>
          index === -1 ? setHoverDate(undefined) : setHoverDate(data[index]?.date)
        }
        x={x}
      />
      <ContingenciesFillPattern
        fillBgn="fill-entities-contingencies-drawn"
        id="pattern-contingencies"
        stroke="stroke-entities-contingencies"
      />
      <ContingenciesFillPattern
        fillBgn="fill-entities-allowances-drawn"
        id="pattern-allowances"
        stroke="stroke-entities-allowances"
      />
      <CostLabelBlur />
    </SVGWithDimensions>
  );
}
