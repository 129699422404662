import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes } from '../../../api/gqlEnums';
import { UserStatus } from '../../../api/gqlEnumsBe';
import { useResendInviteMutation } from '../../../hooks/useResendInviteMutation';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isEnumValue } from '../../../utilities/types';
import { useCurrentUser } from '../../contexts/current-user';
import { Button, Icon, Select, SortManager, TextInput, Tooltip } from '../../scales';
import {
  useUpdateCompanyUserJobTitleMutation,
  useUpdateCompanyUserNameMutation,
  useUpdateCompanyUserStatusMutation,
} from '../CompanyHooks';
import CompanyTabRoleInfo from '../CompanyTabRoleInfo/CompanyTabRoleInfo';
import CompanyTabRoleSelector from '../CompanyTabRoleSelector/CompanyTabRoleSelector';
import CompanyTabStyles from '../CompanyTabStyles';
import { CompanyUserSearchManager, getIsPending, searchCompanyUsers } from '../CompanyTabUtils';
import CompanyUserPhoto from '../CompanyUserPhoto';

import CompanyTabMembersSidebar from './CompanyTabMembersSidebar';
import ProjectTable from './ProjectTable';
import StatusColorDot from './StatusColorDot';

type CompanyTabMembersProfileProps = {
  classes: Classes<typeof CompanyTabStyles>;
  company: Company;
  companyProjectRoles: CompanyProjectRoles[];
  companyRoles: PartialRole[];
  companyUsers: CompanyUser[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  companyUsersLoading: boolean;
  companyUserDetails?: CompanyUserDetails;
  countTotalMembers: number;
  projectSortManager: SortManager;
  sortManager: SortManager;
  filterStatus: string;
  setFilterStatus: (s: string) => void;
  searchManager: CompanyUserSearchManager;
  userCounts: number[];
};

const CompanyTabMembersProfile: FC<CompanyTabMembersProfileProps> = ({
  classes,
  company,
  companyProjectRoles,
  companyRoles,
  companyUsers,
  companyUsersLoading,
  companyUserDetails,
  countTotalMembers,
  projectSortManager,
  sortManager,
  filterStatus,
  setFilterStatus,
  searchManager,
  userCounts,
}) => {
  const { user } = companyUserDetails || { user: undefined };

  const [revisedUser, setRevisedUser] = useState(user);
  const updateUserName = useUpdateCompanyUserNameMutation(company?.id);
  const updateUserJobTitle = useUpdateCompanyUserJobTitleMutation(company?.id);
  const updateUserStatus = useUpdateCompanyUserStatusMutation();
  const resendInvite = useResendInviteMutation();

  const currentUser = useCurrentUser();

  const isCurrentUser = currentUser?.id === user?.id;

  const sendAnalytics = useSendAnalytics();

  const { searchTerm } = searchManager;

  const searchedCompanyUsers = searchCompanyUsers(companyUsers, searchTerm);

  const deactivated = user && user.status === UserStatus.DEACTIVATED;

  const statusEntries = companyUserDetails?.user?.isEmailVerified
    ? [
        {
          id: UserStatus.ACTIVE,
          label: UserStatus.ACTIVE,
          startAdornment: <StatusColorDot status={UserStatus.ACTIVE} />,
        },
        {
          id: UserStatus.DEACTIVATED,
          label: UserStatus.DEACTIVATED,
          startAdornment: <StatusColorDot status={UserStatus.DEACTIVATED} />,
        },
      ]
    : [
        {
          id: UserStatus.PENDING,
          label: UserStatus.PENDING,
          startAdornment: <StatusColorDot status={UserStatus.PENDING} />,
        },
        {
          id: UserStatus.DEACTIVATED,
          label: UserStatus.DEACTIVATED,
          startAdornment: <StatusColorDot status={UserStatus.DEACTIVATED} />,
        },
      ];

  return (
    <div className={classes.memberContainer}>
      <CompanyTabMembersSidebar
        company={company}
        companyProjectRoles={companyProjectRoles}
        companyRoles={companyRoles}
        companyUsers={searchedCompanyUsers}
        countTotalMembers={countTotalMembers}
        filterStatus={filterStatus}
        loading={companyUsersLoading}
        searchManager={searchManager}
        setFilterStatus={setFilterStatus}
        sortManager={sortManager}
        userCounts={userCounts}
      />
      {companyUserDetails && user && revisedUser && (
        <div className="flex w-full max-w-[800px] flex-col gap-4 p-6 text-type-primary">
          <div className="flex items-center">
            <Link
              className="type-heading3"
              data-cy="breadcrumb-link"
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBERS_BREADCRUMB_CLICK)
                );
              }}
              to={generateSharedPath(JoinCompanyRoutes.COMPANY_MEMBERS, {})}
            >
              Employees
            </Link>
            <div className="type-body1">
              &nbsp;/&nbsp;
              {`${user.name} - ${user.email}`}
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col gap-0.5">
              <div className="flex items-center gap-1">
                <div className="type-label">Employee Photo</div>
                <Tooltip content="Images can be PNG, JPG, SVG, or WEBP. Drop image or click to choose file.">
                  <Icon name="info" size="sm" type="filled" />
                </Tooltip>
              </div>
              <div
                className={
                  deactivated
                    ? `${classes.avatarProfile} ${classes.deactivatedAvatar}`
                    : `${classes.avatarProfile}`
                }
              >
                <CompanyUserPhoto user={user} />
              </div>
            </div>
            <div className="flex grow flex-col gap-2">
              <TextInput
                data-cy="company-user-name-text-input"
                errorMessage={revisedUser?.name?.trim() ? '' : 'Name is required.'}
                isDisabled={deactivated}
                label="Full Name *"
                onBlur={() => {
                  if (revisedUser.name !== user.name && revisedUser.name?.trim()) {
                    updateUserName(user.id, revisedUser.name);
                    sendAnalytics(
                      companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_NAME_CHANGE)
                    );
                  } else {
                    setRevisedUser(user);
                  }
                }}
                onChange={(value) => {
                  setRevisedUser({ ...revisedUser, name: value });
                }}
                placeholder={revisedUser?.name ?? ''}
                value={deactivated ? '' : (revisedUser?.name ?? '')}
              />
              <TextInput
                data-cy="company-user-job-title-text-input"
                isDisabled={deactivated}
                label="Job Title"
                onBlur={() => {
                  if (revisedUser.jobTitle !== companyUserDetails.user.jobTitle) {
                    updateUserJobTitle(companyUserDetails.user.id, revisedUser.jobTitle);
                    sendAnalytics(
                      companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_JOB_TITLE_CHANGE, {
                        targetID: companyUserDetails.user.id,
                        jobTitle: revisedUser.jobTitle,
                      })
                    );
                  }
                }}
                onChange={(value) => {
                  setRevisedUser({ ...revisedUser, jobTitle: value });
                }}
                placeholder={user.jobTitle.length > 0 ? user.jobTitle : 'e.g. Senior Estimator'}
                value={deactivated ? '' : (revisedUser?.jobTitle ?? '')}
              />
            </div>
          </div>

          <div>
            <div className="flex items-end gap-2">
              <CompanyTabRoleSelector
                key={companyUserDetails?.role?.id}
                analyticsView={CompanyAdminView.MEMBER_DETAILS}
                companyID={company?.id}
                currentRole={companyUserDetails?.role?.id}
                isDisabled={deactivated}
                isLabeled
                roles={companyRoles}
                userID={companyUserDetails.user.id}
              />
              <CompanyTabRoleInfo
                onClick={() => {
                  sendAnalytics(
                    companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_COMPANY_ROLE_MODAL, {
                      view: CompanyAdminView.MEMBER_DETAILS,
                    })
                  );
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Select
              data-cy="status-select"
              entries={statusEntries}
              isDisabled={isCurrentUser}
              label="Status"
              onChange={(value) => {
                if (!isEnumValue(UserStatus, value)) return;

                updateUserStatus(user.id, value);
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_STATUS_CHANGE, {
                    view: CompanyAdminView.MEMBER_DETAILS,
                    status: value,
                    targetID: user.id,
                  })
                );
              }}
              value={user.status}
            />
            {companyUserDetails.user.activeAt && (
              <div className="type-body2">
                Latest activity on {new Date(companyUserDetails.user.activeAt).toLocaleDateString()}
              </div>
            )}
            {getIsPending(companyUserDetails.user) ? (
              <Button
                data-cy="resend-company-invite-button"
                label="Resend Invite"
                onClick={() => {
                  resendInvite(companyUserDetails.user.id, undefined, undefined, () =>
                    setToast({
                      message: `Invite email has been resent to ${companyUserDetails?.user?.email} `,
                    })
                  );
                  sendAnalytics(
                    companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_RESEND_INVITE_CTA, {
                      targetID: user.id,
                    })
                  );
                }}
                type="secondary"
              />
            ) : null}
          </div>
          <ProjectTable
            companyUserDetails={companyUserDetails}
            page={CompanyAdminView.MEMBER_DETAILS}
            sortManager={projectSortManager}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabMembersProfile);
