import { ReactNode } from 'react';

import { navEventTypes } from '../../../../analytics/analyticsEventProperties';
import { TOTAL } from '../../../../constants';
import { DD_NAV_COST_PER_UNIT } from '../../../../features';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useAvailableCostModes } from '../../../../utilities/costMode';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { isEnumValue } from '../../../../utilities/types';
import useMilestoneQuantitiesQuery from '../../../Milestone/hooks/useMilestoneQuantitiesQuery';
import { Tab, TabList, Tabs } from '../../../scales';
import useSendNavAnalytics from '../../hooks/useSendNavAnalytics';
import { GearIcon } from '../../icons';

import CostsSummary from './CostsSummary';
import { HUDTabPanel } from './HUDTabPanel';
import ItemsChart from './ItemsChart';
import Settings from './Settings';
import { TabKeys } from './types';
import UnitToggle from './UnitToggle';

type Props = {
  projectID: UUID;
  milestoneID?: UUID;
};

export default function HUD(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();
  const hasUnitToggleFeature = useHasFeature(DD_NAV_COST_PER_UNIT);

  const [quantityID, setQuantityID] = useLocalStorage(
    `Sidebar Project ${props.projectID} Unit`,
    TOTAL
  );
  const quantities = useMilestoneQuantitiesQuery(props.milestoneID).data?.quantities ?? [];
  const selectedQuantity = quantities.find((q) => quantityID === q.id);
  const shouldHaveUnitToggle = quantities.length > 0;
  const hasUnitToggleVisible = hasUnitToggleFeature && shouldHaveUnitToggle;

  const [activeHUDTab, setActiveHUDTab] = useLocalStorage('NAV_ACTIVE_HUD_TAB', TabKeys.COSTS);
  const tabs = useTabs(props.projectID);
  if (!tabs.length) return null;

  return (
    <Tabs
      data-cy="hud"
      onChange={(key) => {
        if (typeof key === 'string' && isEnumValue(TabKeys, key)) {
          setActiveHUDTab(key);
          sendNavAnalytics(navEventTypes.HUD_TAB_CHANGED, { key });
        }
      }}
      // Make sure we always reset the current tab to one that actually exists. This handles
      // local-storage backcompat and any changes to permissions.
      value={tabs.find((t) => t.key === activeHUDTab) ? activeHUDTab : tabs[0].key}
    >
      <TabList data-cy="hud-tabs">{tabs.map((t) => t.element)}</TabList>
      <HUDTabPanel hasUnitToggleVisible={hasUnitToggleVisible} id={TabKeys.COSTS}>
        {hasUnitToggleVisible && (
          <UnitToggle
            onChange={setQuantityID}
            quantities={quantities}
            quantity={selectedQuantity}
          />
        )}
        <CostsSummary
          activeMilestoneID={props.milestoneID}
          projectID={props.projectID}
          quantity={selectedQuantity}
        />
      </HUDTabPanel>
      <HUDTabPanel hasUnitToggleVisible={hasUnitToggleVisible} id={TabKeys.ITEMS}>
        {hasUnitToggleVisible && (
          <UnitToggle
            onChange={setQuantityID}
            quantities={quantities}
            quantity={selectedQuantity}
          />
        )}
        <ItemsChart
          activeMilestoneID={props.milestoneID}
          projectID={props.projectID}
          quantity={selectedQuantity}
        />
      </HUDTabPanel>
      <HUDTabPanel hasUnitToggleVisible={hasUnitToggleVisible} id={TabKeys.SETTINGS}>
        <Settings projectID={props.projectID} />
      </HUDTabPanel>
    </Tabs>
  );
}

function useTabs(projectID: UUID) {
  const { shouldDisplayCosts, setDisplayCostsToggle } = useShouldDisplayCosts(projectID);
  const { availableMarkupModes } = useAvailableCostModes(projectID);

  const tabs: { key: string; element: ReactNode }[] = [];
  if (shouldDisplayCosts) {
    tabs.push(
      {
        key: TabKeys.COSTS,
        element: (
          <Tab key={TabKeys.COSTS} id={TabKeys.COSTS} isCompact>
            Costs
          </Tab>
        ),
      },
      {
        key: TabKeys.ITEMS,
        element: (
          <Tab key={TabKeys.ITEMS} id={TabKeys.ITEMS} isCompact>
            Items
          </Tab>
        ),
      }
    );
  }

  if ((shouldDisplayCosts && availableMarkupModes.length > 1) || setDisplayCostsToggle) {
    tabs.push({
      key: 'settings',
      element: (
        <Tab key={TabKeys.SETTINGS} id={TabKeys.SETTINGS} isCompact isRightAligned>
          <GearIcon className="icon-md" />
        </Tab>
      ),
    });
  }

  return tabs;
}
