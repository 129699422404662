import { SyntheticEvent, useRef, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { useUploadImage } from '../assets/hooks/AssetsMutationHook';
import { CompanyLogoThumbnail } from '../dragon-scales';
import { BabyButton, Icon, TextLink, Tooltip } from '../scales';

type Props = {
  company: Company;
  onChange: (assetID: UUID) => void;
};

export default function CompanyLogo(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [uploading, setUploading] = useState(false);

  const uploadImage = useUploadImage();
  const onChangeLogo = async (file: File) => {
    setUploading(true);

    try {
      uploadImage(file, null, (a) => {
        props.onChange(a.id);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setUploading(false);
    }
  };

  let image = props.company.assetID ? (
    <CompanyLogoThumbnail size={200} thumbnail={props.company.assetID} />
  ) : (
    <Icon name="add" size="lg" />
  );
  if (uploading) image = <CircularProgress size={200} />;

  return (
    <div
      className="flex flex-col gap-0.5"
      onDragEnter={noop}
      onDragLeave={noop}
      onDragOver={noop}
      onDrop={(evt) => {
        noop(evt);
        onChangeLogo(evt.dataTransfer.files[0]);
      }}
    >
      <div className="type-label">Logo</div>
      <button
        aria-haspopup="true"
        aria-label="Thumbnail"
        aria-owns="thumbnail"
        className="h-50 w-50 border border-dashed target:outline focus-visible:outline"
        onClick={() => inputRef.current?.click()}
      >
        {image}
      </button>
      <input
        ref={inputRef}
        accept=".png, .jpg, .webp, .svg"
        className="hidden"
        onChange={(evt) => {
          if (evt.target && evt.target.files) {
            onChangeLogo(evt.target.files[0]);
          }
        }}
        type="file"
      />
      <div className="type-body3">
        <div className="flex items-center gap-2">
          <div>Drop image or click to choose file.</div>
          <Tooltip content="Images can be PNG, JPG, SVG, or WEBP.">
            <BabyButton aria-label="supports png, jpg, svg, or webp" icon={<Icon name="info" />} />
          </Tooltip>
        </div>
        <div className="type-body3">
          {'Need help? '}
          <TextLink label="Contact Join Support" size="sm" to="mailto:support@join.build" />
        </div>
      </div>
    </div>
  );
}

const noop = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};
