import { Link } from 'react-router-dom';

import { JoinRoutes } from '../../../../api/gqlEnums';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { Thumbnail } from '../../../dragon-scales';

import Title from './Title';

type Props = {
  isShowingMilestoneInfo?: boolean;
  onShowMilestoneInfo?: (value: boolean) => void;
  project: {
    activeMilestone: { id: UUID; name: string };
    id: UUID;
    name: string;
    thumbnail?: string;
  };
  isThumbnailVisible?: boolean;
};

export default function ProjectTitle(props: Props) {
  return (
    <Title
      isShowingMilestoneInfo={props.isShowingMilestoneInfo}
      onShowMilestoneInfo={props.onShowMilestoneInfo}
      subtitle={props.project.activeMilestone.name}
      thumbnail={
        props.isThumbnailVisible ? (
          <Link
            className="cursor-pointer border focus-visible:outline"
            to={generateSharedPath(JoinRoutes.PROJECT, {
              projectId: props.project?.id,
            })}
          >
            <Thumbnail size={62} thumbnail={props.project?.thumbnail} />
          </Link>
        ) : undefined
      }
      title={props.project.name}
    />
  );
}
