import * as d3 from 'd3';

import { renderCostString } from '../CostReport/CostReportUtils';

import { CategoryTotals } from './hooks/useProgramCategorizedTotalsQuery';

type Props = {
  xScale: d3.ScaleBand<string>;
  xWidth: number;
  yOffset: number;
  categoryCostData: CategoryTotals[];
  categorizationName: string;
};

export default function EstimateChartXAxis(props: Props) {
  return (
    <>
      <g
        ref={(node) => {
          if (node) {
            const axis = d3
              .axisBottom(props.xScale)
              .tickFormat(
                (t) =>
                  `${t}\n${renderCostString({ cost: props.categoryCostData.find((ccd) => ccd.categoryName === t)?.estimate, short: true })}`
              );

            // Apply the axis to the <g> element
            const axisGroup = d3.select(node).call(axis);

            // Style the axis line (domain)
            axisGroup.select('.domain').style('stroke', '#e6e6e9').style('stroke-width', 2);

            // Style the ticks
            axisGroup.selectAll('.tick line').style('stroke', '#e6e6e9');

            // format the tick text so the category and estimate values are on separate lines then add style
            axisGroup
              .selectAll('.tick text')
              .each(function formatTickText() {
                const self = d3.select(this);
                const lines = self.text().split('\n'); // Split the text by newline
                self.text(null); // Clear the original text

                // Append each line as a separate tspan
                lines.forEach((line, _) => {
                  self
                    .append('tspan')
                    .attr('x', 0)
                    .attr('dy', `1.25em`) // Position lines below each other
                    .text(line);
                });
              })
              .attr('class', 'type-body2');

            // Add x-axis label
            // Select the existing x-axis label or append a new one if it doesn't exist
            const xAxisLabel: d3.Selection<SVGTextElement, object, SVGGElement, unknown> = axisGroup
              .selectAll<SVGTextElement, object>('.x-axis-label')
              .data([{}]);

            // Enter and merge the label to ensure updates on resize
            xAxisLabel
              .enter()
              .append('text')
              .attr('class', 'type-body1 x-axis-label')
              .merge(xAxisLabel)
              .attr('x', props.xWidth / 2) // Center the label horizontally based on the new width
              .attr('y', 70) // Position below the axis
              .attr('text-anchor', 'middle') // Center text anchor
              .style('fill', 'black') // Text color
              .text(props.categorizationName); // Set the label text
          }
        }}
        transform={`translate(0, ${props.yOffset})`} // Move the axis to the bottom of the chart
      />
      <g />
    </>
  );
}
