import { useCallback } from 'react';

import { navEventTypes } from '../../../../analytics/analyticsEventProperties';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useSendNavAnalytics from '../../hooks/useSendNavAnalytics';

export default function useHUDVisibility() {
  const sendNavAnalytics = useSendNavAnalytics();

  const [showHUD, setShowHUD] = useLocalStorage('NAV_SHOW_HUD', true);
  const onChangeHUDVisibility = useCallback(
    (expand: boolean) => {
      sendNavAnalytics(navEventTypes.HUD_EXPAND_CHANGED, { expand });
      setShowHUD(expand);
    },
    [sendNavAnalytics, setShowHUD]
  );

  return { showHUD, onChangeHUDVisibility };
}
