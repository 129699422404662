//* * Pattern definition for filling in the Contingencies chart area */
export default function ContingenciesFillPattern({
  fillBgn,
  id,
  stroke,
}: {
  fillBgn: string;
  id: string;
  stroke: string;
}) {
  return (
    <defs>
      <pattern height="4" id={id} patternUnits="userSpaceOnUse" width="4">
        <rect className={fillBgn} height="4" width="4" />
        <path className={stroke} d="M 1 5 l -2 -2 M 4 4 l -4 -4 M 5 1 l -2 -2" strokeWidth="1" />
      </pattern>
    </defs>
  );
}
