import { useState } from 'react';

import { useProgramBreakdownQuery } from '../../hooks/useProgramBreakdownQuery';
import { useProgramCategorizationsQuery } from '../../hooks/useProgramCategorizationsQuery';
import { useProgramCostsQuery } from '../../hooks/useProgramCostsQuery';
import { useProgramQuery } from '../../hooks/useProgramQuery';

import { useProgramCostTrendlineQuery } from './useProgramCostTrendlineQuery';

export default function useProgramDashboardHooks(programID: UUID) {
  const [selectedProjectIDs, setSelectedProjectIDs] = useState<UUID[]>([]);

  const {
    data: programData,
    previousData: prevProgramData,
    loading: loadingProgram,
  } = useProgramQuery(programID, {
    onCompleted: () => {
      setSelectedProjectIDs(programData?.program?.projects?.map((p) => p.id) ?? []);
    },
  });
  const program = programData?.program ?? prevProgramData?.program;

  // wait for program / selectedProjectIDs to be loaded to avoid duplicate queries with empty list of selectedProjectIDs
  const {
    data: breakdownData,
    previousData: prevBreakdownData,
    loading: loadingBreakdownData,
  } = useProgramBreakdownQuery(program?.id, selectedProjectIDs);
  const breakdowns = breakdownData?.programBreakdown ?? prevBreakdownData?.programBreakdown;

  // wait for program / selectedProjectIDs to be loaded to avoid duplicate queries with empty list of selectedProjectIDs
  const {
    data: programCostTrendlineData,
    previousData: prevProgramCostTrendlineData,
    loading: loadingProgramCostTrendlineData,
  } = useProgramCostTrendlineQuery(program?.id, selectedProjectIDs);
  const trendline =
    programCostTrendlineData?.programCostTrendline ??
    prevProgramCostTrendlineData?.programCostTrendline;

  // wait for program / selectedProjectIDs to be loaded to avoid duplicate queries with empty list of selectedProjectIDs
  const {
    data: programCostData,
    previousData: prevProgramCostData,
    loading: loadingProgramCostData,
  } = useProgramCostsQuery(selectedProjectIDs, program?.id);
  const programCosts = programCostData?.programCosts ?? prevProgramCostData?.programCosts;

  const programCategorizationsQueryResult = useProgramCategorizationsQuery(
    program?.id, // wait for program / selectedProjectIDs to be loaded to avoid duplicate queries with empty list of selectedProjectIDs
    selectedProjectIDs
  );
  const programCategorizations = (
    programCategorizationsQueryResult.data ?? programCategorizationsQueryResult.previousData
  )?.programCategorizations;

  const isLoading =
    loadingProgram ||
    loadingBreakdownData ||
    loadingProgramCostTrendlineData ||
    loadingProgramCostData ||
    programCategorizationsQueryResult.loading;

  return {
    isLoading,
    programCategorizations,
    programCosts,
    program,
    selectedProjectIDs,
    setSelectedProjectIDs,
    breakdowns,
    trendline,
  };
}
