import { searchEventTypes } from '../../../analytics/analyticsEventProperties';
import { ButtonBar } from '../../scales';

import useSendSearchAnalytics from './hooks/useSendSearchAnalytics';

type SearchToggleProps = {
  options: { value: string; label: string; count: number }[];
  onChange: (value: string) => void;
  value: string;
};

export type SearchToggleParams = SearchToggleProps;

export default function SearchToggle({ options, onChange, value }: SearchToggleProps) {
  const sendSearchAnalytics = useSendSearchAnalytics();

  return (
    <ButtonBar
      onChange={(value: string) => {
        onChange(value);
        sendSearchAnalytics(searchEventTypes.SEARCH_TOGGLE, {
          toggleValue: value,
        });
      }}
      options={options.map((o) => ({ ...o, label: `${o.label} (${o.count})` }))}
      value={value}
    />
  );
}
