import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { useReactiveVar } from '@apollo/client';

import { projectSettingsVar, reloadGridVersionVar } from '../../../api/apollo/reactiveVars';
import { ItemDrawInfo } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useCostMode } from '../../../utilities/costMode';
// eslint-disable-next-line import/no-cycle
import {
  MarkupGridController,
  newCategorizationController,
  newEstimateGridController,
  newMarkupGridController,
} from '../GridController';
import { CategorizationWrapperProps } from '../JoinGridCategorizationWrapper';
import {
  EstimateArmatureSubtotals,
  GridController,
  GridType,
  JoinGridWrapperProps,
} from '../types';
import { filterControllerProps } from '../utilities/component';
import { getSortBy } from '../utilities/data';

import categorizationQuery from './categorizationQuery';
import { EstimateArmature, getEstimateArmature } from './estimateQuery';

type QueryState<T> = {
  loading: boolean;
  error: boolean;
  data: T | undefined;
};

type EstimateQueryState = QueryState<{
  estimate: GridController;
  markup: MarkupGridController;
  inheritedMarkups?: MarkupGridController;
  incorporatedMarkups?: MarkupGridController;
  incorporatedDraws?: MarkupGridController;
  itemDraws?: MarkupGridController;
  ownerCosts?: MarkupGridController;
  inheritedOwnerCostMarkups?: MarkupGridController;
  contingencyDraws?: Pick<ItemDrawInfo, 'id' | 'draw' | 'name' | 'error'>[];
  subtotals?: EstimateArmatureSubtotals;
}>;

// This is basically a hand-rolled useQuery. In fact, it was the inspired by the
// real useQuery implementation, which as of today can be found here. The real one
// does a lot more with middleware and post-effects, but in spirit they're really the same
// https://github.com/apollographql/@apollo/client/blob/master/src/react/hooks/utils/useBaseQuery.ts
export function useEstimateMarkupController(props: JoinGridWrapperProps): EstimateQueryState {
  const {
    estimateID,
    milestoneID,
    itemID,
    includeDraws,
    refetchOuter,
    sortData,
    viewFilter,
    enabledCategorizationsIDs,
  } = props;

  const [queryResult, setQueryResult] = useState<EstimateQueryState>({
    loading: true,
    error: false,
    data: undefined,
  });

  const isActive = useRef(true);
  // this cleanup function prevents an unmounted component from setting state
  // but is this always right?
  useEffect(
    () => () => {
      isActive.current = false;
    },
    [isActive]
  );

  const itemEstimateInput = itemID && milestoneID ? { itemID, milestoneID } : undefined;

  const handleQueryResult = (
    result: Omit<EstimateArmature, 'contingencyDraws' | 'drawSubtotal'>
  ) => {
    if (isActive.current) {
      const { estimate } = result;

      if (estimate) {
        const inheritedMarkupController = newMarkupGridController(
          {
            ...props,
            estimate,
            refetch: () => {
              getEstimateArmature(
                estimateID,
                itemEstimateInput,
                getSortBy(sortData),
                viewFilter || {},
                undefined,
                handleQueryResult
              );
              if (refetchOuter) refetchOuter();
            },
          },
          GridType.INHERITED_GRID
        );

        const inheritedOwnerCostMarkupController = newMarkupGridController(
          {
            ...props,
            estimate,
            refetch: () => {
              getEstimateArmature(
                estimateID,
                itemEstimateInput,
                getSortBy(sortData),
                viewFilter || {},
                undefined,
                handleQueryResult
              );
              if (refetchOuter) refetchOuter();
            },
          },
          GridType.INHERITED_OWNER_COST_MARKUP_GRID
        );

        const incorporatedMarkupController = newMarkupGridController(
          {
            ...props,
            estimate,
            refetch: () => {
              getEstimateArmature(
                estimateID,
                itemEstimateInput,
                getSortBy(sortData),
                viewFilter || {},
                undefined,
                handleQueryResult
              );
              if (refetchOuter) refetchOuter();
            },
          },
          GridType.INCORPORATED_ITEM_MARKUP_GRID
        );

        const incorporatedDrawsController = newMarkupGridController(
          {
            ...props,
            estimate,
            refetch: () => {
              getEstimateArmature(
                estimateID,
                itemEstimateInput,
                getSortBy(sortData),
                viewFilter || {},
                undefined,
                handleQueryResult
              );
              if (refetchOuter) refetchOuter();
            },
          },
          GridType.INCORPORATED_ITEM_DRAWS_GRID
        );
        const itemDrawsController = includeDraws
          ? newMarkupGridController(
              {
                ...props,
                estimate,
              },
              GridType.ITEM_DRAWS_GRID
            )
          : undefined;

        const ownerCostEstimate = estimate?.ownerCostEstimate;
        const ownerCostsController = newMarkupGridController(
          {
            ...props,
            estimateID: ownerCostEstimate?.id,
            estimate: ownerCostEstimate,
            milestoneEstimateID: estimate.id,
            refetch: () => {
              getEstimateArmature(
                estimateID,
                itemEstimateInput,
                getSortBy(sortData),
                viewFilter || {},
                undefined,
                handleQueryResult
              );
              if (refetchOuter) refetchOuter();
            },
          },
          GridType.OWNER_COST_GRID
        );
        const markupController = newMarkupGridController(
          {
            ...props,
            estimate,
            replaceInheritedMarkups:
              inheritedMarkupController && inheritedMarkupController.replaceMarkups,
            replaceIncorporatedMarkups:
              incorporatedMarkupController && incorporatedMarkupController.replaceMarkups,
            replaceIncorporatedDraws:
              incorporatedDrawsController && incorporatedDrawsController.replaceMarkups,
            replaceInheritedOwnerCostMarkups:
              inheritedOwnerCostMarkupController &&
              inheritedOwnerCostMarkupController.replaceMarkups,
            refetch: () => {
              getEstimateArmature(
                estimateID,
                itemEstimateInput,
                getSortBy(sortData),
                viewFilter || {},
                undefined,
                handleQueryResult
              );
              if (refetchOuter) refetchOuter();
            },
          },
          GridType.MARKUP_GRID
        );

        if (!markupController) return;

        const estimateController = newEstimateGridController({
          ...props,
          estimate: { ...estimate, linesCount: estimate.linesCount ?? undefined },
          replaceMarkups: markupController.replaceMarkups,
          replaceInheritedMarkups:
            inheritedMarkupController && inheritedMarkupController.replaceMarkups,
          replaceIncorporatedMarkups:
            incorporatedMarkupController && incorporatedMarkupController.replaceMarkups,
          replaceIncorporatedDraws:
            incorporatedDrawsController && incorporatedDrawsController.replaceMarkups,
          replaceInheritedOwnerCostMarkups:
            inheritedOwnerCostMarkupController && inheritedOwnerCostMarkupController.replaceMarkups,
          refetch: () => {
            getEstimateArmature(
              estimateID,
              itemEstimateInput,
              getSortBy(sortData),
              viewFilter || {},
              undefined,
              handleQueryResult
            );
            if (refetchOuter) refetchOuter();
          },
        });
        setQueryResult({
          loading: false,
          error: false,
          data: {
            estimate: estimateController,
            markup: markupController,
            inheritedMarkups: inheritedMarkupController,
            incorporatedMarkups: incorporatedMarkupController,
            incorporatedDraws: incorporatedDrawsController,
            itemDraws: itemDrawsController,
            ownerCosts: ownerCostsController,
            inheritedOwnerCostMarkups: inheritedOwnerCostMarkupController,
            contingencyDraws: estimate.contingencyDraws ?? [],
            subtotals: {
              subtotal: estimate.subtotal,
              markupSubtotal: estimate.markupSubtotal,
              incorporatedSubtotal: estimate.incorporatedSubtotal,
              incorporatedDrawsSubtotal: estimate.incorporatedDrawsSubtotal,
              inheritedSubtotal: estimate.inheritedSubtotal,
            },
          },
        });
      } else {
        setQueryResult({
          loading: false,
          error: true,
          data: undefined,
        });
      }
    }
  };

  // adding reloadGrid to the memoization allows us to see different
  // inherited markups when moving an item between milestones
  // without it the inherited markups will not change
  // and reload when categorizations change
  const reloadGridVersion = useReactiveVar(reloadGridVersionVar);
  useDeepCompareEffect(() => {
    if (estimateID) {
      getEstimateArmature(
        estimateID,
        itemEstimateInput,
        getSortBy(sortData),
        viewFilter || {},
        undefined,
        handleQueryResult
      );
    }
  }, [
    estimateID,
    filterControllerProps(props),
    isActive,
    reloadGridVersion,
    enabledCategorizationsIDs,
    useCostMode(), // refetch markups if cost mode changes
    useReactiveVar(projectSettingsVar), // we need to rerender the grid if the currency changes
  ]);

  return queryResult;
}

export function useCategorizationController(
  props: CategorizationWrapperProps,
  onCloseRef: MutableRefObject<() => void>,
  onSuccess: () => void
): QueryState<GridController> {
  const [queryResult, setResult] = useState<QueryState<GridController>>({
    loading: true,
    error: false,
    data: undefined,
  });

  const sendAnalytics = useSendAnalytics();

  const propsRef = useRef(props);
  const isActive = useRef(true);
  useEffect(
    () => () => {
      isActive.current = false;
    },
    [isActive]
  );

  useEffect(() => {
    if (propsRef.current.categorizationID) {
      categorizationQuery(propsRef.current.categorizationID, (result) => {
        if (isActive.current) {
          if (result.data) {
            setResult({
              loading: false,
              error: false,
              data: newCategorizationController(
                {
                  ...propsRef.current,
                  categorization: result.data.categorization,
                },
                onCloseRef,
                sendAnalytics,
                onSuccess
              ),
            });
          } else {
            setResult({ loading: false, error: true, data: undefined });
          }
        }
      });
    } else {
      setResult({
        loading: false,
        error: false,
        data: newCategorizationController(
          {
            ...propsRef.current,
          },
          onCloseRef,
          sendAnalytics,
          onSuccess
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [isActive, onCloseRef, propsRef, sendAnalytics]);
  // NOTE: updating with the missing onSuccess dependency causes the categorizations grid to flicker

  return queryResult;
}
