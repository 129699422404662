import { ReactNode } from 'react';

import { InsightsSort } from '../../generated/graphql';
import useSearchProjectsQueryParams from '../HomeTab/Search/projects/hooks/useSearchProjectsQueryParams';

import ProjectsContingenciesAndAllowances from './ProjectsTables/ProjectsContingenciesAndAllowances';
import ProjectsCost from './ProjectsTables/ProjectsCost';
import ProjectsItems from './ProjectsTables/ProjectsItems';
import { InsightsProject, InsightsTabId } from './types';

export default function ProjectsListTables({
  projectCounts,
  insightsProjects,
  sortState,
  tabId,
  onFetchMore,
  viewHeader,
}: {
  projectCounts: { current: number; total: number };
  insightsProjects: InsightsProject[];
  sortState: InsightsSort;
  tabId: InsightsTabId;
  onFetchMore: () => void;
  viewHeader: ReactNode;
}) {
  const params = useSearchProjectsQueryParams();

  if (!insightsProjects) return null;
  return (
    <>
      {tabId === InsightsTabId.Costs && (
        <ProjectsCost
          counts={projectCounts}
          loading={false}
          onFetchMore={onFetchMore}
          projects={insightsProjects}
          sortManager={params.sortManager}
          sortState={sortState}
          stickyHeader={viewHeader}
        />
      )}
      {tabId === InsightsTabId.Items && (
        <ProjectsItems
          counts={projectCounts}
          loading={false}
          onFetchMore={onFetchMore}
          projects={insightsProjects}
          sortManager={params.sortManager}
          sortState={sortState}
          stickyHeader={viewHeader}
        />
      )}
      {tabId === InsightsTabId.Contingencies && (
        <ProjectsContingenciesAndAllowances
          counts={projectCounts}
          loading={false}
          onFetchMore={onFetchMore}
          projects={insightsProjects}
          sortManager={params.sortManager}
          sortState={sortState}
          stickyHeader={viewHeader}
        />
      )}
    </>
  );
}
