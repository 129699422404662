import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import { CostTimeSeries } from '../InsightsCost/types';
import TimelineCircle from '../TimelineCircle';

import CombinedHoverContent from './CombinedHoverContent';

const OFFSET = 8;

type Props = {
  backgroundColor: string[];
  data: CostTimeSeries;
  fill: string[];
  isOpen?: boolean;
  onHover?: () => void;
  onLeave?: () => void;
  pointSize?: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  yDomain: [number, number];
};

export default function CombinedTimelineMultiTooltip(props: Props) {
  if (!props.data) return null;

  const lineAttrs = {
    x1: props.x(props.data.date) ?? 0,
    x2: props.x(props.data.date) ?? 0,
    y1: props.y(props.yDomain[0]),
    y2: props.y(props.yDomain[1]),
    stroke: 'var(--colors-chart-axis-ticks)',
    strokeWidth: 2,
  };

  return (
    <>
      {props.isOpen && (
        <>
          <line {...lineAttrs} />
          <TimelineCircle<{ value: number }>
            key={`hover-point-${props.data.date}-${props.data.estimate}`}
            data={{ date: props.data.date, value: props.data.estimate }}
            fill="fill-entities-estimate"
            size={props.pointSize}
            x={props.x}
            y={props.y}
          />
          <TimelineCircle<{ value: number }>
            key={`hover-point-${props.data.date}-${props.data.budget}`}
            data={{ date: props.data.date, value: props.data.budget }}
            fill="fill-entities-budget"
            size={props.pointSize}
            x={props.x}
            y={props.y}
          />
          <TimelineCircle<{ value: number }>
            key={`hover-point-${props.data.date}-${props.data.runningTotal}`}
            data={{ date: props.data.date, value: props.data.runningTotal }}
            fill="fill-entities-estimate"
            size={props.pointSize}
            x={props.x}
            y={props.y}
          />
          <TimelineCircle<{ value: number }>
            key={`hover-point-${props.data.date}-${props.data.pendingMax}`}
            data={{ date: props.data.date, value: props.data.pendingMax }}
            fill="var(--colors-chart-pending-cost-area)"
            size={props.pointSize}
            x={props.x}
            y={props.y}
          />
          <TimelineCircle<{ value: number }>
            key={`hover-point-${props.data.date}-${props.data.pendingMin}`}
            data={{ date: props.data.date, value: props.data.pendingMin }}
            fill="var(--colors-chart-pending-cost-area)"
            size={props.pointSize}
            x={props.x}
            y={props.y}
          />
        </>
      )}
      {props.isOpen && (
        <foreignObject
          height="500"
          width="500"
          x={
            // place hover content to the left of the point if it's too close to the right edge
            (props.x(props.data.date) ?? 0) + OFFSET + 250 > props.x.range()[1]
              ? (props.x(props.data.date) ?? 0) - 325 - OFFSET
              : (props.x(props.data.date) ?? 0) + OFFSET
          }
          y={10}
        >
          <CombinedHoverContent costData={props.data} />
        </foreignObject>
      )}
    </>
  );
}
