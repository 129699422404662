import { NS_PROGRAM_REPORTING } from '../../features';
import HasFeatureFlag from '../HasFeatureFlag';

import ProgramRoute from './ProgramRoute';

export default function ProgramReportingRoute() {
  return (
    <HasFeatureFlag flag={NS_PROGRAM_REPORTING}>
      <ProgramRoute />
    </HasFeatureFlag>
  );
}
