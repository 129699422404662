import { IdeaFilters, IdeasQuery, IdeasQueryVariables } from '../../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../../hooks/useMountAwareQuery';

import { ideasQuery } from './queries';

export default function useIdeasQuery(
  projectID: UUID,
  milestoneID: UUID | undefined,
  filters: IdeaFilters,
  options?: QueryHookOptions<IdeasQuery, IdeasQueryVariables>
) {
  return useQuery<IdeasQuery, IdeasQueryVariables>(ideasQuery, {
    skip: !projectID,
    ...options,
    variables: { projectID, milestoneID, filters },
  });
}
