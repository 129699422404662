import { Link } from 'react-router-dom';

import { ErrorOutline } from '@material-ui/icons';

import { InsightsProjectAlert } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString } from '../../../utilities/string';
import { getUniqueAlertDescriptionCounts } from '../utils';

type Props = {
  triggeredAlertsByProject: InsightsProjectAlert[];
};

const ProjectsWithAlertsTooltip = (props: Props) => {
  // Calculate how many projects are not shown in the tooltip
  const displayedProjects = props.triggeredAlertsByProject.slice(0, 4);
  const remainingProjectsCount = props.triggeredAlertsByProject.length - displayedProjects.length;

  return (
    <div className="flex w-75 flex-col gap-3 p-1">
      <strong className="type-body2">Projects with alerts</strong>
      {displayedProjects.map((project) => {
        const uniqueAlerts = getUniqueAlertDescriptionCounts(project.activeAlerts);

        return (
          <div key={project.projectID} className="flex flex-col gap-1">
            <div className="flex items-center justify-between gap-1">
              <Link
                to={{
                  pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, {
                    projectId: project.projectID,
                  }),
                }}
              >
                <div className="line-clamp-1 text-type-link type-heading3">
                  {project.projectName}
                </div>
              </Link>
              <strong className="whitespace-nowrap type-table-text">
                {pluralizeCountString('alert', project.activeAlerts.length)}
              </strong>
            </div>

            {/* Display the unique alerts with counts */}
            {uniqueAlerts.map((alert) => (
              <div key={alert.description} className="flex items-center gap-1">
                <ErrorOutline color="error" style={{ width: 20, height: 20 }} />
                <div className="text-type-error type-table-text">{alert.description}</div>
              </div>
            ))}
          </div>
        );
      })}

      {/* Display the remaining project count, if any */}
      {remainingProjectsCount > 0 && (
        <div className="type-table-text">
          +{pluralizeCountString('project', remainingProjectsCount)}
        </div>
      )}
    </div>
  );
};

export default ProjectsWithAlertsTooltip;
