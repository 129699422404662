import { useMemo } from 'react';

import { SortDirection } from '../../../../generated/graphql';

import { CompanyUserSortKey } from './useSortUserManager';

export const getValueOrEmpty = (value: string | undefined): string => value || '';

export const getSortValue =
  (sortKey: string) =>
  (cu: CompanyUser): string => {
    switch (sortKey) {
      case CompanyUserSortKey.JOB_TITLE.toString():
        return getValueOrEmpty(cu.user?.jobTitle).toLocaleLowerCase();
      case CompanyUserSortKey.COMPANY_ROLE.toString():
        return getValueOrEmpty(cu.role?.name).toLocaleLowerCase();
      case CompanyUserSortKey.NAME.toString():
      default:
        return getValueOrEmpty(cu.user?.name).toLocaleLowerCase();
    }
  };

export function useSortCompanyUsers(companyUsers: CompanyUser[], sortState: SortBy) {
  return useMemo(
    () =>
      companyUsers.sort((a, b) => {
        const aValue = getSortValue(sortState.sortKey)(a);
        const bValue = getSortValue(sortState.sortKey)(b);
        const ascendingAlgorithm = aValue > bValue ? 1 : -1;
        const descendingAlgorithm = aValue < bValue ? 1 : -1;
        return sortState.sortDirection === SortDirection.SORT_ASCENDING
          ? ascendingAlgorithm
          : descendingAlgorithm;
      }),
    [companyUsers, sortState]
  );
}
