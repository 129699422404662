import { ComponentProps, ReactNode } from 'react';

import { Button, Chip, Icon, IconMenuButton } from '../../scales';

type RootTileProps = {
  material: ComponentProps<typeof Tile>['material'];
};

export function RootTile(props: RootTileProps) {
  return <Tile chip="Root Material" className="bg-background-1" material={props.material} />;
}

type AlternateTileProps = {
  alternate: ComponentProps<typeof Tile>['material'];
  onCreateItem?: () => void;
  onHide?: () => void;
};

export function AlternateTile(props: AlternateTileProps) {
  return (
    <Tile
      className="border bg-background-primary"
      footer={
        <div className="flex justify-end gap-2">
          <Button label="Add to Project" onClick={props.onCreateItem} type="secondary" />
          <IconMenuButton
            aria-label="more actions button"
            entries={[
              {
                id: 'hide',
                label: 'Hide this Alternate',
                startAdornment: <Icon name="hide" />,
                onClick: () => props.onHide?.(),
              },
            ]}
            type="secondary"
          />
        </div>
      }
      material={props.alternate}
    />
  );
}

function Tile(props: {
  chip?: string;
  className?: string;
  footer?: ReactNode;
  material: { imageURL: string; name: string; description: string };
}) {
  return (
    <div className={['flex w-full flex-col rounded', props.className].join(' ')}>
      <div
        className="flex h-[190px] flex-col justify-end rounded-t bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${props.material.imageURL})` }}
      >
        <div className="flex flex-col gap-2 bg-gradient-to-t from-[#000000B3] from-75% to-[#0000] to-100% p-2 pt-6">
          <div className="text-type-secondary type-heading3">{props.material.name}</div>
          {props.chip && <Chip backgroundColor="#E7EAEF33" text={props.chip} textColor="#FFF" />}
        </div>
      </div>
      <div className="flex flex-col justify-between gap-4 rounded-b p-4">
        <span className="line-clamp-6 type-body3">{props.material.description}</span>
        {props.footer}
      </div>
    </div>
  );
}
