export const budgetImg = <div className="h-1 w-3 self-center bg-entities-budget" />;

export const estimateImg = (
  <svg fill="none" height="4" viewBox="0 0 12 4" width="12" xmlns="http://www.w3.org/2000/svg">
    <mask fill="white" id="path-1-inside-1_5473_97274">
      <path d="M0 0H12V4H0V0Z" />
    </mask>
    <path
      d="M0 6H1V2H0V6ZM3 6H5V2H3V6ZM7 6H9V2H7V6ZM11 6H12V2H11V6ZM0 8H1V0H0V8ZM3 8H5V0H3V8ZM7 8H9V0H7V8ZM11 8H12V0H11V8Z"
      fill="black"
      mask="url(#path-1-inside-1_5473_97274)"
    />
  </svg>
);

export const gapIcon = (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3L14 13H2L8 3Z" stroke="#686B6C" strokeWidth="2" />
  </svg>
);

export const gapMinIcon = (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3L14 13H2L8 3Z" stroke="#686B6C" strokeWidth="2" />
    <path d="M15.7555 4.79H12.6055V4.02H15.7555V4.79Z" fill="#686B6C" />
  </svg>
);

export const gapMaxIcon = (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3L14 13H2L8 3Z" stroke="#686B6C" strokeWidth="2" />
    <path
      d="M14.0538 3.11H15.7138V3.89H14.0538V5.56H13.2538V3.89H11.5938V3.11H13.2538V1.44H14.0538V3.11Z"
      fill="#686B6C"
    />
  </svg>
);

export const pendingAddDedIcon = (
  <svg fill="none" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <pattern height="4" id="diagonalHatch" patternUnits="userSpaceOnUse" width="4">
        <path d="M0 4L4 0" stroke="#D1D100" strokeWidth="0.5" />
      </pattern>
    </defs>
    <rect fill="url(#diagonalHatch)" height="16" width="16" />
  </svg>
);

export const potentialRangeIcon = (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66537 4.66667L7.9987 1.33333L11.332 4.66667L10.3987 5.6L8.66536 3.88333L8.66536 12.1167L10.3987 10.4L11.332 11.3333L7.9987 14.6667L4.66537 11.3333L5.5987 10.4L7.33203 12.1167L7.33203 3.88333L5.5987 5.6L4.66537 4.66667Z"
      fill="#F6B901"
    />
  </svg>
);

export const runningTotalImg = <div className="h-1 w-3 self-center bg-entities-estimate" />;

export const gmpMilestoneIcon = (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 11 12"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
    y={-2}
  >
    <path
      className="fill-entities-baseline-gmp"
      d="M6.33398 2.00049L5.66732 0.66716H0.333984V12.0005H1.66732V7.33383H5.00065L5.66732 8.66716H10.334V2.00049H6.33398ZM9.00065 7.33383H6.33398L5.66732 6.00049H1.66732V2.00049H5.00065L5.66732 3.33383H9.00065V7.33383Z"
    />
  </svg>
);

export const gmpActiveMilestoneIcon = (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 11 12"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
    y={-2}
  >
    <path
      className="fill-entities-baseline-gmp"
      d="M6.60065 2L6.33398 0.666666H0.333984V12H1.66732V7.33333H5.40065L5.66732 8.66667H10.334V2H6.60065Z"
    />
  </svg>
);

export const activeMilestoneIcon = (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 11 12"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
    y={-2}
  >
    <path
      className="fill-entities-milestone"
      d="M6.60065 1.97071L6.33398 0.637375H0.333984V11.9707H1.66732V7.30404H5.40065L5.66732 8.63737H10.334V1.97071H6.60065Z"
    />
  </svg>
);

export const pendingContingencies = (
  <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
    <rect fill="url(#pattern-contingencies)" height="12" width="12" />
  </svg>
);

export const pendingAllowances = (
  <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
    <rect fill="url(#pattern-allowances)" height="12" width="12" />
  </svg>
);

export const pendingCostsImg = (
  <svg fill="none" height="12" viewBox="0 0 12 12" width="12">
    <rect fill="url(#stripes)" height="12" width="12" />
  </svg>
);
