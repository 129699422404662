import { useState } from 'react';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useCurrentUser } from '../../contexts/current-user';
import { useModuleEntitlements } from '../../contexts/module-entitlements';
import { allRoles } from '../../Dialogs/DialogsCompanyRoles/DialogsCompanyRolesUtils';
import { Select } from '../../scales';
import { useUpdateCompanyUserCompanyRole } from '../CompanyHooks';

type Props = {
  companyID?: UUID;
  isLabeled?: boolean;
  roles: PartialRole[];
  currentRole?: UUID;
  userID: UUID;
  isDisabled?: boolean;
  analyticsView?: CompanyAdminView;
};

export default function CompanyTabRoleSelector(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const [newRole, setNewRole] = useState(props.currentRole);
  const updateRole = useUpdateCompanyUserCompanyRole(props.companyID);

  const moduleEntitlementFlags = useModuleEntitlements();

  const entries = props.roles.flatMap((r) => {
    if (
      allRoles.find(
        (a) =>
          a.companyRole === r.name &&
          (!a.entitlement || moduleEntitlementFlags.find((m) => m === a.entitlement))
      )
    ) {
      return [{ id: r.id, label: r.name }];
    }
    return [];
  });

  const isCurrentUser = useCurrentUser().id === props.userID;

  return (
    <Select
      entries={entries}
      isDisabled={props.isDisabled || isCurrentUser}
      label={props.isLabeled ? 'Company Role' : undefined}
      onChange={(entry) => {
        updateRole(props.userID, entry);
        setNewRole(entry);
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_COMPANY_ROLE_CHANGE, {
            view: props.analyticsView,
            targetID: props.userID,
            userRole: entry,
          })
        );
      }}
      value={newRole}
    />
  );
}
