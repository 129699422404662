import { useMemo } from 'react';

import { TermKey } from '../../../api/gqlEnums';
import useProjectTermStore from '../../../hooks/useProjectTermStore';
import { formatCost } from '../../../utilities/currency';
import { EMPTY_COST } from '../../../utilities/string';
import { isCostRange, isCostScalar } from '../../CostReport/CostReportUtils';
import { CostTimeSeries } from '../../dragon-scales/TimelineCharts/InsightsCost/types';
import useItemStatusCostReport from '../../Nav/Sidebar/hooks/useItemStatusCostReport';
import useSummaryCostReport from '../../Nav/Sidebar/hooks/useSummaryCostReport';
import { TerminologyDescriptions } from '../../ProjectDisplaySettings/constants';
import {
  budgetImg,
  estimateImg,
  gapIcon,
  gapMaxIcon,
  gapMinIcon,
  pendingAddDedIcon,
  potentialRangeIcon,
  runningTotalImg,
} from '../InsightsIcons';
import { InsightsProject } from '../types';

import ProjectCostRow from './ProjectCostRow';

type Props = {
  costTimeSeriesData?: CostTimeSeries;
  isChartTooltip?: boolean;
  isInHeader?: boolean;
  project?: InsightsProject;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showTooltips?: boolean;
};

export default function ProjectCostTable(props: Props) {
  // If project is provided then use following queries
  const activeMilestoneID = (props?.project?.milestones || []).find((m) => m.active);
  const terms = useProjectTermStore();
  const costsReport = useSummaryCostReport(props?.project?.id, activeMilestoneID?.id || '');
  const itemsReport = useItemStatusCostReport(props?.project?.id, activeMilestoneID?.id || '');
  // If displaying as a chart tooltip then use time series data for this table
  const displayAsChartTooltip = props.isChartTooltip;
  // Table is used in the project header
  const isInHeader = props.isInHeader;

  const costTimeSeriesPendingAdds = props.costTimeSeriesData?.pendingMax
    ? // eslint-disable-next-line no-unsafe-optional-chaining -- TODO CT-1151: Fix this pls :)
      props.costTimeSeriesData?.pendingMax - (props.costTimeSeriesData?.runningTotal || 0)
    : undefined;
  const costTimeSeriesPendingDeducts = props.costTimeSeriesData?.pendingMin
    ? // eslint-disable-next-line no-unsafe-optional-chaining -- TODO CT-1151: Fix this pls :)
      props.costTimeSeriesData?.pendingMin - (props.costTimeSeriesData?.runningTotal || 0)
    : undefined;
  // Format all costs to be used in this table
  const costValues = useMemo(
    () => ({
      estimate: formatCostValue(
        displayAsChartTooltip ? props.costTimeSeriesData?.estimate : costsReport.estimate
      ),
      accepted: formatCostValue(
        displayAsChartTooltip
          ? Number(props.costTimeSeriesData?.runningTotal) -
              Number(props.costTimeSeriesData?.estimate)
          : costsReport.accepted
      ),
      running: formatCostValue(
        displayAsChartTooltip ? props.costTimeSeriesData?.runningTotal : costsReport.running
      ),
      target: formatCostValue(
        displayAsChartTooltip ? props.costTimeSeriesData?.budget : costsReport.target,
        'No Budget'
      ),
    }),
    [displayAsChartTooltip, props.costTimeSeriesData, costsReport]
  );

  return (
    <div
      className={`flex w-full flex-col py-1 text-left type-table-text ${isInHeader ? 'gap-1.5' : 'gap-2'}`}
    >
      {/* ESTIMATE row */}
      <CostRow
        className={`text-type-primary ${isInHeader ? '!type-body3' : ''}`}
        cost={costValues.estimate}
        data-cy="cost-summary-estimate"
        description="Base estimate for the active milestone"
        icon={isInHeader ? undefined : estimateImg}
        isBold={!isInHeader}
        label={terms.titleCase(TermKey.ESTIMATE)}
        showTooltips={props.showTooltips}
        zeroText="No estimate"
      />
      {/* ACCEPTED_CHANGES row */}
      <CostRow
        className={`text-item-status-accepted ${isInHeader ? '!type-body3' : ''}`}
        cost={costValues.accepted}
        data-cy="cost-summary-accepted-changes"
        description={TerminologyDescriptions(terms)(TermKey.ACCEPTED_CHANGES)}
        label={terms.titleCase(TermKey.ACCEPTED_CHANGES)}
        showTooltips={props.showTooltips}
      />

      <hr />

      {/* RUNNING_TOTAL row */}
      <CostRow
        className={`text-type-primary ${isInHeader ? '!type-body3' : ''}`}
        cost={costValues.running}
        data-cy="cost-summary-running-total"
        description="Revised estimate including accepted items"
        icon={isInHeader ? undefined : runningTotalImg}
        isBold={!isInHeader}
        label={terms.titleCase(TermKey.RUNNING_TOTAL)}
        showTooltips={props.showTooltips}
        zeroText="No estimate"
      />
      {/* PENDING_ADDS_DEDUCTS row  */}
      <PendingAddDeducts
        className={`text-type-primary ${isInHeader ? '!type-body3' : ''}`}
        data-cy="cost-summary-pending-adds-deducts"
        description={TerminologyDescriptions(terms)(TermKey.PENDING_ADDS_DEDUCTS)}
        icon={isInHeader ? undefined : pendingAddDedIcon}
        itemData={itemsReport.pending}
        label={terms.titleCase(TermKey.PENDING_ADDS_DEDUCTS)}
        pendingAdds={costTimeSeriesPendingAdds}
        pendingDeducts={costTimeSeriesPendingDeducts}
        showTooltips={props.showTooltips}
      />
      {/* POTENTIAL_RANGE row */}
      <PotentialRange
        className={`text-type-primary ${isInHeader ? '!type-body3' : ''}`}
        cost={costValues.running}
        data-cy="cost-summary-potential-range"
        description={TerminologyDescriptions(terms)(TermKey.POTENTIAL_RANGE)}
        icon={isInHeader ? undefined : potentialRangeIcon}
        itemData={itemsReport.pending}
        label={terms.titleCase(TermKey.POTENTIAL_RANGE)}
        pendingAdds={costTimeSeriesPendingAdds}
        pendingDeducts={costTimeSeriesPendingDeducts}
        showTooltips={props.showTooltips}
      />
      {/* GAP row */}
      <GapTrending
        className={`text-type-primary ${isInHeader ? '!type-body3' : ''}`}
        costBudget={costValues.target}
        costRunning={costValues.running}
        data-cy="cost-summary-gap"
        description={TerminologyDescriptions(terms)(TermKey.GAP)}
        icon={isInHeader ? undefined : gapIcon}
        label={terms.titleCase(TermKey.GAP)}
        pendingAdds={costTimeSeriesPendingAdds}
        pendingDeducts={costTimeSeriesPendingDeducts}
        showTooltips={props.showTooltips}
        type="Base"
      />
      {/*  GAP_TRENDING_MAXIMUM row */}
      <GapTrending
        className={`text-type-primary ${isInHeader ? '!type-body3' : ''}`}
        costBudget={costValues.target}
        costRunning={costValues.running}
        data-cy="cost-summary-gap-min"
        description={TerminologyDescriptions(terms)(TermKey.GAP_TRENDING_MAXIMUM)}
        icon={isInHeader ? undefined : gapMaxIcon}
        itemData={itemsReport.pending}
        label={terms.titleCase(TermKey.GAP_TRENDING_MAXIMUM)}
        pendingAdds={costTimeSeriesPendingAdds}
        pendingDeducts={costTimeSeriesPendingDeducts}
        showTooltips={props.showTooltips}
        type="Max"
      />
      {/*  GAP_TRENDING_MINIMUM row */}
      <GapTrending
        className={`text-type-primary ${isInHeader ? '!type-body3' : ''}`}
        costBudget={costValues.target}
        costRunning={costValues.running}
        data-cy="cost-summary-gap-min"
        description={TerminologyDescriptions(terms)(TermKey.GAP_TRENDING_MINIMUM)}
        icon={isInHeader ? undefined : gapMinIcon}
        itemData={itemsReport.pending}
        label={terms.titleCase(TermKey.GAP_TRENDING_MINIMUM)}
        pendingAdds={costTimeSeriesPendingAdds}
        pendingDeducts={costTimeSeriesPendingDeducts}
        showTooltips={props.showTooltips}
        type="Min"
      />
      <hr />

      {/*  Budget row */}
      <CostRow
        className={`text-entities-milestone ${isInHeader ? '!type-body3' : ''}`}
        cost={costValues.target}
        data-cy="cost-summary-budget"
        description={`${terms.rawTerm(TermKey.TARGET)} value for this milestone`}
        icon={isInHeader ? undefined : budgetImg}
        isBold={!isInHeader}
        label={terms.titleCase(TermKey.TARGET)}
        showTooltips={props.showTooltips}
        zeroText="No budget"
      />
    </div>
  );
}

// Base props type without itemData
type BaseProps = {
  'data-cy': string;
  className: string;
  cost?: FormattedCost;
  description: string;
  icon?: JSX.Element;
  isBold?: boolean;
  label: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showTooltips?: boolean;
};

// Specific props for PendingAddDeducts and PotentialRange where itemData is needed
type ItemStatusData = {
  cost?: AddDeductCost;
};
type ItemDataProps = BaseProps & {
  itemData?: ItemStatusData;
  pendingAdds?: number;
  pendingDeducts?: number;
};

// Specific props for GapTrending where costBudget, costRunning, and type are needed
type GapTrendingProps = ItemDataProps & {
  costBudget?: FormattedCost;
  costRunning?: FormattedCost;
  type: 'Base' | 'Max' | 'Min';
};

function CostRow(props: BaseProps & { zeroText?: string }) {
  const value = props.cost ? props.cost.value : 0;
  const costRounded = props.zeroText && value <= 0 ? props.zeroText : props.cost?.shortCost;
  return (
    <ProjectCostRow
      className={props.className}
      costExact={props.cost?.exactCost}
      costRounded={costRounded}
      data-cy={props['data-cy']}
      description={props.description}
      icon={props.icon}
      isBold={props.isBold}
      label={props.label}
      showTooltips={props.showTooltips}
    />
  );
}

function PendingAddDeducts(props: ItemDataProps) {
  // Format Costs
  const costAdds = formatCostValue(props.itemData?.cost?.adds || props.pendingAdds);
  const costDeducts = formatCostValue(props.itemData?.cost?.deducts || props.pendingDeducts);
  // For Table Display
  const itemAdds = costAdds.shortCost;
  const itemDeducts = costDeducts.shortCost;
  const itemCostRange = `${itemAdds} / ${itemDeducts}`;
  // For Tooltip Display
  const exactRangAdds = costAdds.exactCost;
  const exactRangeDeducts = costDeducts.exactCost;
  const itemCostRangeExact = `${exactRangAdds} / ${exactRangeDeducts}`;

  return (
    <ProjectCostRow
      className={props.className}
      costExact={itemCostRangeExact}
      costRounded={itemCostRange}
      data-cy={props['data-cy']}
      description={props.description}
      icon={props.icon}
      isBold={props.isBold}
      label={props.label}
      showTooltips={props.showTooltips}
    />
  );
}

function PotentialRange(props: ItemDataProps) {
  const itemAdds = formatCostValue(props.itemData?.cost?.adds || props.pendingAdds);
  const itemDeducts = formatCostValue(props.itemData?.cost?.deducts || props.pendingDeducts);

  let runningTotal = 0;
  if (props.cost) {
    const { value } = props.cost as CostScalar;
    if (value !== undefined) {
      runningTotal = Number(value);
    }
  }

  const rangeMax = runningTotal + Number(itemAdds.value);
  const rangeMin = runningTotal + Number(itemDeducts.value);
  // Format value
  const rangeMaxCost = formatCostValue(rangeMax);
  const rangeMinCost = formatCostValue(rangeMin);
  // Display Values
  const potentialRangeRounded = `${rangeMaxCost.shortCost} to ${rangeMinCost.shortCost}`;
  const potentialRangeExact = `${rangeMaxCost.exactCost} to ${rangeMinCost.exactCost}`;

  return (
    <ProjectCostRow
      className={props.className}
      costExact={potentialRangeExact}
      costRounded={potentialRangeRounded}
      data-cy={props['data-cy']}
      description={props.description}
      icon={props.icon}
      isBold={props.isBold}
      label={props.label}
      showTooltips={props.showTooltips}
    />
  );
}

function GapTrending(props: GapTrendingProps) {
  const itemAdds = Number(props.itemData?.cost?.adds || props.pendingAdds);
  const itemDeducts = Number(props.itemData?.cost?.deducts || props.pendingDeducts);
  const getCostValue = (cost: Cost) => {
    if (cost && 'value' in cost) {
      return Number(cost.value) || 0;
    }
    return 0;
  };

  const costRunning = getCostValue(props.costRunning);
  const costBudget = getCostValue(props.costBudget);

  const gap = costBudget - costRunning;
  const rangeSummary = {
    Max: gap - itemAdds,
    Min: gap - itemDeducts,
    Base: gap,
  };
  const value = rangeSummary[props.type] || gap;
  const costs = formatCostValue(value);
  const percentage = costBudget > 0 ? `${((value / costBudget) * 100).toFixed()}%` : '-%';

  return (
    <ProjectCostRow
      className={props.className}
      costExact={costs.exactCost}
      costPercentage={percentage}
      costRounded={costRunning && costBudget ? costs.shortCost : '-'}
      data-cy={props['data-cy']}
      description={props.description}
      icon={props.icon}
      isBold={props.isBold}
      label={props.label}
      showTooltips={props.showTooltips}
    />
  );
}

// Utils

type FormattedCost = {
  displayedCost: string;
  exactCost: string;
  shortCost: string;
  value: number;
};
const formatExactCost = (cost: number | string) =>
  formatCost(cost, { showCents: true, rounded: false });

const formatRoundedCost = (cost: number | string) =>
  formatCost(cost, { showCents: false, rounded: true });

const formatShortCost = (cost: number | string) => formatCost(cost, { short: true });

const formatCostValue = (
  cost: Cost | number | null | undefined,
  emptyValueText?: string
): FormattedCost => {
  let displayedCost = '';
  let exactCost = '';
  let shortCost = '';
  let value = 0;
  const emptyValue = emptyValueText ?? EMPTY_COST;

  if (cost === undefined || cost === null) {
    displayedCost = emptyValue;
    exactCost = emptyValue;
    shortCost = emptyValue;
    value = 0;
  } else if (typeof cost === 'number' || typeof cost === 'string') {
    exactCost = formatExactCost(cost);
    displayedCost = formatRoundedCost(cost);
    shortCost = formatShortCost(cost);
    value = cost;
  } else if (isCostScalar(cost)) {
    exactCost = formatCost(cost.value);
    displayedCost = formatRoundedCost(cost.value);
    shortCost = formatShortCost(cost.value);
    value = Number(cost.value);
  } else if (isCostRange(cost)) {
    displayedCost = emptyValue;
    exactCost = emptyValue;
    shortCost = emptyValue;
    value = 0;
  }
  return { displayedCost, exactCost, shortCost, value };
};
