import * as d3 from 'd3';
import { ScaleLinear, ScaleTime } from 'd3-scale';

export const FormatTime = d3.timeFormat('%m/%y');

export default function TimelineXAxis({
  placement,
  range,
  tickTotal,
  x,
  y,
  yPosition,
}: {
  placement?: 'top' | 'bottom';
  range: [number, number];
  tickTotal: number;
  x: ScaleTime<number, number>;
  y: ScaleLinear<number, number>;
  yPosition: number;
}) {
  const ticks = x.ticks(tickTotal + 1).map((value) => ({
    value,
    xOffset: x(value),
  }));
  ticks.pop();

  const yOffset = y(yPosition);

  return (
    <svg>
      <path
        d={['M', range[0], 0, 'v', 0, 'H', range[1], 'v', 0].join(' ')}
        fill="none"
        stroke="var(--colors-chart-axis)"
        strokeWidth={2}
        transform={`translate(0, ${yOffset})`}
      />
      {ticks.map(({ value, xOffset }, i) => {
        return (
          <g
            key={`x-tick-${JSON.stringify(i)}-${value}`}
            transform={`translate(${xOffset}, ${yOffset})`}
          >
            <line stroke="var(--colors-chart-axis)" strokeWidth={1} y1="-6" y2="6" />
            <text
              key={`${value}`}
              style={{
                fontSize: '11px',
                textAnchor: 'middle',
                transform: placement === 'top' ? 'translateY(-12px)' : 'translateY(22px)',
              }}
            >
              {FormatTime(new Date(value))}
            </text>
          </g>
        );
      })}
    </svg>
  );
}
