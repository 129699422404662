import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { ErrorOutline } from '@material-ui/icons';

import { InsightsSortKey } from '../../generated/graphql';
import { ButtonBar, Select, SelectEntry, Switch } from '../scales';

import { InsightsTabId } from './types';

type InsightsTabsProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  alertsOnly: boolean;
  onSortByChange: (sortKey: InsightsSortKey) => void;
  onTabChange?: (tabId: InsightsTabId) => void;
  selectedSortBy: InsightsSortKey;
  selectedTabID: InsightsTabId;
  setAlertsOnly: (checked: boolean) => void;
};

export default function InsightsTabs({
  alertsOnly,
  onSortByChange,
  onTabChange,
  selectedSortBy,
  selectedTabID,
  setAlertsOnly,
}: InsightsTabsProps) {
  const navigate = useNavigate();
  const [tabId, setTabId] = useState(selectedTabID);
  const updateHash = (id: InsightsTabId) => {
    const idAnchor = `#${id}`;
    const hasHash = window.location.href.includes(idAnchor);
    if (!hasHash) navigate({ hash: idAnchor });
  };
  useDebounce(() => updateHash(tabId), 100, [tabId]);
  const handleTabChange = (id: InsightsTabId) => {
    setTabId(id);
    if (onTabChange) {
      onTabChange(id);
    }
  };

  // Sorting Selector
  type SortByInsights = {
    [key in InsightsSortKey]?: string;
  };
  const sortByEntries: SortByInsights[] = [
    { [InsightsSortKey.COST]: 'Cost' },
    { [InsightsSortKey.DELIVERY_TYPE]: 'Delivery Method' },
    { [InsightsSortKey.DESIGN_PHASE]: 'Design Phase' },
    { [InsightsSortKey.LAST_UPDATED]: 'Last Updated' },
    { [InsightsSortKey.NAME]: 'Name' },
    { [InsightsSortKey.SIZE]: 'Size (GSF)' },
    { [InsightsSortKey.STATUS]: 'Status' },
    { [InsightsSortKey.PROJECT_LEAD]: 'Project Lead' },
  ];

  const sortBySelectors: SelectEntry[] = sortByEntries.map((item: SortByInsights) => {
    const [key, value] = Object.entries(item)[0];
    return { id: key as keyof typeof InsightsSortKey, label: value! };
  });
  function findSelectEntry(selectedSortBy: InsightsSortKey): SelectEntry | undefined {
    const foundItem = sortByEntries.find((item) => Object.keys(item)[0] === selectedSortBy);
    if (foundItem) {
      const [key, value] = Object.entries(foundItem)[0];
      return { id: key as keyof typeof InsightsSortKey, label: value! };
    }
    return undefined;
  }
  const selectedEntry = findSelectEntry(selectedSortBy);

  return (
    <>
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-row items-center gap-3">
          <div className="flex flex-col">
            <div className="type-body3">View</div>
            <ButtonBar
              isFullWidth={false}
              onChange={(tabId) => handleTabChange(tabId as InsightsTabId)}
              options={[
                {
                  label: 'Cost Trends',
                  value: InsightsTabId.Costs,
                },
                {
                  label: 'Items',
                  value: InsightsTabId.Items,
                },
                {
                  label: 'Contingencies & Allowances',
                  value: InsightsTabId.Contingencies,
                },
              ]}
              value={tabId}
            />
          </div>
          <div className="flex items-center pt-4">
            <ErrorOutline color="error" />
            <Switch
              checked={alertsOnly}
              data-cy="alerts-only"
              label="Alerts only"
              onChange={(value) => {
                setAlertsOnly(value);
              }}
            />
          </div>
        </div>
        <div className="flex w-44">
          <Select
            aria-label="Insights Sort"
            entries={sortBySelectors}
            label="Sort By"
            onChange={(value: string) => {
              const selectedSortKey: InsightsSortKey = value as InsightsSortKey;
              onSortByChange(selectedSortKey);
            }}
            value={selectedEntry?.id}
          />
        </div>
      </div>
    </>
  );
}
