import { ReactNode } from 'react';

import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@material-ui/icons';

import { BabyButton } from '../../../scales';

type Props = {
  isShowingMilestoneInfo?: boolean;
  onShowMilestoneInfo?: (value: boolean) => void;
  subtitle: string;
  title: string;
  thumbnail: ReactNode;
};

export default function Title(props: Props) {
  return (
    <div className="flex gap-2 text-type-primary">
      {props.thumbnail}
      <div className="flex w-full min-w-0 flex-col">
        <div className="line-clamp-2 type-heading3" data-cy="project-title--project-name">
          {props.title}
        </div>
        <div className="mt-auto flex items-center gap-2 type-body3">
          <div className="flex h-6 grow items-center truncate">
            <div className="grow truncate" title={props.subtitle}>
              {props.subtitle}
            </div>
            {props.onShowMilestoneInfo !== undefined && (
              <BabyButton
                aria-label={
                  props.isShowingMilestoneInfo ? 'hide milestone info' : 'show milestone info'
                }
                icon={
                  props.isShowingMilestoneInfo ? (
                    <KeyboardArrowUpOutlined />
                  ) : (
                    <KeyboardArrowDownOutlined />
                  )
                }
                onClick={() => props.onShowMilestoneInfo?.(!props.isShowingMilestoneInfo)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
