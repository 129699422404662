import { Status } from '../../generated/graphql';
import { getItemStatusLabel } from '../../utilities/item-status';
import { getItemCost } from '../../utilities/items';
import { generateCostImpactInfo } from '../Items/ItemsUtils';
import ItemsIconsStatusSelect from '../ItemsList/ItemsIcons/ItemsIconsStatusSelect/ItemsIconsStatusSelect';
import { Button, Dialog, DialogContent } from '../scales';
import MilestoneSelect from '../Select/MilestoneSelect';

type Props = {
  isOpen: boolean;
  item: ItemLike;
  milestones?: Milestone[];
  onCancel: () => void;
  onChangeMilestone: (milestone: string) => void;
  onChangeStatus: (status: Status) => void;
  onClose: () => void;
  onSubmit: () => void;
  selectedMilestone?: string;
  selectedStatus: Status;
};

export default function AddToMilestoneModal(props: Props) {
  const number = props.item ? props.item.number : '';
  const name = props.item ? props.item.name : '';

  return (
    <Dialog
      footerLeft={<Button label="Cancel" onClick={props.onCancel} type="secondary" />}
      footerRight={<Button label="OK" onClick={props.onSubmit} type="primary" />}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Move Item to Another Milestone"
    >
      <DialogContent className="flex flex-col gap-2">
        <div>
          Move <strong>{`#${number} ${name}`}</strong> to:
        </div>
        <div className="flex items-center gap-2">
          <MilestoneSelect
            isCompact={false}
            milestones={props.milestones}
            onChangeMilestone={(milestone) => milestone && props.onChangeMilestone(milestone)}
            selectedMilestone={props.selectedMilestone}
          />
          <div className="shrink-0">with status</div>
          <ItemsIconsStatusSelect
            availableStates={props.item.availableStates ?? []}
            editable
            item={props.item}
            options={'options' in props.item ? props.item.options : []}
            status={props.selectedStatus}
            updateStatus={props.onChangeStatus}
          />
        </div>
        {props.item.milestone?.id && props.selectedMilestone !== props.item.milestone.id && (
          <div>
            {'This item will still continue to impact '}
            <strong>{props.item.milestone.name}</strong>
            {' with status '}
            <strong>{getItemStatusLabel(props.item.status)}</strong>
            {' and cost impact '}
            <strong>{generateCostImpactInfo(getItemCost(props.item))}</strong>.
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
