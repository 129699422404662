import { Org, OrgNode } from '../../../generated/graphql';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { isNonNullable } from '../../../utilities/types';
import { TreeEntry, makeTree } from '../../../utilities/utilities';
import { getProjectsRange } from '../ProjectsTables/utils';
import { InsightsProject } from '../types';

export const getProjectRange = (project: InsightsProject): [Date, Date] => {
  const today = getTodayUtcNoon();
  const { timelineActivitiesMockData, itemsDueDateSeriesMockData, milestones, costTimeseries } =
    project;
  const min = Math.min(
    ...(timelineActivitiesMockData ?? []).map((a) => new Date(a.startDate).getTime()),
    ...(itemsDueDateSeriesMockData ?? []).map((a) => a.date.getTime()),
    // Draft milestone may have no date a.date.getTime() > 0
    ...(milestones ?? []).filter((a) => a.date.getTime() > 0).map((a) => a.date.getTime()),
    ...(costTimeseries ?? []).map((a) => a.date.getTime()),
    today.getTime()
  );
  const max = Math.max(
    ...(timelineActivitiesMockData ?? []).map((a) => new Date(a.startDate).getTime()),
    ...(itemsDueDateSeriesMockData ?? []).map((a) => a.date.getTime()),
    // Draft milestone may have no date a.date.getTime() > 0
    ...(milestones ?? []).filter((a) => a.date.getTime() > 0).map((a) => a.date.getTime()),
    ...(costTimeseries ?? []).map((a) => a.date.getTime()),
    today.getTime()
  );
  if (Number.isNaN(min) || Number.isNaN(max) || !Number.isFinite(min) || !Number.isFinite(max)) {
    // Fallback logic
    return getProjectsRange(getTodayUtcNoon());
  }
  const before = new Date(min).getTime();
  const after = new Date(max).getTime();
  const padding = (after - before) / 10;
  return [new Date(before - padding), new Date(after + 2 * padding)];
};

export function getOrgsSubNodes(orgs: Org[] | undefined, nodes: (OrgNode | null)[]) {
  return (orgs || []).map((org) => ({ org, nodes: getOrgSubNodes(org, nodes) }));
}

export function getOrgSubNodes(org: Org, nodes: (OrgNode | null)[]): OrgNode[] {
  if (!nodes) return [];
  const tree = makeTree(org.nodes);
  const subNodes: OrgNode[] = [];
  function findSubNodes(orgNodes: TreeEntry<OrgNode>[] | undefined, nodes: (OrgNode | null)[]) {
    (orgNodes || []).forEach((orgNode) => {
      const ns = nodes.filter((n) => orgNode.id && orgNode.id === n?.id).filter(isNonNullable);
      if (ns.length) {
        subNodes.push(...ns);
        return;
      }
      findSubNodes(orgNode.entries, nodes);
    });
  }
  findSubNodes(tree, nodes);
  return subNodes;
}
