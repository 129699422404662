export enum StarredToggle {
  ALL_IDEAS = 'ALL_IDEAS',
  STARRED_IDEAS = 'STARRED_IDEAS',
}

export type IdeaGroup = {
  name: string;
  ideas: ProjectIdea[];
  total: number;
};

export type ProjectIdea = {
  id: UUID;
  name: string;
  comparisonSummary: string;
  root: ProjectAlternate;
  alternates: ProjectAlternate[];
  relevance?: IdeaRelevance | null;
};

export type ProjectAlternate = {
  id: UUID;
  imageURL: string;
  name: string;
  otherNames: string[];
  description: string;
  characteristics: string[];
  advantages: string[];
  disadvantages: string[];
  idealConditions: string[];
  weight: number;
  uniFormat?: { id: UUID; number: string; name: string } | null;
  masterFormat?: { id: UUID; number: string; name: string } | null;
};

type IdeaRelevance = {
  cost: Numeric;
  lines: {
    description: string;
    matchingTerms: string[];
    total: Numeric;
  }[];
};
