import { useMemo, useState } from 'react';

import { ResourceType } from '../../../generated/graphql';
import { useGetSharedResources } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';

export const useSelectedRemovedAddedUserIDs = (reportID: UUID) => {
  const [selectedUserIDs, setSelectedUserIDs] = useState<UUID[] | null>(null);

  const { data, refetch, loading } = useGetSharedResources(
    [reportID],
    ResourceType.FORECASTING_REPORT
  );
  const sharedUsers = data?.getSharedResources?.resources?.[0]?.users;
  const sharedUserIDs = sharedUsers?.map((u) => u.id);

  useMemo(() => {
    if (sharedUserIDs && !selectedUserIDs) {
      setSelectedUserIDs(sharedUserIDs);
    }
  }, [sharedUserIDs, selectedUserIDs]);

  const { newUserIDs, removedUserIDs } = useMemo(() => {
    const newUserIDs: UUID[] = selectedUserIDs?.filter((id) => !sharedUserIDs?.includes(id)) ?? [];
    const removedUserIDs: UUID[] =
      sharedUserIDs?.filter((id) => !selectedUserIDs?.includes(id)) ?? [];
    return { newUserIDs, removedUserIDs };
  }, [selectedUserIDs, sharedUserIDs]);

  return {
    newUserIDs,
    removedUserIDs,
    resourceLoading: loading,
    refetch,
    selectedUserIDs,
    setSelectedUserIDs,
    sharedUserIDs,
  };
};
