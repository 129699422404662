import { ErrorOutline } from '@material-ui/icons';

import { InsightsProject as InsightsProjectType } from '../types';
import { getUniqueAlertDescriptionCounts } from '../utils';

export default function InsightsProjectAlerts(props: { project: InsightsProjectType }) {
  const uniqueAlerts = getUniqueAlertDescriptionCounts(props.project.activeAlerts);

  return (
    <div className="flex flex-col gap-2">
      {uniqueAlerts.map((a) => (
        <div key={a.description} className="flex gap-2">
          <div className="flex items-center text-type-error [&_*]:!icon-sm">
            <ErrorOutline />
          </div>
          <div className="whitespace-nowrap text-type-error type-body3">{a.description}</div>
        </div>
      ))}
    </div>
  );
}
