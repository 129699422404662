import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  EditProgramInput,
  EditProgramMutation,
  EditProgramMutationVariables,
} from '../../../../generated/graphql';
import { editProgramMutation } from '../../mutations';

export const useEditProgramMutation = (
  options?: MutationHookOptions<EditProgramMutation, EditProgramMutationVariables>
) => {
  const [editProgram] = useMutation<EditProgramMutation, EditProgramMutationVariables>(
    editProgramMutation,
    options
  );
  return (programID: UUID, input: EditProgramInput) =>
    editProgram({
      variables: { programID, input },
      ...options,
    });
};
