import './TipTapTextArea.scss';

import { useEffect, useState } from 'react';

import { Slice } from '@tiptap/pm/model';
import { EditorProps, EditorView } from '@tiptap/pm/view';
import { EditorContent, useEditor } from '@tiptap/react';

import { useUploadImage } from '../../../assets/hooks/AssetsMutationHook';
import MenuBar from '../MenuBar/MenuBar';

import { addImage } from './TipTapTextAreaImageUtils';
import { getEditorContent, getExtensions, useHandleDOMEvents } from './TipTapTextAreaUtils';

export enum TipTapTextAreaVariant {
  DEFAULT,
  DESCRIPTION,
}

type Props = {
  'data-cy'?: string;
  isDisabled?: boolean;
  isEditable: boolean;
  onAttachAsset?: (asset: Asset) => void;
  onChange?: (value: string, valueStyled: string) => void;
  placeholder?: string;
  value: string;
};

export default function TipTapTextArea(props: Props) {
  // Save off the value when the user clicks into the editor to compare against
  // when the editor loses focus so that we can detect if the value has changed.
  const [valueWhenGainedFocus, setValueWhenGainedFocus] = useState({ text: '', html: '' });

  const editorProps: EditorProps = { handleDOMEvents: useHandleDOMEvents() };

  const uploadImage = useUploadImage();
  if (props.onAttachAsset) {
    editorProps.handleDrop = (
      view: EditorView,
      event: DragEvent,
      _: Slice,
      moved: boolean
    ): boolean => {
      const file = (event?.dataTransfer?.files || [])[0];
      if (moved || !file) return false; // not handled use default behaviour

      event.preventDefault();

      const { clientX: x, clientY: y } = event || {};
      const onSuccess = (asset: Asset) => {
        if (props.onAttachAsset) props.onAttachAsset(asset);
      };
      return addImage(view, file, x, y, uploadImage, onSuccess);
    };
    editorProps.handlePaste = (view: EditorView, event: ClipboardEvent): boolean => {
      const file = (event.clipboardData?.files || [])[0];
      if (!file) return false; // not handled use default behaviour

      event.preventDefault();

      const position = view.state.selection.$anchor.pos || 0;
      const { left: x, top: y } = view.coordsAtPos(position);
      const onSuccess = (asset: Asset) => {
        if (props.onAttachAsset) props.onAttachAsset(asset);
      };
      return addImage(view, file, x, y, uploadImage, onSuccess);
    };
  }

  const editable = props.isEditable && !props.isDisabled;
  const editor = useEditor({
    editable,
    editorProps,
    extensions: getExtensions(props.placeholder),
    content: getEditorContent(props.value),
    onFocus: ({ editor }) => {
      setValueWhenGainedFocus({ text: editor.getText(), html: editor.getHTML() });
    },
    onBlur: ({ editor }) => {
      const newValue = { text: editor.getText(), html: editor.getHTML() };
      if (
        valueWhenGainedFocus.text !== newValue.text ||
        valueWhenGainedFocus.html !== newValue.html
      ) {
        props.onChange?.(newValue.text, newValue.html);
      }
    },
  });

  // Changing the value of `editable` passed into the `useEditor` hook doesn't
  // update the editor instance that's already been rendered. This is a workaround
  // to update the editor instance with the new `editable` value.
  // Ref: https://github.com/ueberdosis/tiptap/issues/111
  useEffect(() => editor?.setOptions({ editable }), [editor, editable]);

  let className = 'cursor-default bg-transparent'; // !isEditable
  if (props.isEditable) {
    className = props.isDisabled
      ? 'border bg-button-inactive'
      : 'border bg-background-primary focus-within:outline cursor-text';
  }

  return (
    <div
      className={[
        'scales--rich-text-editor', // used for scoping in the css file
        'mb-2 flex w-full flex-col rounded-md text-type-primary type-body2 focus-visible:[&_*]:outline-none',
        props.isEditable ? 'min-h-[160px]' : '',
        className,
      ].join(' ')}
      onMouseDown={editable ? () => editor?.commands.focus() : undefined}
    >
      {props.isEditable && editor && <MenuBar editor={editor} isDisabled={props.isDisabled} />}
      <EditorContent
        className={['overflow-auto', props.isEditable ? 'p-4' : ''].join(' ')}
        data-cy={props['data-cy']}
        editor={editor}
        tabIndex={editable ? 0 : -1}
      />
    </div>
  );
}
