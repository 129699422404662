import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

export default function YMeshLines({
  tickTotal,
  x,
  y,
  xDomain,
}: {
  tickTotal: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  xDomain: [Date, Date];
}) {
  const ticks = y.ticks(tickTotal).map((value) => ({
    value,
    yOffset: y(value),
  }));

  const xOffset1 = x(xDomain[0]) ?? 0;
  const xOffset2 = x(xDomain[1]) ?? 0;

  return (
    <svg>
      {ticks.map(({ value, yOffset }) => (
        <g key={`${value}`} transform={`translate(${0}, ${yOffset})`}>
          <line stroke="var(--colors-chart-axis)" strokeWidth={1} x1={xOffset1} x2={xOffset2} />
        </g>
      ))}
    </svg>
  );
}
