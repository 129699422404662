import {
  ProgramCategorizationsQuery,
  ProgramCostFieldsFragment,
  ProgramCostTrendline,
  ProgramFieldsFragment,
  ProjectCostBreakdown,
} from '../../generated/graphql';
import { ScrollContainer } from '../scales';

import ProgramDashboardContent from './ProgramDashboardContent';
import ProgramDashboardMenu from './ProgramDashboardMenu';

type Props = {
  isPrinting: boolean;
  program?: ProgramFieldsFragment;
  programCategorizations: ProgramCategorizationsQuery['programCategorizations'];
  programCosts?: ProgramCostFieldsFragment;
  programBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  projectBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
  totalProgramCost: number;
  chartData?: ProgramCostTrendline;
};

export default function ProgramDashboard({
  chartData,
  isPrinting,
  program,
  programCosts,
  programBreakdown,
  projectBreakdown,
  selectedProjectIDs,
  setSelectedProjectIDs,
  totalProgramCost,
  programCategorizations,
}: Props) {
  const projects = program?.projects ?? [];

  if (!projects || projects.length === 0) return null;

  const content = (
    <ProgramDashboardContent
      chartData={chartData}
      isPrinting={isPrinting}
      program={program}
      programBreakdown={programBreakdown}
      programCategorizations={programCategorizations}
      programCosts={programCosts}
      projectBreakdown={projectBreakdown}
      projects={projects}
      selectedProjectIDs={selectedProjectIDs}
      setSelectedProjectIDs={setSelectedProjectIDs}
      totalProgramCost={totalProgramCost}
    />
  );

  return (
    <div className="flex h-full flex-col">
      <ProgramDashboardMenu isPrinting={isPrinting} program={program} />
      {isPrinting ? content : <ScrollContainer direction="vertical">{content}</ScrollContainer>}
    </div>
  );
}
