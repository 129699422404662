import { QueryHookOptions, useQuery } from '@apollo/client';

import { ProgramBreakdownQuery, ProgramBreakdownQueryVariables } from '../../../generated/graphql';
import { programBreakdownQuery } from '../queries';

export const useProgramBreakdownQuery = (
  programID: UUID | undefined,
  projectIDs: UUID[],
  options?: QueryHookOptions<ProgramBreakdownQuery, ProgramBreakdownQueryVariables>
) =>
  useQuery<ProgramBreakdownQuery, ProgramBreakdownQueryVariables>(programBreakdownQuery, {
    variables: programID
      ? {
          programID,
          projectIDs,
        }
      : undefined,
    skip: !programID || !projectIDs.length,
    ...options,
  });
